<div style="display: block;">
    <mat-card>
      <h3>Próximos Dividendos</h3>
      <br>
      <mat-list>
        <mat-list-item *ngFor="let dividendo of dividendos">
            <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
            <p mat-line class="fecha" [ngClass]="getClassFromDate(dividendo.payable_date)">{{ dividendo.payable_date }}</p>
            <img src="https://logo.clearbit.com/{{ dividendo.empresa.logo }}.com" matListAvatar alt="">
            <div matLine>{{ dividendo.empresa.symbol }}</div>
            <div matLine>Ex-date: {{ dividendo.ex_dividend }}</div>
            <p mat-line-end class="abu">{{ dividendo.frequency }} div per year </p>
            <p mat-line-end class="abu">{{ dividendo.dividendo | number : '1.2-2' }} {{ dividendo.empresa.currency }} </p>
            <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </mat-card>
  </div>