import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabla-acciones',
  templateUrl: './tabla-acciones.component.html',
  styleUrls: ['./tabla-acciones.component.scss']
})
export class TablaAccionesComponent implements OnInit {

  @Input() acciones;
  @Input() total_invertido = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
