import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duplicate',
  pure: false
})
export class DuplicatePipe implements PipeTransform {

  transform(array: any[], propiedad: string = 'idVenta'): any[] {

    if (!array) {
      return array;
    }

    return array.map(e => e[propiedad])

                  // store the indexes of the unique objects
                  .map((e, i, final) => final.indexOf(e) === i && i)

                  // eliminate the false indexes & return unique objects
                 .filter((e) => array[e]).map(e => array[e]);
  
  }

}
