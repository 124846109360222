<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-toolbar-row>
      <img class="img" width="35px" src="assets/icons/cubos.png" [routerLink]="['/']">
      <!-- <span>HamperBlock</span> -->
    </mat-toolbar-row>
    <mat-nav-list>
      <mat-list-item [routerLink]="['/pages/home']">
        <mat-icon>home</mat-icon>
        {{ 'HOME' | translate }}
      </mat-list-item>
      <mat-list-item [routerLink]="['/pages/dashboard']">
        <mat-icon>dashboard</mat-icon>
        Dashboard
      </mat-list-item>
      <mat-list-item [routerLink]="['/pages/board']">
        <mat-icon>play_circle</mat-icon>
        Play
      </mat-list-item>
      <mat-list-item [routerLink]="['/pages/carteras']">
        <mat-icon>card_travel</mat-icon>
        {{ 'carteras.my_portfolios' | translate }}
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/pages/coleccion']">
        <mat-icon>collections</mat-icon>
        Coleccion
      </mat-list-item>
      <mat-list-item [routerLink]="['/pages/market']">
        <mat-icon>shopping_cart</mat-icon>
        MarketPlace
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/pages/empresas']">
        <mat-icon>settings_input_antenna</mat-icon>
        Rádar
      </mat-list-item>
      <mat-list-item [routerLink]="['/pages/calendar']">
        <mat-icon>calendar_today</mat-icon>
        Calendario
      </mat-list-item>
      <mat-list-item [routerLink]="['/pages/movimientos']">
        <mat-icon>account_balance_wallet</mat-icon>
        Movimientos
      </mat-list-item>
      <mat-list-item [routerLink]="['/pages/profit']">
        <mat-icon>assessment</mat-icon>
        Profit
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/pages/account']">
        <mat-icon>account_circle</mat-icon>
        {{ 'account.myaccount' | translate }}
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/pages/swap']">
        <mat-icon>autorenew</mat-icon>
        <b>SWAP</b>
      </mat-list-item>

    </mat-nav-list>
  </mat-sidenav>

  <router-outlet></router-outlet>
  
 <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="example-spacer"></span>
        <span>HamperBlock v1.5.1</span>
      <span class="example-spacer"></span>
      <button mat-raised-button class="big-round-shape-button" (click)="connectAccount()"> {{ 'account.connect_account' | translate }}</button>
      <button mat-icon-button [ngClass]="{'active': activeLang == 'es'}" (click)="cambiarLenguaje('es')">
        <mat-icon>language</mat-icon>
    </button>
      <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>share</mat-icon>
      </button> -->
      <button mat-icon-button [routerLink]="['/account']">
        <mat-icon>person</mat-icon>
      </button>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
