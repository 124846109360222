import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-crypto',
  templateUrl: './crypto.component.html',
  styleUrls: ['./crypto.component.scss']
})
export class CryptoComponent implements OnInit {

  nft: any;
  fundamentales: any;
  analisis: any;
  historico = [];
  velas = [];
  actualSma = {};

  arraySlider = [0, 0, 0, 0];
  displayedColumns: string[] = ['fiscalDateEnding', 'reportedEPS', 'commonStockSharesOutstanding', 'dpa', 'bpa', 'netIncome', 'dividendPayout', 'fcf'];
  displayedColumnsFundamentales: string[] = ['fiscalDateEnding', 'totalRevenue', 'costOfRevenue', 'grossProfit', 'ebitda', 'dandp', 'ebit', 'interests', 'ebt', 'tax', 'netIncome'];
  
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLabels: Label[] = ['Ballenas', 'Resto'];;
  public doughnutChartData = [90, 10];

  public barChartType: ChartType = 'bar';
  public barChartLabels: Label[] = ['1d', '7d', '30d', '60d', '90d'];
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [];
  
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], 
    yAxes: [{
      ticks: {
        beginAtZero:true
      }
    }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  panelOpenState = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
  ) { }

  ngOnInit(): void {

    console.log(this.data);
    this.nft = this.data.nft;
    this.data = this.data.crypto;

    // Get analisis tecnico
    this.dataService.getVelasCripto(this.nft.icono)
      .subscribe( (velas: any) => {
        console.log(velas["Time Series (Digital Currency Daily)"]);
        let datosTecnicos = [];
        // this.dataService.getSMADiario(this.nft.icono)
        //   .subscribe( (sma: any) => {
        //     console.log(sma);
            for (const [key, value] of Object.entries(velas['Time Series (Digital Currency Daily)'])) {
        //       this.actualSma = Object.values(sma['Technical Analysis: SMA'])[0];
        //       for (const [key2, value2] of Object.entries(sma['Technical Analysis: SMA'])) {
        //         if (key === key2) {
                  datosTecnicos.push(
                    {
                      date: Date.parse(key),
                      open: value['1b. open (USD)'],
                      high: value['2b. high (USD)'],
                      low: value['3b. low (USD)'],
                      close: value['4b. close (USD)'],
                      volume: value['5. volume'],
                      marketCap: value['6. market cap (USD)'],
                      // sma: value2['SMA']
                    });
        //         }
        //     }
            }
        //   });
        this.velas = datosTecnicos;
        console.log(this.velas);
      });

    this.dataService.getFundamentalCrypto(this.data.id)
      .subscribe( (crypto: any) => {
        this.fundamentales = crypto['results'][0];
        console.log(this.fundamentales);
        // Obtengo el precio anterior segun el actual
        let precio = this.fundamentales.precio;
        this.barChartData = [
          { data: [
            this.fundamentales['percent_change_24h'],
            this.fundamentales['percent_change_7d'],
            this.fundamentales['percent_change_30d'],
            this.fundamentales['percent_change_60d'],
            this.fundamentales['percent_change_90d'],
        ], label: 'dif % VS Today' }
        ];
        // this.arraySlider = [this.fundamentales['WeekLowYear'], this.fundamentales['WeekHighYear'], this.fundamentales['DayMovingAverage200'], this.fundamentales['DayMovingAverage50']]
      });
    
    this.dataService.getAnalisisCrypto(this.data.id)
      .subscribe( (crypto: any) => {
        this.analisis = crypto['results'];
        console.log(this.analisis);
        });

  }

}
