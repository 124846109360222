
<mat-tab-group mat-align-tabs="start">
    <mat-tab label="Básico">
  
      <mat-grid-list cols="3" rowHeight="450px" *ngIf="fundamentales && analisis">
        
        <mat-grid-tile colspan="1" [rowspan]="1">
          <!-- IZQUIERDA -->
          <app-nft-info [nft]="nft"></app-nft-info>
        </mat-grid-tile>
  
        <mat-grid-tile colspan="1" [rowspan]="1" *ngIf="fundamentales">
          <!-- CENTRO -->
          <div>
  
            <mat-card>
        
              <mat-card-header>
                <mat-icon mat-card-avatar>{{ icono }}</mat-icon>
                <mat-card-title>{{ data.nombre }}</mat-card-title>
                <mat-card-subtitle>{{ data.sector }}</mat-card-subtitle>
              </mat-card-header>
  
              <mat-card-content>
                {{ data.description }}
                
                <p>: {{ fundamentales.markercap /1000 | number : '1.2-2' }} M$</p>
          
              </mat-card-content>
  
            </mat-card>
  
  
            <div>
  
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Supply (mill)</mat-card-title>
                </mat-card-header>
  
                <mat-grid-list cols="2" rowHeight="50px" class="small">
      
                  <mat-grid-tile colspan="1">
                    Total:  {{ fundamentales.total_supply/1000000 }}
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1" [ngClass]="fundamentales.per > 1 ? 'green' : orange">
                    Max: {{ fundamentales.max_supply/1000000 }}
                  </mat-grid-tile>
    
                </mat-grid-list>
              </mat-card>
            
          </div>
  
            <div>
  
              <mat-card>
                <mat-card-header>
                  <mat-card-title>MarketCap</mat-card-title>
                </mat-card-header>
  
                <mat-grid-list cols="3" rowHeight="50px" class="smaller">
      
                    <mat-grid-tile colspan="1">
                        <mat-icon>paid</mat-icon> {{ fundamentales.marketcap }}
                      </mat-grid-tile>
                    <mat-grid-tile colspan="1">
                        <mat-icon>donut_small</mat-icon>  {{ fundamentales.market_cap_dominance }} %
                      </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                    <mat-icon [matBadge]="fundamentales.cmc_rank" matBadgeColor="primary">grade</mat-icon>
                  </mat-grid-tile>
    
                </mat-grid-list>
              </mat-card>
            
          </div>
  
        </div>
            
  
        </mat-grid-tile>
  
  
        <mat-grid-tile colspan="1" [rowspan]="1">
          <!-- DERECHA -->
  
          <div>
  
            
  
            <br>
  
            <div>

                <div>
                    <mat-icon>event_available</mat-icon>  {{ fundamentales.cripto.date_added }}
                </div>
  
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Estadísticas</mat-card-title>
                </mat-card-header>
  
                <mat-grid-list cols="4" rowHeight="50px">
      
                <mat-grid-tile colspan="1">
                    <mat-icon [matBadge]="fundamentales.cmc_rank" matBadgeColor="primary">grade</mat-icon>
                </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                    <mat-icon matBadge="0" matBadgeColor="primary">thumb_up_alt</mat-icon>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                    <mat-icon matBadge="0" matBadgeColor="primary">thumb_down_alt</mat-icon>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                    <mat-icon [matBadge]="analisis.length" matBadgeColor="primary">leaderboard</mat-icon>
                  </mat-grid-tile>
    
                </mat-grid-list>
              </mat-card>
            
          </div>
  
          <br>
  
            <div>
  
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Doc</mat-card-title>
                </mat-card-header>
  
                <mat-grid-list cols="4" rowHeight="50px">

                      <mat-grid-tile colspan="4">
                        <mat-icon>picture_as_pdf</mat-icon><a [href]="fundamentales.cripto.technical_doc" target="blank">WhitePaper</a>
                      </mat-grid-tile>
      
    
                </mat-grid-list>
              </mat-card>
            
          </div>
  
          <br>
  
          <div>
              <mat-card>
                <mat-card-header>
                  <mat-card-title>Medias</mat-card-title>
                  <mat-card-subtitle>
                    MMA200: {{ arraySlider[2] }} MMA50: {{ arraySlider[3] }}
                  </mat-card-subtitle>
                </mat-card-header>
                <span class="red smaller">{{ arraySlider[0] }}</span>
                <mat-slider
                  thumbLabel
                  tickInterval="1000"
                  [min]= arraySlider[0]
                  [value] = arraySlider[2]
                  disabled = true
                  [max] = arraySlider[1]
                  class="cdk-focused">
                </mat-slider>
                <span class="green smaller">{{ arraySlider[1] }}</span>
              </mat-card>
            </div>
  
          </div>
  
  
        </mat-grid-tile>
  
        <!-- ABAJO -->
        <mat-grid-tile colspan="3" [rowspan]="1">
        </mat-grid-tile>
  
  
      </mat-grid-list>
  
  
  
    </mat-tab>

    <mat-tab label="Análisis">


        <mat-accordion *ngFor="let item of analisis; let i = index">

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ item.last_updated }}
                </mat-panel-title>
                <mat-panel-description>
                <mat-icon>date_range</mat-icon>
                  Análisis #{{ i +1 }}
                  por Alejandro Ureña
                </mat-panel-description>
              </mat-expansion-panel-header>
              <h3>Minería</h3>
              <p>{{ item.mineria }}</p>
              <h3>Roadmap</h3>
              <p>{{ item.roadmap }}</p>
              <h3>Proyección</h3>
              <p>{{ item.proyeccion }}</p>
            </mat-expansion-panel>
          </mat-accordion>

    </mat-tab>
  
    <!-- Fundamentales -->
    <mat-tab label="Fundamentales">
  
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1px" fxLayoutAlign="center">
  
          <div fxFlex="45" style="padding: .5%">

            <h3>Ballenas (%)</h3>
  
            <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
              <canvas baseChart
                [data]="doughnutChartData"
                [labels]="doughnutChartLabels"
                [chartType]="doughnutChartType">
              </canvas>
            </div>
  
          </div>
          
          <div fxFlex="45" style="padding: .5%">

            <h3>Evolución</h3>
  
            <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
              <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType">
              </canvas>
            </div>
  
          </div>
  
        </div>
      
  
    </mat-tab>
  
    <!-- Análisis Técnico -->
    <mat-tab label="Análisis Técnico">
      <app-grafica-velas *ngIf="velas.length > 0" [datos]="velas"></app-grafica-velas>
    </mat-tab>

    
  </mat-tab-group>
  