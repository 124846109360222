import { Component, OnInit } from '@angular/core';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-presale',
  templateUrl: './presale.component.html',
  styleUrls: ['./presale.component.scss']
})
export class PresaleComponent implements OnInit {

  num_tokens: number;
  direction = '';
  valueProgress = 0;
  bufferValue = 75;
  tokensSold = 0;
  contrato;

   // Doughnut Data
   doughnutChartLabels = ['Presale 35%', 'Liquidity 35%', 'Unlocked 30%'];
   doughnutChartData = [
     [35, 35, 30],
   ];

  constructor(
    private contract: ContractService,
  ) {
    this.contract
          .getContractPasarela()
          .then(async (contratoPasarela: any) => {
            console.log('contratoPasarela', contratoPasarela);
            
            this.contrato = contratoPasarela;
            // this.setToken();
            this.investors().then(value => {
              this.tokensSold = value / 10**18;
              this.valueProgress = this.tokensSold * 100 / 10000000;
              console.log('tokensSold: ', this.tokensSold)
            });
          });
   }

  ngOnInit(): void {
    this.direction = localStorage.getItem('myAddress');
    console.log('tu direccion es:', this.direction);
  }

  async send() {
    console.log(this.num_tokens, this.direction);
    return await this.contrato.methods.purchaseICO().send({from: this.direction, value: this.num_tokens * 10**18});
  }

  async setToken() {
    let dir_contrato = '0xEa18e58F540c6f355ef997ed53DBbb0360e558C8';
      return await this.contrato.methods.setToken(dir_contrato).send({from: this.direction});
  }

  async balance() {
    let dir_contrato = '0xEa18e58F540c6f355ef997ed53DBbb0360e558C8';
      return await this.contrato.methods.balanceOf(dir_contrato).call();
  }

  async investors() {
      return await this.contrato.methods.tokensSold().call();
  }

}
