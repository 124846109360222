import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.scss']
})
export class MovimientosComponent implements OnInit {

  data : MatTableDataSource < any[] > = new MatTableDataSource<any[]>([]);
  displayedColumns: string[] = ['fecha', 'empresa', 'tipo', 'acciones', 'precio', 'moneda', 'cambio_moneda', 'comision', 'total_coste', 'total_acciones',];

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.dataService.getMovimientosCartera()
      .subscribe( (movimientos: any) => {
        console.log(movimientos.filter(item => item.cartera.id == 1));
        this.data = movimientos.filter(item => item.cartera.id == 1);
      });
  }

}
