<div class="body">
    <mat-card [ngClass]="[nft.tipo == 'Cryptocurrency' ? 'color2' : 'color', nft.color]" id="image-section">
        <mat-card-header class="color-bar">
            <div mat-card-avatar class="tp-header-image"
            [style.background-image]="'url(../../../../assets/logos/' + nft.pais + '.png'">
            </div>
            <!-- <div class="container">
              <div class="fill"></div>
              <div></div>
            </div> -->
            <mat-card-title>{{ nft.nombre }}</mat-card-title>
            <mat-card-subtitle>{{ nft.tipo }}</mat-card-subtitle>
            <div class="fill"></div>
            <mat-icon>{{ nft.sector }}</mat-icon>
            {{ nft.id }}
        </mat-card-header>

        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

        <mat-card-content>
<br><br>
          <img mat-card-sm-image [src]='"assets/logos/" + nft.icono + ".png"'>

            <p>
              {{ nft.desc }}
            </p>
            

          </mat-card-content>

          <mat-card-actions align="end">
            <button mat-button>{{ nft.mercado }}</button>
            <button mat-button>{{ nft.icono }}</button>
            <button mat-button>Nivel: {{ nft.level }}</button>
          </mat-card-actions>

          <div align="center" *ngIf="market">

            <mat-card-footer *ngIf="esMio">
              <button mat-raised-button color="primary" class="my-button" (click)="cancelNft(nft)">Cancelar Venta</button>
            </mat-card-footer>

          <mat-card-footer *ngIf="!esMio">
            <button mat-raised-button color="primary" class="my-button" (click)="buyNft(nft)">Comprar por {{ nft.price }} {{ nft.symbol }}</button>
          </mat-card-footer>

        </div>
            

        <!-- <mat-card-actions align="end">
          <button mat-stroked-button color="accent">{{ nft.index + 1 }}/10000</button>
        </mat-card-actions> -->
        
    </mat-card>

    <!-- <button type="button" (click)="generateImage(id, level)">Create Image</button> -->
</div>