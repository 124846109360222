import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContractService } from 'src/app/services/contract/contract.service';
import { DialogComponent } from '../../dialog/dialog.component';


@Component({
  selector: 'app-cartera-tarjeta',
  templateUrl: './cartera-tarjeta.component.html',
  styleUrls: ['./cartera-tarjeta.component.scss']
})
export class CarteraTarjetaComponent implements OnInit {

  @Input() cartera;
  contrato;
  direction = "";
  loaded = false;

  constructor(
    private contract: ContractService,
    public dialog: MatDialog,
  ) {
    this.contract
    .getContractUnlockPortfolio()
    .then((contrato: any) => {
      this.contrato = contrato;
    });
   }

  ngOnInit(): void {
    this.direction = localStorage.getItem('myAddress');
  }

  openDialog(data: any) {
    console.log(data);
    const dialogRef = this.dialog.open(DialogComponent, {
      data: data
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        //this.tarjeta.bloqueada = true;
        console.log(`Dialog result: ${result}`);
      });
  }

  async desbloquear(cartera: any) {
    // Obtengo el id de cartera a partir del nombre 
    let idCartera = cartera.nombre.substr(8);
    console.log(idCartera, this.direction);
    let infinity = Boolean(localStorage.getItem('operacionesDefault'));
    this.loaded = true;
    
    this.amountByPortfolio(idCartera).then((costeDesbloqueo: any) => { 
      console.log(costeDesbloqueo);
      this.contract.getContractAnyToken('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174').then((contratoToken: any) => {
        this.contract.allowance_approve(contratoToken, this.direction, '0x80AB1D724F609425A5aEa20f0E06076Ae32A8a3C', String(costeDesbloqueo), infinity, ()=>{
          this.contrato.methods.unlockPortfolio(idCartera).send({from: this.direction}).then((ok: any) => {
              this.loaded = false;
              console.log(ok);
              alert('La cartera ha sido bloqueada correctamente.');
              });
          });
        });
    });

  }

  async amountByPortfolio(idPortfolio){
    return await this.contrato.methods.amountByPortfolio(idPortfolio).call();
  }
        

}
