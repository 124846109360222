
<mat-dialog-content class="mat-typography">
    <mat-icon aria-hidden="false" aria-label="Example home icon">crop_rotate</mat-icon>
    <h1 mat-dialog-title>{{ tarjeta.nombre }}</h1>

    <h2>{{ texto1 }}</h2>
    <img class="img" width="300" [src]="tarjeta.class == 'fee income-tax' ? 'assets/mineria.png' : 'assets/icons/suerte.png'">
    <h2>{{ texto2 }}</h2>

    <button mat-raised-button color="primary" [mat-dialog-close]="true" [routerLink]="['/pages/learning/', tarjeta.id]">Continuar aprendiendo</button>
    <p></p>

      <mat-spinner *ngIf="loaded"></mat-spinner>
      <h2 *ngIf="loaded">Estamos calculando... ¡Suerte!</h2>
<!-- 
      <h2 *ngIf="tarjeta.class == 'community-chest'">¡Enhorabuena! Recibes {{ tarjeta.price }} HBLOCK</h2>
      <h2 *ngIf="tarjeta.class != 'community-chest'">Pagas {{ tarjeta.price }} HBLOCK</h2> -->

      

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Salir</button>
  </mat-dialog-actions>