<!-- <div class="body"> -->
  <!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1px" fxLayoutAlign="center">

    <div fxFlex="25" style="padding: .5%">
      <app-cartera-tarjeta [cartera]="cartera"></app-cartera-tarjeta>
    </div>
    
    <div fxFlex="70" style="padding: .5%"> -->

      <mat-dialog-content class="mat-typography">
        <!-- <app-cartera-tarjeta [cartera]="cartera"></app-cartera-tarjeta> -->
<br>
        <!-- <div mat-dialog-content style="height: calc(100% - 96px);"> -->
        <mat-icon aria-hidden="false" aria-label="Example home icon">card_travel</mat-icon>
      <h1 mat-dialog-title>{{ cartera.nombre }} de {{ cartera.tipo }} - Casilla {{ cartera.id }} - Riesgo {{ leccion.riesgo }}</h1>
        <p innerHTML={{leccion.intro}}>{{ leccion.intro }}</p>
      <br>
      
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancelar</button>
        <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary" class="big-round-shape-button" [routerLink]="['/pages/learning', cartera.id]">Empezar</button>
      </mat-dialog-actions>


    <!-- </div>
  </div> -->
<!-- </div> -->
