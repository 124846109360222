<html lang="en">

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap" rel="stylesheet">

    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
        integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">

    <title>Hamper Swap</title>
    <link rel="stylesheet" href="swap.component.scss">
</head>

<body>
    <div class="top-bar">
        <nav class=" top-bar-nav navbar navbar-expand-lg navbar">
            <!--<div class="logo">
                <a class="navbar-brand" href="#"><img src="https://cryptologos.cc/logos/uniswap-uni-logo.png"
                        alt=""></a>
            </div>
             <div class="center">
                <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-swap-tab" data-toggle="pill" href="#pills-swap" role="tab"
                            aria-controls="pills-swap" aria-selected="true">Swap</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-pool-tab" data-toggle="pill" href="#" role="tab" aria-selected="false">Pool</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" id="pills-vote-tab" data-toggle="pill" href="#" role="tab" aria-selected="false">Vote</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="pills-chart-tab" data-toggle="pill" href="#" role="tab" aria-selected="false">Chart</a>
                    </li>
                </ul>
            </div> -->
            <div class="right">
                <button class=" navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="eth-button dropdown show">
                    <a class="ethereum-coin btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span><img src="../../../assets/logos/MATIC.png"
                                alt=""></span>
                        Polygon
                    </a>
                </div>
                <div>
                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="ethereum-coin1 dropdown show">
                        <a class="btn" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"><span>...</span>

                        </a>
                    </div>
                </div>
            </div>
        </nav>
        <div class="main-boby">

            <div class="tab-content " id="pills-tabContent">
                <div class="swap-container tab-pane fade show active" id="pills-swap" role="tabpanel"
                    aria-labelledby="pills-swap-tab">

                    <div class="swap-contents">
                        <div class="card swap-contents-card">
                            <div class="swap-head card-header">
                                Hamper Swap
                            </div>
                            <div class="card-body">
                                <div class="card-input">
                                    <div class="input-card-top input-eth">
                                        <input type="number" 
                                            [(ngModel)]="amountA" 
                                            class="sizemin"
                                            placeholder="0" class="form-control" 
                                            [style.color]="errorInput ? 'red' : '' "
                                            aria-label="Text input with dropdown button" id="amountA" required>
                
                                        <div class="dropdown">
                                              <app-select-tokens class="btn" (onNftSelected)="nftASelected($event)" (onClean)="cleanNftA($event)"></app-select-tokens>
                                        </div>
                                        <button mat-mini-fab (click)="setMaxBalance()">MAX</button>
                                    </div>
                                    <div class="card-arrow">
                                        <img src="../../../assets/swap/img/arrow.png" alt="">
                                    </div>
                                    <div class="input-card-top">
                                        <input type="text" [(ngModel)]="amountB" class="form-control sizemin"
                                            aria-label="Text input with dropdown button" id="to-amount" disabled>
                                        <div class="dropdown input-eth">
                                            <app-select-tokens class="btn btn-primary" (onNftSelected)="nftBSelected($event)" (onClean)="cleanNftB($event)"></app-select-tokens>
                                        </div>
                                    </div>
                                    <div> <span id="gas-estimate"></span></div>
                                    
                                    <div class="center">
                                        Slippage: {{ slippage/100 }} %
                                        <mat-slider
                                            color="primary"
                                            thumbLabel
                                            [displayWith]="formatLabel"
                                            tickInterval="100"
                                            step="100"
                                            min="0"
                                            max="10000"
                                            [(ngModel)]="slippage"
                                            (change)="change($event)"
                                            aria-label="units">
                                            </mat-slider>
                                    </div>
                                </div>
                                <button type="button" [disabled]="amountA == 0 || tokenA.symbol == '' || tokenB.symbol == '' || errorInput==true" color="primary" class="connect-wallet-down" id="main-connect-wallet-button" (click)="amountB > 0 ? swap() : swapPrevio()">
                                     {{amountB > 0 ? 'SWAP' : 'Get Cotizaciones'}}
                                </button>
                                <mat-spinner *ngIf="loaded"></mat-spinner>
                                <div class="center">
                                    Price: {{ amountA / amountB | number: '1.5-5' }} {{ tokenA.symbol }} per {{ tokenB.symbol }}
                                    <!-- <mat-icon>autorenew</mat-icon> -->
                                </div>
                                <div class="center" *ngIf="amountB > 0">
                                    Cantidad mínima a recibir: {{ amountB * 1-(slippage / 10000) }} 
                                    <a *ngIf="linkOperation != '' " href="{{ linkOperation }}">Ver operación</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-boby">
                    Ruta óptima:
                    <table>
                        <thead>
                            <th>#</th>
                            <th>Name</th>
                            <th>Version</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let swap of bestSwap; let i = index">
                                <td>{{i}}</td>
                                <td>{{swap.name}}</td>
                                <td>{{swap.version}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>

                <!-- </div>
            </div> -->
        </div>
    </div>

    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js"
        integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
        crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossorigin="anonymous"></script>
        <script src="https://unpkg.com/moralis/dist/moralis.js"></script>
        <script src="./script.js"></script>
</body>

</html>