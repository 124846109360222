
<mat-dialog-content class="mat-typography">
    <mat-icon aria-hidden="false" aria-label="Example home icon">crop_rotate</mat-icon>
    <h1 mat-dialog-title>{{ tarjeta.nombre }}</h1>

    <h2>¡LLega un nuevo aprendizaje!</h2>
    <img class="img" width="300" src="assets/icons/cubos.png">
    <h2>Es el momento de aprender algo nuevo...</h2>
    <h2>¡Descúbrelo!</h2>

      <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary" class="big-round-shape-button" [routerLink]="['/pages/learning/bc/', tarjeta.id]">Ver nuevo aprendizaje</button>
      <p></p>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Salir</button>
  </mat-dialog-actions>