<div class="body">
    <mat-card [ngClass]="[nft.tipo == 'Cryptocurrency' ? 'color2' : 'color', nft.color]" id="image-section">
        <mat-card-header class="color-bar">
            <div mat-card-avatar class="tp-header-image"
            [style.background-image]="'url(../../../../assets/logos/' + nft.pais + '.png'">
            </div>
            <div class="container">
              <div class="fill"></div>
              <div><mat-card-subtitle>{{ nft.id }}/10</mat-card-subtitle></div>
            </div>
            <mat-card-title>{{ nft.nombre }}</mat-card-title>
            <mat-card-subtitle>{{ nft.tipo }}</mat-card-subtitle>
            <!-- <mat-icon>{{ nft.sector }}</mat-icon> -->
        </mat-card-header>

        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

        <mat-card-content>
<br><br>
          <img mat-card-lg-image [src]='"assets/logos/" + nft.icono + ".png"'>

            <p>
              {{ nft.desc }}
            </p>
            <br>

          </mat-card-content>

          <mat-card-actions align="end">
            <button mat-button>{{ nft.mercado }}</button>
            <button mat-button>{{ nft.icono }}</button>
            <button mat-button>Nivel: {{ nft.level }}</button>
          </mat-card-actions>

        <mat-card-actions>
        <button mat-button color="warn" [disabled]=true *ngIf="nft.sombra">Desbloqueada {{ nft.price }} HBLOCK</button>
        <button mat-raised-button color="primary" class="big-round-shape-button" (click)="info(nft)">INFO</button>
        <button mat-raised-button color="primary" class="big-round-shape-button" (click)="upLevelEmpresa(nft.index)">Subir Nivel</button>   
        <mat-spinner *ngIf="loaded"></mat-spinner>
        </mat-card-actions>

        <mat-card-actions align="end">
          <button mat-stroked-button color="accent">{{ nft.index + 1 }}/10000</button>
        </mat-card-actions>
        
    </mat-card>

    <!-- <button type="button" (click)="generateImage(id, level)">Create Image</button> -->
</div>