import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NftTarjetaComponent } from './nft-tarjeta/nft-tarjeta.component';
import { AppMaterialModule } from '../app-material.module';
import { NftInfoComponent } from './nft-info/nft-info.component';
import { SelectTokensComponent } from './select-tokens/select-tokens.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TablaAccionesComponent } from './tabla-acciones/tabla-acciones.component';
import { TablaDividendosComponent } from './tabla-dividendos/tabla-dividendos.component';



@NgModule({
  declarations: [
    NftTarjetaComponent,
    NftInfoComponent,
    SelectTokensComponent,
    TablaAccionesComponent,
    TablaDividendosComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppMaterialModule
  ],
  exports: [
    NftTarjetaComponent,
    NftInfoComponent,
    SelectTokensComponent,
    TablaAccionesComponent,
    TablaDividendosComponent
  ],
})
export class ComponentsModule { }
