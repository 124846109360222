<mat-card [ngClass]="cartera.bloqueada ? 'shadow' : ''">
    <mat-card-header class="color-bar" [ngClass]="cartera.color">
        <mat-card-title>{{ cartera.nombre }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-card-subtitle>{{ 'tiposCarteras.portfolioOf' | translate }} {{ cartera.tipo }}</mat-card-subtitle>
        <img mat-card-image [src]="cartera.tipo | imagen">
    </mat-card-content>
    <mat-card-footer>
      <button mat-button color="warn" [disabled]=true *ngIf="cartera.bloqueada">{{ 'carteras.unblocked' | translate }} HBLOCK</button>
      <button mat-button color="warn" (click)="desbloquear(cartera)" *ngIf="!cartera.bloqueada">{{ 'carteras.unblock' | translate }} HBLOCK</button> 
      <!-- {{ cartera.price }} -->
      <br>
      <button mat-raised-button [disabled]="!cartera.bloqueada" color="primary" class="big-round-shape-button" (click)="openDialog(cartera)">{{ 'carteras.plays' | translate }}</button>
    </mat-card-footer>
</mat-card>