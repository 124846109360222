<div class="table">
  <div class="board">
    <div class="center">
      <div class="community-chest-deck">
        <h2 class="label">Communidad</h2>
        <div class="deck"></div>
      </div>
      <img class="img logo" width="180px" src="assets/icons/cubos.png">
      <h1 class="title">HamperBlock</h1>
     <button class="boton" mat-flat-button color="primary" (click)="avanzarCasilla()">JUGAR</button>
      <div class="chance-deck">
        <h2 class="label">Suerte</h2>
        <mat-spinner *ngIf="loaded"></mat-spinner>
        <div class="deck"></div>
      </div>
    </div>

    <div class="space corner go">
      <div class="container">
        <div class="instructions">Recibe tu premio al pasar</div>
        <div class="go-word">GO!</div>
      </div>
      <fa-icon [icon]="faLongArrowAltLeft" class="arrow"></fa-icon>
    </div>

    <!-- Casillas de abajo -->

    <div class="row horizontal-row bottom-row">
      <div *ngFor="let tarjeta of tarjetas">
        <div class="space" [ngClass]="tarjeta.class">
          <div class="container" [ngClass]="tarjeta.bloqueada ? 'shadow' : ''">

              <div *ngIf="tarjeta.class == 'property'" [ngClass]="tarjeta.color">
                <mat-icon *ngIf="tarjeta.home" aria-hidden="false" aria-label="Example home icon">card_travel</mat-icon>
                <mat-icon *ngIf="!tarjeta.home"></mat-icon>
              </div>
              
              <!-- Opciones de tipos de tarjetas -->

              <!-- LAS DE BLOCKCHAIN ABREN LECCIONES ESPECIALES -->
              <div *ngIf="tarjeta.class == 'community-chest'">
                  <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialogBlockChain(tarjeta)" [style.color]="visitedColor"
                  >{{ tarjeta.nombre }}</button>
                  <button *ngIf="!tarjeta.bloqueada" class="name" mat-button color="primary" [style.color]="visitedColor"
                  >{{ tarjeta.nombre }}</button>
                  <img class="img" width="50px" src="assets/icons/{{ tarjeta.icon }}.png" alt="{{ tarjeta.tipo }}">
                  <br><br>
                  <div class="instructions">{{ tarjeta.instructions }}
                    <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
                  </div>
              </div>

              <!-- LAS DE BONUS O BONUS SPECIAL DAN NFTS-->
              <div *ngIf="tarjeta.class == 'railroad' || tarjeta.class == 'bonus2'">
                <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialogBonus(tarjeta)" [style.color]="visitedColor"
                >{{ tarjeta.nombre }}</button>
                <button *ngIf="!tarjeta.bloqueada" class="name" mat-button color="primary" [style.color]="visitedColor"
                >{{ tarjeta.nombre }}</button>
                <img class="img" width="50px" src="assets/icons/{{ tarjeta.icon }}.png" alt="{{ tarjeta.tipo }}">
                <br><br>
                <div class="instructions">{{ tarjeta.instructions }}
                  <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
                </div>
            </div>              

              <!-- LAS DE MINERIA O SUERTE DAN O QUITAN HBLOCKS-->
              <div *ngIf="tarjeta.class == 'fee income-tax' || tarjeta.class == 'chance'">
                <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialogMineria(tarjeta)" [style.color]="visitedColor"
                >{{ tarjeta.nombre }}</button>
                <button *ngIf="!tarjeta.bloqueada" class="name" mat-button color="primary" [style.color]="visitedColor"
                >{{ tarjeta.nombre }}</button>
                <img class="img" width="50px" [src]="tarjeta.icon ? 'assets/icons/suerte.png' : 'assets/mineria.png'">
                <div class="instructions">{{ tarjeta.instructions }}
                  <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
                </div>
              </div>

              <div *ngIf="tarjeta.class == 'property'">
                <div [ngClass]="tarjeta.bloqueada ? 'bloqueada' : ''">
                  <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialog(tarjeta)" [style.color]="visitedColor"
                  >{{ tarjeta.nombre }}</button>
                  <button *ngIf="!tarjeta.bloqueada" class="name" mat-button color="primary" [style.color]="visitedColor"
                  >{{ tarjeta.nombre }}</button>
                  <div  class="price">
                    {{ tarjeta.price }} HBLOCK
                    <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false">

                    </span>
  
                  </div>
                </div>
                
              </div>

          </div>
        </div>
      </div>

    </div>


    <div class="space corner jail">
      <div class="just">ESPECIAL</div>
     
      <div class="drawing">
        <div class="container">
          <img class="img" width="80px" src="assets/icons/special.png">
        </div>
      </div>
      <div class="visiting">ESPECIAL</div>
    </div>

    <!-- verticales izda -->

    <div class="row vertical-row left-row">

        <div class="space property" [ngClass]="tarjeta.class" *ngFor="let tarjeta of tarjetasIzq">
          <div class="container" [ngClass]="tarjeta.bloqueada ? 'shadow' : ''">

            <div *ngIf="tarjeta.class == 'property'" [ngClass]="tarjeta.color">
              <mat-icon *ngIf="tarjeta.home" aria-hidden="false" aria-label="Example home icon">card_travel</mat-icon>
              <mat-icon *ngIf="!tarjeta.home"></mat-icon>
            </div>
            
            <!-- LAS DE BLOCKCHAIN ABREN LECCIONES ESPECIALES -->
            <div *ngIf="tarjeta.class == 'community-chest'">
              <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialogBlockChain(tarjeta)" [style.color]="visitedColor"
              >{{ tarjeta.nombre }}</button>
              <button *ngIf="!tarjeta.bloqueada" class="name" mat-button color="primary" [style.color]="visitedColor"
              >{{ tarjeta.nombre }}</button>
              <img class="img" width="50px" src="assets/icons/{{ tarjeta.icon }}.png" alt="{{ tarjeta.tipo }}">
              <br><br>
              <div class="instructions">{{ tarjeta.instructions }}
                <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
              </div>
          </div>

          <!-- LAS DE BONUS O BONUS SPECIAL DAN NFTS-->
          <div *ngIf="tarjeta.class == 'railroad' || tarjeta.class == 'bonus2'">
            <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialogBonus(tarjeta)" [style.color]="visitedColor"
            >{{ tarjeta.nombre }}</button>
            <button *ngIf="!tarjeta.bloqueada" class="name" mat-button color="primary" [style.color]="visitedColor"
            >{{ tarjeta.nombre }}</button>
            <img class="img" width="50px" src="assets/icons/{{ tarjeta.icon }}.png" alt="{{ tarjeta.tipo }}">
            <br><br>
            <div class="instructions">{{ tarjeta.instructions }}
              <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
            </div>
        </div>              

          <!-- LAS DE MINERIA O SUERTE DAN O QUITAN HBLOCKS-->
          <div *ngIf="tarjeta.class == 'fee income-tax' || tarjeta.class == 'chance'">
            <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialogMineria(tarjeta)" [style.color]="visitedColor"
            >{{ tarjeta.nombre }}</button>
            <button *ngIf="!tarjeta.bloqueada" class="name" mat-button color="primary" [style.color]="visitedColor"
            >{{ tarjeta.nombre }}</button>
            <img class="img" width="50px" [src]="tarjeta.icon ? 'assets/icons/suerte.png' : 'assets/mineria.png'">
            <div class="instructions">{{ tarjeta.instructions }}
              <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
            </div>
          </div>

            <!-- <div *ngIf="tarjeta.class == 'community-chest' || tarjeta.class == 'railroad' || tarjeta.class == 'chance'">

                <button class="name" mat-button color="primary" (click)="openDialogComunidad(tarjeta)" [style.color]="visitedColor"
                >{{ tarjeta.nombre }}
                </button>
                <img class="img" width="50px" src="assets/icons/{{ tarjeta.icon }}.png" alt="{{ tarjeta.tipo }}">
                <br><br>
                <div class="instructions">{{ tarjeta.instructions }}
                  <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
                </div>
            </div>

            <div *ngIf="tarjeta.class == 'fee income-tax'">
              <button class="name" mat-button color="primary" (click)="openDialogComunidad(tarjeta)" [style.color]="visitedColor"
              >{{ tarjeta.nombre }}</button>
              <img class="img" width="50px" src="assets/mineria.png">
              <div class="instructions">{{ tarjeta.instructions }}
                <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
              </div>
            </div> -->

            <div *ngIf="tarjeta.class == 'property'">
              <div [ngClass]="tarjeta.bloqueada ? 'bloqueada' : ''">
                <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialog(tarjeta)" [style.color]="visitedColor"
                >{{ tarjeta.nombre }}</button>
                <div  class="price">
                  {{ tarjeta.price }} HBLOCK
                  <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false">

                  </span>

                </div>
              </div>
              
            </div>

          </div>
        </div>

    </div>

    <div class="space corner free-parking">
      <div class="container">
        <div class="name">Special</div>
        <img class="img" width="80px" src="assets/icons/special.png">
        <div class="name">Special</div>
      </div>
    </div>

    <!-- Casillas de arriba -->

    <div class="row horizontal-row top-row">

      <div class="space property" [ngClass]="tarjeta.class" *ngFor="let tarjeta of tarjetasArriba">
        <div class="container" [ngClass]="tarjeta.bloqueada ? 'shadow' : ''">

          <div *ngIf="tarjeta.class == 'property'" [ngClass]="tarjeta.color">
            <mat-icon *ngIf="tarjeta.home" aria-hidden="false" aria-label="Example home icon">card_travel</mat-icon>
            <mat-icon *ngIf="!tarjeta.home"></mat-icon>
          </div>
          
          <!-- Opciones de tipos de tarjetas <div *ngIf="tarjeta.bloqueada" class="bloqueada"> -->

          <div *ngIf="tarjeta.class == 'community-chest' || tarjeta.class == 'railroad' || tarjeta.class == 'chance'">

              <button class="name" mat-button color="primary" (click)="openDialogComunidad(tarjeta)" [style.color]="visitedColor"
              >{{ tarjeta.nombre }}
              </button>
              <img class="img" width="50px" src="assets/icons/{{ tarjeta.icon }}.png" alt="{{ tarjeta.tipo }}">
              <!-- <fa-icon [icon]="tarjeta.icon" class="drawing"></fa-icon> -->
              <br><br>
              <div class="instructions">{{ tarjeta.instructions }}
                <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
              </div>
          </div>

          <div *ngIf="tarjeta.class == 'fee income-tax'">
            <button class="name" mat-button color="primary" (click)="openDialogComunidad(tarjeta)" [style.color]="visitedColor"
            >{{ tarjeta.nombre }}</button>
            <img class="img" width="50px" src="assets/mineria.png">
            <div class="instructions">{{ tarjeta.instructions }}
              <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
            </div>
          </div>

          <div *ngIf="tarjeta.class == 'property'">
            <div [ngClass]="tarjeta.bloqueada ? 'bloqueada' : ''">
              <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialog(tarjeta)" [style.color]="visitedColor"
              >{{ tarjeta.nombre }}</button>
              <div  class="price">
                {{ tarjeta.price }} HBLOCK
                <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false">

                </span>

              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>


    <div class="space corner go-to-jail">
      <div class="container">
        <div class="name">Especial</div>
        <img class="img" width="80px" src="assets/icons/special.png">
        <div class="name">Especial</div>
      </div>
    </div>

    <!-- Casillas de derecha -->

    <div class="row vertical-row right-row">

      <div class="space property" [ngClass]="tarjeta.class" *ngFor="let tarjeta of tarjetasDrcha">
        <div class="container" [ngClass]="tarjeta.bloqueada ? 'shadow' : ''">

          <div *ngIf="tarjeta.class == 'property'" [ngClass]="tarjeta.color">
            <mat-icon *ngIf="tarjeta.home" aria-hidden="false" aria-label="Example home icon">card_travel</mat-icon>
            <mat-icon *ngIf="!tarjeta.home"></mat-icon>
          </div>
          
          <!-- Opciones de tipos de tarjetas <div *ngIf="tarjeta.bloqueada" class="bloqueada"> -->

          <div *ngIf="tarjeta.class == 'community-chest' || tarjeta.class == 'railroad' || tarjeta.class == 'chance'">

              <button class="name" mat-button color="primary" (click)="openDialogComunidad(tarjeta)" [style.color]="visitedColor"
              >{{ tarjeta.nombre }}
              </button>
              <img class="img" width="50px" src="assets/icons/{{ tarjeta.icon }}.png" alt="{{ tarjeta.tipo }}">
              <!-- <fa-icon [icon]="tarjeta.icon" class="drawing"></fa-icon> -->
              <br><br>
              <div class="instructions">{{ tarjeta.instructions }}
                <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
              </div>
          </div>

          <div *ngIf="tarjeta.class == 'fee income-tax'">
            <button class="name" mat-button color="primary" (click)="openDialogComunidad(tarjeta)" [style.color]="visitedColor"
            >{{ tarjeta.nombre }}</button>
            <img class="img" width="50px" src="assets/mineria.png">
            <div class="instructions">{{ tarjeta.instructions }}
              <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false"></span>
            </div>
          </div>

          <div *ngIf="tarjeta.class == 'property'">
            <div [ngClass]="tarjeta.bloqueada ? 'bloqueada' : ''">
              <button *ngIf="tarjeta.bloqueada" class="name" mat-button color="primary" (click)="openDialog(tarjeta)" [style.color]="visitedColor"
              >{{ tarjeta.nombre }}</button>
              <div  class="price">
                {{ tarjeta.price }} HBLOCK
                <span [matBadge]="tarjeta.id" matBadgePosition="after" matBadgeColor="accent" matBadgeOverlap="false">

                </span>

              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>

    
  </div>
</div>
