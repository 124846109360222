import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'AngularDapp';
  public activeLang = 'es';

  constructor(
    private translate: TranslateService,
    private contract: ContractService,
    ) {
    translate.setDefaultLang('es');
  }


  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
  }

  connectAccount() {
    this.contract
    .connectAccount()
    .then((value: any) => {
      console.log(value);
      // this.contract.comprobarRedOk();
     /* this.profile = this.threebox.getProfile(this.direction).then((response) => {
          console.log(response);
          this.profile = response;
          this.url = this.profile.image[0].contentUrl["/"];
        }); */
    })
    .catch((error: any) => {
      this.contract.failure(
        "Could't get the account data, please check if metamask is running correctly and refresh the page"
      );
    });
  }


}
