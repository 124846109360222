export let tokens = [
    {
        "token": "0x1b69D5b431825cd1fC68B8F883104835F3C72C80",
        "symbol": "HBlock",
        "logo": "https://polygonscan.com/token/images/hamperblock_32.png"
    },
    {
        "token": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        "symbol": "WMATIC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png"
    },
    {
        "token": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        "symbol": "USDC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
        "token": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
        "symbol": "WETH",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
        "token": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        "symbol": "USDT",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
        "token": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
        "symbol": "DAI",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
        "token": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
        "symbol": "WBTC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
        "token": "0xa3Fa99A148fA48D14Ed51d610c367C61876997F1",
        "symbol": "MIMATIC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10238.png"
    },
    {
        "token": "0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A",
        "symbol": "TITAN",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10467.png"
    },
    {
        "token": "0x5647Fe4281F8F6F01E84BCE775AD4b828A7b8927",
        "symbol": "MM",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10866.png"
    },
    {
        "token": "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec",
        "symbol": "SHIB",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png"
    },
    {
        "token": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
        "symbol": "LINK",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
        "token": "0x26d326B1fc702260baeB62334d7c1Da6f1a2C386",
        "symbol": "GTPS",
        "logo": "https://polygonscan.com/token/images/gtpsfinance_32.png"
    },
    {
        "token": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
        "symbol": "GHST",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/7046.png"
    },
    {
        "token": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
        "symbol": "AAVE",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png"
    },
    {
        "token": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
        "symbol": "FISH",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10134.png"
    },
    {
        "token": "0x0000000000000000000000000000000000001010",
        "symbol": "MATIC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
    },
    {
        "token": "0xE0339c80fFDE91F3e20494Df88d4206D86024cdF",
        "symbol": "ELON",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/9436.png"
    },
    {
        "token": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
        "symbol": "UNI",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
        "token": "0xf28164A485B0B2C90639E47b0f377b4a438a16B1",
        "symbol": "dQUICK",
        "logo": "https://polygonscan.com/token/images/dragonquick_32.png"
    },
    {
        "token": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
        "symbol": "TEL",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/2394.png"
    },
    {
        "token": "0x9cd6746665D9557e1B9a775819625711d0693439",
        "symbol": "LUNC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/4172.png"
    },
    {
        "token": "0x20D3922b4a1A8560E1aC99FBA4faDe0c849e2142",
        "symbol": "MAWETH",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/8924.png"
    },
    {
        "token": "0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b",
        "symbol": "AVAX",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png"
    },
    {
        "token": "0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C",
        "symbol": "HEX",
        "logo": "https://polygonscan.com/token/images/hex_32.png"
    },
    {
        "token": "0x9Bbcda2606e616659b118399A2823E8a392f55DA",
        "symbol": "GIVE",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10838.png"
    },
    {
        "token": "0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16",
        "symbol": "WOLF",
        "logo": "https://polygonscan.com/token/images/moonwolf-wolf_32.png"
    },
    {
        "token": "0x13748d548D95D78a3c83fe3F32604B4796CFfa23",
        "symbol": "KOGECOIN",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10665.png"
    },
    {
        "token": "0x23001f892c0C82b79303EDC9B9033cD190BB21c7",
        "symbol": "LUSD",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/9566.png"
    },
    {
        "token": "0x580A84C73811E1839F75d86d75d88cCa0c241fF4",
        "symbol": "QI",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10237.png"
    },
    {
        "token": "0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4",
        "symbol": "SOL",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png"
    },
    {
        "token": "0xCBf4AB00b6Aa19B4d5D29C7c3508B393a1C01Fe3",
        "symbol": "MegaDoge",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/18254.png"
    },
    {
        "token": "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4",
        "symbol": "MANA",
        "logo": "https://polygonscan.com/token/images/decentraland_32.png"
    },
    {
        "token": "0x0000000000004946c0e9F43F4Dee607b0eF1fA1c",
        "symbol": "CHI",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/5709.png"
    },
    {
        "token": "0x6AE7Dfc73E0dDE2aa99ac063DcF7e8A63265108c",
        "symbol": "JPYC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/9045.png"
    },
    {
        "token": "0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891",
        "symbol": "YELD",
        "logo": "https://polygonscan.com/token/images/polyyeld_32.png"
    },
    {
        "token": "0xD86b5923F3AD7b585eD81B448170ae026c65ae9a",
        "symbol": "IRON",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10484.png"
    },
    {
        "token": "0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F",
        "symbol": "BNB",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
    },
    {
        "token": "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89",
        "symbol": "FRAX",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
    },
    {
        "token": "0x7e9928aFe96FefB820b85B4CE6597B8F660Fe4F4",
        "symbol": "PBNB",
        "logo": "https://polygonscan.com/token/images/pbnb_32.png"
    },
    {
        "token": "0x19A935CbAaa4099072479d521962588D7857D717",
        "symbol": "LitCoin",
        "logo": "https://polygonscan.com/token/images/polygolitcoin_32.png"
    },
    {
        "token": "0xe6FC6C7CB6d2c31b359A49A33eF08aB87F4dE7CE",
        "symbol": "IGG",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/4054.png"
    },
    {
        "token": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
        "symbol": "MAUSDC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/8918.png"
    },
    {
        "token": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
        "symbol": "KRILL",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/9459.png"
    },
    {
        "token": "0xbe9512e2754cb938dd69Bbb96c8a09Cb28a02D6D",
        "symbol": "GBTS",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10834.png"
    },
    {
        "token": "0x6bb45cEAC714c52342Ef73ec663479da35934bf7",
        "symbol": "BONE",
        "logo": "https://polygonscan.com/token/images/polypup-bone_32.png"
    },
    {
        "token": "0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59",
        "symbol": "PUP",
        "logo": "https://polygonscan.com/token/images/polypup-pup_32.png"
    },
    {
        "token": "0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef",
        "symbol": "ICE",
        "logo": "https://polygonscan.com/token/images/ironfinanceicetoken_32.png"
    },
    {
        "token": "0xAa9654BECca45B5BDFA5ac646c939C62b527D394",
        "symbol": "DINO",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/10777.png"
    },
    {
        "token": "0x229b1b6C23ff8953D663C4cBB519717e323a0a84",
        "symbol": "BLOK",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/11206.png"
    },
    {
        "token": "0x9a05D1FF699ea187Dc8523E333eD63503f0d82db",
        "symbol": "BABYQUICK",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/13927.png"
    },
    {
        "token": "0x71B821aa52a49F32EEd535fCA6Eb5aa130085978",
        "symbol": "0xBTC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/2837.png"
    },
    {
        "token": "0x172370d5Cd63279eFa6d502DAB29171933a610AF",
        "symbol": "CRV",
        "logo": "https://polygonscan.com/token/images/curvefi-crv_32.png"
    },
    {
        "token": "0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97",
        "symbol": "DFYN",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/9511.png"
    },
    {
        "token": "0x30DE46509Dbc3a491128F97be0aAf70dc7Ff33cB",
        "symbol": "xZAR",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/5946.png"
    },
    {
        "token": "0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59",
        "symbol": "USTC",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png"
    },
    {
        "token": "0x5d47bAbA0d66083C52009271faF3F50DCc01023C",
        "symbol": "BANANA",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/8497.png"
    },
    {
        "token": "0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683",
        "symbol": "SAND",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/6210.png"
    },
    {
        "token": "0xDA537104D6A5edd53c6fBba9A898708E465260b6",
        "symbol": "YFI",
        "logo": "https://polygonscan.com/token/images/yearn-yfi_32.png"
    },
    {
        "token": "0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5",
        "symbol": "FTM",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png"
    },
    {
        "token": "0xc6C855AD634dCDAd23e64DA71Ba85b8C51E5aD7c",
        "symbol": "ICE",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/13133.png"
    },
    {
        "token": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
        "symbol": "ADDY",
        "logo": "https://polygonscan.com/token/images/adamant_32.png"
    },
    {
        "token": "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a",
        "symbol": "SUSHI",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png"
    },
    {
        "token": "0xcE829A89d4A55a63418bcC43F00145adef0eDB8E",
        "symbol": "RENDOGE",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/8363.png"
    },
    {
        "token": "0x0e59D50adD2d90f5111aca875baE0a72D95B4762",
        "symbol": "DARK",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/7325.png"
    },
    {
        "token": "0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F",
        "symbol": "SWAP",
        "logo": "https://s2.coinmarketcap.com/static/img/coins/64x64/5829.png"
    },
    {
        "token": "0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c",
        "symbol": "COMP",
        "logo": "https://polygonscan.com/token/images/comp_32.png"
    }
]