<!-- <div class="preloader">
  <div class="preloader_image"></div>
</div> -->

<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">

<!-- search modal -->
<!-- <div class="modal" tabindex="-1" role="dialog" aria-labelledby="search_modal" id="search_modal">
  <div class="widget widget_search">
    <form method="get" class="searchform form-inline" action="/">
      <div class="form-group">
        <input type="text" value="" name="search" class="form-control" placeholder="Search keyword" id="modal-search-input">
      </div>
      <button type="submit" class="theme_button">Search</button>
    </form>
  </div>
</div> -->

<!-- wrappers for visual page editor and boxed version of template -->
<div id="canvas">
  <div id="box_wrapper">

    <!-- template sections -->

    <section class="ds section_padding_110">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-2 col-md-8 text-center">
            <div class="cornered-heading center-heading bottommargin_40">
              <img class="img" width="150px" src="assets/icons/cubos.png">
              <h1 class="text-uppercase">¿QUÉ ES HAMPERBLOCK?</h1>
              <span class="text-uppercase">Inversión en carteras descentralizadas</span>
            </div>
            <p>
              <em>
                HamperBlock es un juego que te permite aprender a invertir en carteras descentralizadas. Queremos que aprendas a invertir de una forma entretenida y didáctica a la vez que inviertes con nuestro Token, el HBLOCK. Si quieres saber más sobre nuestro proyecto lee el WhitePaper
              </em>
            </p>
            <a mat-raised-button class="big-round-shape-button" target="_blank" a href="https://hamper-block.gitbook.io/hamper-block-whitepaper/"> WHITEPAPER </a>
            <br>
            <p>Antes de empezar debes conectar con tu Metamask y asegurarte de que estás en la red de Polygon</p>
            <button mat-raised-button color="primary" class="big-round-shape-button" (click)="connectAccount()"> {{ 'account.connect_account' | translate }}</button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0" *ngFor="let teaser of teasers">
            <div class="bg_teaser light_teaser with_shadow with_corners text-center topmargin_25">
              <div class="bg_overlay"></div>
              <img src="../images/teaser01.jpg">
              <div class="teaser_content">
                <div class="teaser text-center">
                  <div class="teaser_icon grey size_big">
                    <img width="250px" src="../../../../assets/images/{{ teaser.image }}.png" [alt]="teaser.alt">
                    <!-- <i class="flaticon-solar-panel"></i> -->
                  </div>
                  <h4 class="text-uppercase topmargin_25">{{ teaser.title }}</h4>
                  <hr class="divider_2_40 bottommargin_25">
                  <p class="darken-font-color">
                    {{ teaser.desc }}
                  </p>
                  <!-- <a href="#" class="theme_button color1">Read More</a> -->
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section id="comingsoon1" class="ds ms comingsoon">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">

            <p class="text-uppercase grey fontsize_18 bottommargin_0">Since 02-02-2022</p>
            <h2 class="highlight text-uppercase">
              WE ARE LIVE!
            </h2>

            <!-- <div class="text-uppercase fontsize_24" id="comingsoon-countdown"></div>
            <br> -->

            <h4>Official Contract Address: 0x1b69d5b431825cd1fc68b8f883104835f3c72c80</h4>

            <p class="topmargin_60">
              <a target="_blank" href="https://www.dextools.io/app/polygon/pair-explorer/0x4d9c94c6f685e7640c1b69795aefc1c153a805c2" class="theme_button color1">COMPRAR TOKEN</a>
            </p>

          </div>
        </div>
      </div>
    </section>

    <section class="ds section_padding_110 columns_margin_0">
      <div class="container">
        <div class="row">
          <div class="col-md-4 text-center text-md-left to_animate" data-animation="fadeInLeft">
            <div class="cornered-heading bottommargin_60">
              <h2 class="text-uppercase">TOKENOMICS</h2>
              <span class="text-uppercase">Asi funciona nuestro criptojuego</span>
            </div>

            <img width="400px" src="../../../../assets/images/tokenomic.png">
            <!-- <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent dapibus erat in tincidunt consequat. Phasellus sit amet convallis turpis, id molestie nunc.
            </p> -->
          </div>

          <div class="col-md-4 col-sm-6">

            <div class="teaser media inline-block topmargin_50 features-teaser to_animate" 
                  data-animation="pullDown"
                  *ngFor="let tokenomic of tokenomics1">
              <div class="media-left media-middle">
                <div class="teaser_icon fontsize_36 grey with_background">
                  <i [class]="tokenomic.image"></i>
                </div>
              </div>
              <div class="media-body media-middle text-left">
                <h5 class="text-uppercase">
                  <a href="#">{{ tokenomic.title }}</a>
                </h5>
                <p>{{ tokenomic.desc }}</p>
              </div>
            </div>

          </div>
          

          <div class="col-md-4 col-sm-6">

            <div class="teaser media inline-block topmargin_50 features-teaser to_animate" 
                data-animation="pullDown"
                *ngFor="let tokenomic of tokenomics2">
              <div class="media-left media-middle">
                <div class="teaser_icon fontsize_36 grey with_background">
                  <i [class]="tokenomic.image"></i>
                </div>
              </div>
              <div class="media-body media-middle text-left">
                <h5 class="text-uppercase">
                  <a href="#">{{ tokenomic.title }}</a>
                </h5>
                <p>{{ tokenomic.desc }}</p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>


    <!-- <section class="ds section_padding_50 counters_section">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">

            <div class="teaser info-teaser text-center" *ngFor="let dato of datos">
              <span class="info-icon info-project teaser_icon"></span>
              <br>
              <span class="counter highlight" data-from="0" [data-to]="dato.numero" data-speed="1500">0</span>
              <p class="bold text-uppercase">{{ dato.title }}</p>
            </div>

          </div>
        </div>
      </div>
    </section> -->


    <section class="cs section_padding_110 services">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-6">
            <div class="cornered-heading bottommargin_60">
              <span class="text-uppercase">Estáte atento</span>
              <h2 class="text-uppercase">FASES DE LANZAMIENTO</h2>
            </div>
            <p class="bottommargin_0">
              Salimos en enero de 2022, asi que prepara tu MetaMask y comprueba que estás en la red de Polygon. Nuestros HBLOCK ya tienen ganas de estar en tus manos y de que los uses para completar nuestro tablero de carteras.
            </p>
            <div class="row columns_margin_0 service-teasers-row">

              <div class="col-sm-4 col-xs-6 to_animate" data-animation="pullUp" *ngFor="let fase of fases">
                <div class="service-teaser with_corners hover_corners small_corners topmargin_30">
                  <div class="teaser_content">
                    <div class="teaser">
                      <div class="teaser_icon grey size_normal">
                        <i [class]="fase.image"></i>
                      </div>
                      <p class="text-uppercase raleway bold darklinks bottommargin_0">
                        <a [href]="fase.link" target="_blank">{{ fase.title }}</a>
                      </p>
                      <a href="https://dx.app/app/v3/defipresale?saleID=104&chain=Matic" target="_blank">Access IDO</a>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="ls section_padding_110">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-2 col-md-8 text-center">
            <div class="cornered-heading center-heading bottommargin_40">
              <h2 class="text-uppercase">New from blog</h2>
              <span class="text-uppercase">Recent News</span>
            </div>
            <p>
              Curabitur dolor metus, accumsan vel iaculis eu, venenatis a turpis. Vestibulum mollis, nulla at tristique varius, ipsum diam tempus erat, nec dignissim ex lacus at velit. Etiam odio tortor, ultrices ultrices enim quis.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <article class="vertical-item content-absolute bottom-content blog-item ds topmargin_25">

              <div class="item-media entry-thumbnail">
                <div class="entry-meta-corner">
                  <span class="date">
                    <time datetime="2016-08-01T15:05:23+00:00" class="entry-date">
                      <strong>08</strong>
                      Jan
                    </time>
                  </span>
                </div>
                <img src="images/gallery/14.jpg" alt="">
                <div class="media-links">
                  <div class="links-wrap darklinks">
                    <a class="p-view prettyPhoto" title="" data-gal="prettyPhoto[gal]" href="images/gallery/14.jpg"></a>
                    <a class="p-link" href="blog-single-right.html"></a>
                  </div>
                </div>
              </div>

              <div class="item-content entry-content">
                <header class="entry-header">
                  <h4 class="text-uppercase">
                    <a href="blog-single-right.html" rel="bookmark">Lorem ipsum dolor sit amet, consecter adipiscing elit Donec</a>
                  </h4>
                  <p class="item-meta greylinks text-uppercase">
                    <a href="#">
                      <i class="flaticon-social"></i>By Anna Fry</a>
                    <a href="#" class="pull-right">
                      <i class="flaticon-communication"></i>
                      <span class="comments-amount">4</span>
                    </a>
                  </p>
                </header>

              </div>
            </article>
          </div>
          <div class="col-lg-6">
            <div class="panel-group blog-accordion bottommargin_0 topmargin_25" id="accordion1">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="entry-meta-corner">
                    <span class="date">
                      <time datetime="2016-08-01T15:05:23+00:00" class="entry-date">
                        <strong>03</strong>
                        JUL
                      </time>
                    </span>
                  </div>
                  <div class="media-links">
                    <a class="abs-link collapsed" data-toggle="collapse" data-parent="#accordion1" href="#collapse1"></a>
                  </div>
                  <div class="panel-title">
                    <h5>
                      <a href="blog-single-right.html">
                        Vivamus consequat, ipsum et blandit fermentum.
                      </a>
                    </h5>
                    <p class="item-meta greylinks text-uppercase">
                      <a href="#">
                        <i class="flaticon-social"></i>By Anna Fry</a>
                      <a href="#" class="pull-right">
                        <i class="flaticon-communication"></i>
                        <span class="comments-amount">4</span>
                      </a>
                    </p>
                  </div>
                </div>
                <div id="collapse1" class="panel-collapse collapse">
                  <div class="panel-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium amet ea dicta neque, ut quis omnis quos nam, pariatur, minus, fugit suscipit aspernatur sint ullam quas explicabo. Alias, officiis, dolor!
                  </div>
                </div>
              </div>

              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="entry-meta-corner">
                    <span class="date">
                      <time datetime="2016-08-01T15:05:23+00:00" class="entry-date">
                        <strong>08</strong>
                        JUL
                      </time>
                    </span>
                  </div>
                  <div class="media-links">
                    <a class="abs-link collapsed" data-toggle="collapse" data-parent="#accordion1" href="#collapse2"></a>
                  </div>
                  <div class="panel-title">
                    <h5>
                      <a href="blog-single-right.html">
                        Aliquam non finibus lectus, id pretium mi erat.
                      </a>
                    </h5>
                    <p class="item-meta greylinks text-uppercase">
                      <a href="#">
                        <i class="flaticon-social"></i>By Anna Fry</a>
                      <a href="#" class="pull-right">
                        <i class="flaticon-communication"></i>
                        <span class="comments-amount">14</span>
                      </a>
                    </p>
                  </div>
                </div>
                <div id="collapse2" class="panel-collapse collapse">
                  <div class="panel-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium amet ea dicta neque, ut quis omnis quos nam, pariatur, minus, fugit suscipit aspernatur sint ullam quas explicabo. Alias, officiis, dolor!
                  </div>
                </div>
              </div>

              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="entry-meta-corner">
                    <span class="date">
                      <time datetime="2016-08-01T15:05:23+00:00" class="entry-date">
                        <strong>10</strong>
                        JUL
                      </time>
                    </span>
                  </div>
                  <div class="media-links">
                    <a class="abs-link collapsed" data-toggle="collapse" data-parent="#accordion1" href="#collapse3"></a>
                  </div>
                  <div class="panel-title">
                    <h5>
                      <a href="blog-single-right.html">
                        Praesent molestie, orci bland Donec elementum.
                      </a>
                    </h5>
                    <p class="item-meta greylinks text-uppercase">
                      <a href="#">
                        <i class="flaticon-social"></i>By Anna Fry</a>
                      <a href="#" class="pull-right">
                        <i class="flaticon-communication"></i>
                        <span class="comments-amount">9</span>
                      </a>
                    </p>
                  </div>
                </div>
                <div id="collapse3" class="panel-collapse collapse">
                  <div class="panel-body">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium amet ea dicta neque, ut quis omnis quos nam, pariatur, minus, fugit suscipit aspernatur sint ullam quas explicabo. Alias, officiis, dolor!
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="ds section_padding_90 page_testimonials parallax">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="flexslider">

              <div class="container">
                <div class="row bottommargin_15">
                  <div class="col-sm-12 text-center">
                    <div class="cornered-heading center-heading">
                      <span class="text-uppercase">What people say</span>
                      <h2 class="text-uppercase">Our testimonials</h2>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">

                    <ul class="slides">
                      <li>
                        <div class="row">
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/1.jpg" alt=""> Proin sollicitudin tempor arcu, id vestibulum mi suscipit et. Praesent ac erat tristique, iaculis neque et, porta libero. Duis sodales.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Derrick Rodgers</h5>
                                <p>Manager</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/2.jpg" alt=""> Duis congue purus non malesuada porttitor. Integer vitae orci ac orci tincidunt efficitur. Sed tristique magna a nulla porttitor congue.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Minerva Ferguson</h5>
                                <p>Founder &amp; CEO</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/3.jpg" alt=""> Cras id sem posuere, mollis purus a, placerat turpis. Donec blandit tellus viverra, volutpat urna ac, molestie orci.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Raymond Cummings</h5>
                                <p>Public Relationship</p>
                              </div>
                            </blockquote>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/1.jpg" alt=""> Proin sollicitudin tempor arcu, id vestibulum mi suscipit et. Praesent ac erat tristique, iaculis neque et, porta libero. Duis sodales.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Derrick Rodgers</h5>
                                <p>Manager</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/2.jpg" alt=""> Duis congue purus non malesuada porttitor. Integer vitae orci ac orci tincidunt efficitur. Sed tristique magna a nulla porttitor congue.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Minerva Ferguson</h5>
                                <p>Founder &amp; CEO</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/3.jpg" alt=""> Cras id sem posuere, mollis purus a, placerat turpis. Donec blandit tellus viverra, volutpat urna ac, molestie orci.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Raymond Cummings</h5>
                                <p>Public Relationship</p>
                              </div>
                            </blockquote>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/1.jpg" alt=""> Proin sollicitudin tempor arcu, id vestibulum mi suscipit et. Praesent ac erat tristique, iaculis neque et, porta libero. Duis sodales.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Derrick Rodgers</h5>
                                <p>Manager</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/2.jpg" alt=""> Duis congue purus non malesuada porttitor. Integer vitae orci ac orci tincidunt efficitur. Sed tristique magna a nulla porttitor congue.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Minerva Ferguson</h5>
                                <p>Founder &amp; CEO</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/3.jpg" alt=""> Cras id sem posuere, mollis purus a, placerat turpis. Donec blandit tellus viverra, volutpat urna ac, molestie orci.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Raymond Cummings</h5>
                                <p>Public Relationship</p>
                              </div>
                            </blockquote>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/1.jpg" alt=""> Proin sollicitudin tempor arcu, id vestibulum mi suscipit et. Praesent ac erat tristique, iaculis neque et, porta libero. Duis sodales.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Derrick Rodgers</h5>
                                <p>Manager</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/2.jpg" alt=""> Duis congue purus non malesuada porttitor. Integer vitae orci ac orci tincidunt efficitur. Sed tristique magna a nulla porttitor congue.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Minerva Ferguson</h5>
                                <p>Founder &amp; CEO</p>
                              </div>
                            </blockquote>
                          </div>
                          <div class="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4">
                            <blockquote class="blockquote-vertical with_bottom_border ls topmargin_50">
                              <img src="./images/faces/3.jpg" alt=""> Cras id sem posuere, mollis purus a, placerat turpis. Donec blandit tellus viverra, volutpat urna ac, molestie orci.
                              <hr class="divider_3_60 main_bg_color">
                              <div class="blockquote-meta">
                                <h5>Raymond Cummings</h5>
                                <p>Public Relationship</p>
                              </div>
                            </blockquote>
                          </div>
                        </div>
                      </li>

                    </ul>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </section> -->

    <section class="ds section_padding_top_110 section_padding_bottom_75">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-2 col-md-8 text-center">
            <div class="cornered-heading center-heading bottommargin_40">
              

              <img width="600px" src="../../../../assets/images/tax-fee.png">

            </div>
            <p>
              Como parte de nuestro compromiso con los holders, vamos a establecer unas tarifas sobre las compras y ventas del token, que se distribuirán de la siguiente forma:
<br><br>	1% Reflection: Irá destinado directamente al reparto entre los holders, premiando así tanto a los que más tiempo permanezcan en el proyecto como a los que tengan un mayor volumen.
<br><br>	1% Autoliquidity: Irá destinado directamente a la liquidez del pool, fortaleciendo las finanzas del token.
<br><br>  Sistema Anti-Whale: No se podrá comprar o vender más del 1% del total supply.
            </p>
          </div>
        </div>


        <div class="row with_shadow topmargin_40 to_animate" data-animation="fadeInDown">

          <div class="col-sm-4 col-lg-offset-1 col-lg-3 text-center">
            <div class="teaser inline-block topmargin_30 bottommargin_20">
              <div class="media-left">
                <div class="teaser_icon size_small grey">
                  <i class="flaticon-arrows"></i>
                </div>
              </div>
              <div class="media-body text-left">
                <h5 class="text-uppercase fontsize_16 extra-bold"> Tarjetas Cartera</h5>
                <img width="200px" src="../../../../assets/images/cartera-ej.png">
                <p>22 tarjetas tipo cartera distribuidas en 8 colores. De lo simple a lo complejo.</p>
              </div>
            </div>
          </div>

          <div class="col-sm-4 col-lg-offset-1 col-lg-3 text-center">
            <div class="teaser inline-block topmargin_30 bottommargin_20">
              <div class="media-left">
                <div class="teaser_icon size_small grey">
                  <i class="flaticon-glasses"></i>
                </div>
              </div>
              <div class="media-body text-left">
                <h5 class="text-uppercase fontsize_16 extra-bold">Suerte y BC</h5>
                <img width="200px" src="../../../../assets/images/suerte.png">
                <p>En estas tarjetas vas a aprender diferentes aspectos técnicos y fundamentales de la inversión.</p>
              </div>
            </div>
          </div>

          <div class="col-sm-4 col-lg-offset-1 col-lg-3 text-center">
            <div class="teaser inline-block topmargin_30 bottommargin_20">
              <div class="media-left">
                <div class="teaser_icon size_small grey">
                  <i class="flaticon-magnifying-glass"></i>
                </div>
              </div>
              <div class="media-body text-left">
                <h5 class="text-uppercase fontsize_16 extra-bold">Tarjetas Bonus</h5>
                <p class="greylinks">
                  <img width="200px" src="../../../../assets/images/bonus.png">
                  <p>En estas casillas tendrás la posibilidad de coleccionar las mejores empresas en formato NFT.</p>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row topmargin_35 contact-form-container to_animate" data-animation="fadeInUp">
          <div class="col-sm-12 text-center">
            <span class="text-uppercase">Short loin meatloaf</span>
            <h2 class="text-uppercase topmargin_5 bottommargin_25">Contact Form</h2>

            <form class="contact-form row columns_margin_0" method="post" action="/">
              <div class="col-sm-6">
                <div class="contact-form-name form-group">
                  <label for="name" class="sr-only">Full Name
                    <span class="required">*</span>
                  </label>
                  <input type="text" aria-required="true" size="30" value="" name="name" id="name" class="form-control" placeholder="Enter your Name">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="contact-form-subject form-group">
                  <label for="email" class="sr-only">Email
                    <span class="required">*</span>
                  </label>
                  <input type="text" aria-required="true" size="30" value="" name="subject" id="email" class="form-control" placeholder="Enter your Email">
                </div>
              </div>
              <div class="col-sm-12">
                <div class="contact-form-phone form-group">
                  <label for="subject" class="sr-only">Subject
                    <span class="required">*</span>
                  </label>
                  <input type="text" aria-required="true" size="30" value="" name="subject" id="subject" class="form-control" placeholder="Enter your Subject">
                </div>
              </div>
              <div class="col-sm-12">

                <div class="contact-form-message form-group">
                  <label for="message" class="sr-only">Message</label>
                  <textarea aria-required="true" rows="5" cols="45" name="message" id="message" class="form-control" placeholder="Enter your Message..."></textarea>
                </div>
              </div>

              <div class="col-sm-12">
                <div class="contact-form-submit topmargin_10 bottommargin_25">
                  <button type="submit" id="contact_form_submit" name="contact_submit" class="theme_button color1 margin_0">Send Message</button>
                </div>
              </div>
            </form>

          </div>
        </div> -->


      </div>
    </section>

    <!-- LINEA ROSA -->
    <section class="cs section_padding_25">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
          </div>
        </div>
      </div>
    </section> 

    <footer class="page_footer theme_footer ds ms parallax section_padding_top_90 section_padding_bottom_50">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6" data-animation="scaleAppear">
            <div class="widget widget_text topmargin_20">
              <p class="bottommargin_30">
                <!-- <img src="images/logo.png" alt=""> -->
              </p>
              <p class="bottommargin_25">
                En breve nos encontrarás en otras redes como Discord, por ahora sigue nuestra cuenta de Twitter desde el siguiente enlace
              </p>
              <div class="page_social_icons inline-block darklinks">
                <!-- <a class="social-icon soc-facebook" href="#" title="Facebook"></a> -->
                <a class="social-icon soc-twitter" href="https://twitter.com/HamperBlock"  target="_blank" title="Twitter"></a>
                <a class="social-icon soc-rss" href="https://t.me/hamperblock" target="_blank"></a>
                <a class="social-icon soc-disqus" href="https://discord.gg/jvVgMCa5" target="_blank"></a>
                <!-- <a class="social-icon soc-google" href="#" title="Google"></a> -->
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6" data-animation="scaleAppear">
            <div class="widget widget_text topmargin_25">
              <h4 class="text-uppercase bottommargin_20">contact us</h4>
              <div class="media">
                <div class="media-left">
                  <i class="fa fa-map-marker highlight fontsize_18"></i>
                </div>
                <div class="media-body">
                  Granada, Spain
                </div>
              </div>
              <!-- <div class="media">
                <div class="media-left">
                  <i class="fa fa-phone highlight fontsize_18"></i>
                </div>
              </div> -->
              <div class="media">
                <div class="media-left">
                  <i class="fa fa-envelope highlight fontsize_18"></i>
                </div>
                <div class="media-body greylinks">
                  <a href="#">info@hamperblock.com</a>
                  <br>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-3 col-sm-6" data-animation="scaleAppear">
            <div class="widget widget_twitter topmargin_25">
              <h4 class="text-uppercase bottommargin_20">Twitter widget</h4>
              <div class="twitter"></div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6" data-animation="scaleAppear">
            <div class="widget widget_slider topmargin_25">

              <h4 class="widget-title text-uppercase">Más</h4>
              <!-- <div class="owl-carousel topmargin_30" data-nav="false" data-dots="true" data-loop="true" data-autoplay="true" data-items="1" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-responsive-xs="1">
                <div class="vertical-item">
                  <div class="item-media">
                    <img src="images/gallery/07.jpg" alt="">
                    <div class="media-links middle-links type2">
                      <div class="links-wrap">
                        <a class="p-view prettyPhoto" title="" data-gal="prettyPhoto[gal]" href="images/gallery/07.jpg"></a>
                        <a href="#" class="p-like"></a>
                        <a class="p-link" href="gallery-single.html"></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vertical-item">
                  <div class="item-media">
                    <img src="images/gallery/08.jpg" alt="">
                    <div class="media-links middle-links type2">
                      <div class="links-wrap">
                        <a class="p-view prettyPhoto" title="" data-gal="prettyPhoto[gal]" href="images/gallery/08.jpg"></a>
                        <a href="#" class="p-like"></a>
                        <a class="p-link" href="gallery-single.html"></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vertical-item">
                  <div class="item-media">
                    <img src="images/gallery/09.jpg" alt="">
                    <div class="media-links middle-links type2">
                      <div class="links-wrap">
                        <a class="p-view prettyPhoto" title="" data-gal="prettyPhoto[gal]" href="images/gallery/09.jpg"></a>
                        <a href="#" class="p-like"></a>
                        <a class="p-link" href="gallery-single.html"></a>
                      </div>
                    </div>
                  </div>
                </div> 


              </div>-->

            </div>
          </div>
        </div>
      </div>
    </footer>

    <section class="page_copyright ds ms parallax table_section section_padding_25">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <p class="darklinks fontsize_12">&copy; 2021-{{today | date:'yyyy'}}
              <span class="text-uppercase">HamperBlock</span> | Created with
              <i class="fa fa-heart-o highlight"></i>
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
  <!-- eof #box_wrapper -->
</div>
<!-- eof #canvas -->



