<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class="body">
  <mat-card class="register">
    <h1>{{ 'home.welcome' | translate }}
    </h1>
    <h2>
      {{ 'home.dex' | translate }}
    </h2>
    <p>{{ 'home.know' | translate }}</p>
    <div style="text-align:center">
      <a href="https://twitter.com/HamperBlock" target="_blank" class="fa fa-twitter"></a>
      <a href="https://t.me/hamperblock" target="_blank" class="fa fa-telegram"></a>
      <a href="https://discord.gg/jvVgMCa5" target="_blank" class="fa fa-rss"></a>
    </div>
    <div>
    </div>

    <br>
    <h3>Novedades de la versión v1.5.1 (12/09/22)</h3>
    <ul class="novedades">
      <li>
        Ahora puedes desbloquear carteras pagando con USDC
      </li>
    </ul>
    <h3>Novedades de la versión v1.5.0 (29/07/22)</h3>
    <ul class="novedades">
      <li>
        Cartera crypto casilla 11
        Swap funcionando correctamente
        Varios arreglos pendientes
      </li>
    </ul>
    <h3>Novedades de la versión v1.4.0 (07/06/22)</h3>
    <ul class="novedades">
      <li>
        Lanzamiento del HamperSwap (beta)
      </li>
    </ul>
    <h3>Novedades de la versión v1.3.1 (31/05/22)</h3>
    <ul class="novedades">
      <li>
        Movimientos y profit de la cartera dividendos
      </li>
    </ul>
    <h3>Novedades de la versión v1.3.0 (25/05/22)</h3>
    <ul class="novedades">
      <li>
        Autorización de operaciones por defecto
      </li>
      <li>
        Arreglo de varios errores del juego
      </li>
    </ul>
    <h3>Novedades de la versión v1.2.1 (29/04/22)</h3>
    <ul class="novedades">
      <li>
        MarketPlace de NFTs
      </li>
    </ul>
    <h3>Novedades de la versión v1.2.0 (24/04/22)</h3>
    <ul class="novedades">
      <li>
        Calendario resultados empresas
      </li>
      <li>
        Nuevo apartado Calendario global
      </li>
      <li>
        Info cryptos
      </li>
    </ul>
    <h3>Novedades de la versión v1.0.1 (01/04/22)</h3>
    <ul class="novedades">
      <li>
        Bug casilla 10
      </li>
      <li>
        Nuevo apartado Dashboard
      </li>
      <li>
        Botón para jugar
      </li>
    </ul>
    <h3>Novedades de la versión v1.0.0 (31/03/22)</h3>
    <ul class="novedades">
      <li>
        Game
      </li>
      <li>
        Staking
      </li>
      <li>
        NFT Collections
      </li>
    </ul>
    <h3>Novedades de la versión v0.5.0</h3>
    <ul class="novedades">
      <li>
        MockUp de la landing Page de la web separada de la DAPP
      </li>
      <li>
        Añadido botón superior para conectar cuenta
      </li>
    </ul>
    <h3>Novedades de la versión v0.4.0</h3>
    <ul class="novedades">
      <li *ngFor="let n of novedades">
        <span>{{n}}</span>
      </li>
    </ul>
    
  </mat-card>
  
</div>
