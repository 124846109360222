import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Label } from 'ng2-charts';
import { StoreSummaryService } from 'src/app/services/store-summary.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ContractService } from 'src/app/services/contract/contract.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  contrato;
  balanceOf;

  liquidity = 0;
  rewardpool = 0;
  precioToken = 0;
  stakingDolares = 0;

  //Labels de las minicard
  active_days = 'active_days';
  active_holders = 'active_holders';
  total_supply = 'total_supply';

  // Doughnut Data
  doughnutChartLabels = ['Auto Liquidity', 'Reward Pool', 'Others'];
  doughnutChartData = [
    [350, 450, 100],
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  // public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [0, 37, 86, 112, 151, 162, 173, 0, 0, 0, 0, 0], label: '2022' },
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: '2023' }
  ];

  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 4 },
        };
      }
 
     return {
        columns: 4,
        miniCard: { cols: 1, rows: 1 },
        chart: { cols: 2, rows: 2 },
        table: { cols: 4, rows: 4 },
      };
    })
  );

  miniCardData: any[];

  constructor(
    private contract: ContractService,
    private breakpointObserver: BreakpointObserver,
    private summaryService: StoreSummaryService,
    private translate: TranslateService
    ) {}

  ngOnInit() {

    // Pido al servicio totas las traducciones
    this.translate.get('dashboard')
      .subscribe((value:string)=> {
        this.active_days = value['active_days'];
        this.active_holders = value['active_holders'];
        this.total_supply = value['total_supply'];
        // this.doughnutChartLabels[0] = value['download_sales'];
        // this.doughnutChartLabels[1] = value['in_store_sales'];
        // this.doughnutChartLabels[2] = value['mail_order_sales'];
    });

    this.contract
      .getContract()
      .then((contrato: any) => {
        this.contrato = contrato;
        
        this.contract.balanceContrato(contrato, '0xa967d9e99b94704369e099cab4c2235cd417e6b6').then((liq: any) => {
          this.liquidity = liq/10**18;
          this.contract.balanceContrato(contrato, '0x69225280e6750d178d7b6804f8b12819e72bc22f').then((reward: any) => {
            this.rewardpool = reward/10**18;
            // Doughnut Data
            this.doughnutChartData = [[this.liquidity, this.rewardpool, 100000000 - this.liquidity - this.rewardpool],];
          });
        });

        this.contract.tokenUsd(contrato, 1).then( (value:any) => {
          this.precioToken = 10**18/value;

          // Contrato de Staking
          this.contract
            .getContractStaking()
              .then((contratoStaking: any) => {
                contratoStaking.methods.totalStaked().call().then((valueStaking: any) => {
                  this.stakingDolares = valueStaking/10**18;

                  this.miniCardData = 
                  [
                    {
                      icon: 'calendar_today',
                      title: this.active_days,
                      value: moment().diff("2022-02-02", "days"),
                      color: 'primary',
                      isIncrease: true,
                      isCurrency: false,
                      duration: 'desde el comienzo',
                      percentValue: 0
                    },
                    {
                      icon: 'supervised_user_circle',
                      title: this.active_holders,
                      value: 173,
                      color: 'accent',
                      isIncrease: true,
                      isCurrency: false,
                      duration: 'desde hace 3 días',
                      percentValue: 1.87
                    },
                    {
                      icon: 'shopping_cart',
                      title: 'Tokens Staking',
                      value: (this.stakingDolares/1000).toLocaleString('en-US'),
                      color: 'success',
                      isIncrease: true,
                      isCurrency: false,
                      duration: 'desde hace 23 días',
                      percentValue: .234
                    },{
                      icon: 'attach_money',
                      title: 'MarketCap',
                      value: Math.round(100000000*this.precioToken).toLocaleString('en-US'),
                      color: 'primary',
                      isIncrease: true,
                      isCurrency: true,
                      duration: 'desde hace 3 días',
                      percentValue: ((this.precioToken/0.0027))-1
                    }
                  ];
                });
            });
          });
        
      
      });

    //this.miniCardData = this.summaryService.getStoreSummary()
    // .subscribe({
    //   next: summaryData => {
    //     this.miniCardData = summaryData;
    //   }
    // });
  } 

}
