import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-dialog-bonus',
  templateUrl: './dialog-bonus.component.html',
  styleUrls: ['./dialog-bonus.component.scss']
})
export class DialogBonusComponent implements OnInit {

  contratoNFT;
  direction = "";
  loaded = false;
  texto1 = "";
  texto2 = "";

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public tarjeta,
    public dialogRef: MatDialogRef<DialogBonusComponent>,
    private contract: ContractService,
  ) { }

  ngOnInit(): void {
    console.log('la tarjeta es: ', this.tarjeta);
    this.direction = localStorage.getItem('myAddress');

    if (this.tarjeta.class == 'community-chest') {
      this.texto1 = "Es el momento de recibir un NFT";
      this.texto2 = "Con esta carta tendrás información única del NFT.";
    } else {
      this.texto1 = "Es el momento de recibir un NFT muy especial";
      this.texto2 = "Estos NFTs son muy raros y por tanto tienen mucho valor :)";
    }

    this.contract
      .getContractNFT()
      .then(async (contratoNFT: any) => {
        console.log('contratoNFT: ', contratoNFT);
        this.contratoNFT = contratoNFT;
      });

  }

  mintNFT() {
    console.log('eee', this.direction);
    let ruta = this.router;
    let dia = this.dialogRef;
    this.loaded = true;
    this.contract.costeJugar("1").then(async (coste:any) => {
      return await this.contratoNFT.methods.createRandomNft().send({from: this.direction, value: coste})
        .on('receipt', function(receipt){
          console.log("Transaction receipt", receipt);
          dia.close(this.cartera);
          ruta.navigate(['/pages/carteras']);
        })
        .on('error', function(err) {
          console.error(err);
          dia.close(this.cartera);
        });
      });
  }

  closeDialog() {
    this.dialogRef.close(this.tarjeta);
  }

}
