import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartData, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { DataService } from 'src/app/services/data.service';
import { Accion } from '../empresas/empresas.component';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

  nft: any;
  fundamentales: any;
  historico = [];
  dividendos = [];
  velas = [];
  actualSma = {};
  icono = 'otros';
  year = 2022;
  comentario = {
    strDate: '',
    yearFiscal: '',
    confirmado: false, 
    title: '', 
    eps0: 0, 
    eps1: 0, 
    netIncome: 0,
    totalRevenue: 0,
    estimatedTotalRevenue: 0,
    comentario: ''
  };

  mesesDividendoColors: any[] = [
    {name: 'Ene', color: 'accent'},
    {name: 'Feb', color: 'accent'},
    {name: 'Mar', color: 'accent'},
    {name: 'Abr', color: 'accent'},
    {name: 'May', color: 'accent'},
    {name: 'Jun', color: 'accent'},
    {name: 'Jul', color: 'accent'},
    {name: 'Ago', color: 'accent'},
    {name: 'Sep', color: 'accent'},
    {name: 'Oct', color: 'accent'},
    {name: 'Nov', color: 'accent'},
    {name: 'Dic', color: 'accent'}
  ];

  arraySlider = [0, 10, 3, 5];
  displayedColumns: string[] = ['fiscalDateEnding', 'reportedEPS', 'commonStockSharesOutstanding', 'dpa', 'bpa', 'netIncome', 'dividendPayout', 'fcf'];
  displayedColumnsFundamentales: string[] = ['fiscalDateEnding', 'totalRevenue', 'costOfRevenue', 'grossProfit', 'ebitda', 'dandp', 'ebit', 'interests', 'ebt', 'tax', 'netIncome'];
  displayedColumnsBalance: string[] = ['fiscalDateEnding', 'totalCurrentAssets', 'totalNonCurrentAssets', 'totalBalance', 'totalCurrentLiabilities', 'totalNonCurrentLiabilities', 'shortTermDebt', 'longTermDebt', 'equity'];

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], 
    yAxes: [{
      ticks: {
        beginAtZero:true
      }
    }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartOptionsCalendar: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], 
    yAxes: [{
      id: "y-axis-1",
      position: 'left',
      ticks: {
        beginAtZero:true
      },
      scaleLabel: {
        display: true,
        labelString: 'Ingresos'    
      }
    },
    {
      id: "y-axis-2",
      position: 'right',
      ticks: {
        beginAtZero:true,
      },
      scaleLabel: {
        display: true,
        labelString: 'BPA'    
      }
    }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = [];
  public barChartLabels3: Label[] = [];
  public barChartLabelsCalendar: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartData: ChartDataSets[] = [];
  public barChartData2: ChartDataSets[] = [];
  public barChartData3: ChartDataSets[] = [];
  public barChartDataCalendar: ChartDataSets[] = [];

  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData = [];

  public chartDataFundamentales: ChartDataSets[] = [];
  public charLabelsFundamentales: Label[] = [];
  public chartDataFundamentales2: ChartDataSets[] = [];
  public charLabelsFundamentales2: Label[] = [];
  public chartDataBalance: ChartDataSets[] = [];
  public charLabelsBalance: Label[] = [];

  public chartColors = [
    { backgroundColor:"red" },
    { backgroundColor:"green" },
    { backgroundColor:"blue" },
    { backgroundColor:"orange" }
  ];

  datesToHighlight = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
  ) { }

  order(a, b) {
    return a < b ? -1 : (a > b ? 1 : 0);
  }

  ngOnInit() {
    
    console.log(this.data);
    this.nft = this.data.nft;
    this.data = this.data.accion;

    let yearNow = new Date().getFullYear()
    this.year = yearNow - this.data.dividendo_desde;

    // Get valor del icono segun el sector
    this.icono = this.getIcono(this.data.sector);
    // Barra de meses en los que se cobra el dividedo
    if (this.data.fechas_dividendo == '36912') {
      this.mesesDividendoColors[2].color = 'warn';
      this.mesesDividendoColors[5].color = 'warn';
      this.mesesDividendoColors[8].color = 'warn';
      this.mesesDividendoColors[11].color = 'warn';
    }
    else if(this.data.fechas_dividendo == '14710') {
      this.mesesDividendoColors[0].color = 'warn';
      this.mesesDividendoColors[3].color = 'warn';
      this.mesesDividendoColors[6].color = 'warn';
      this.mesesDividendoColors[9].color = 'warn';
    }
    else if(this.data.fechas_dividendo == '25811') {
      this.mesesDividendoColors[1].color = 'warn';
      this.mesesDividendoColors[4].color = 'warn';
      this.mesesDividendoColors[7].color = 'warn';
      this.mesesDividendoColors[10].color = 'warn';
    } else {
      console.log('otro tipo de dividendo');
    }

    // Get analisis tecnico
    this.dataService.getVelasEmpresa(this.nft.icono)
      .subscribe( (velas: any) => {
        // console.log(velas["Time Series (Daily)"]);
        let datosTecnicos = [];
        this.dataService.getSMADiario(this.nft.icono)
          .subscribe( (sma: any) => {
            console.log(sma);
            for (const [key, value] of Object.entries(velas['Time Series (Daily)'])) {
              this.actualSma = Object.values(sma['Technical Analysis: SMA'])[0];
              for (const [key2, value2] of Object.entries(sma['Technical Analysis: SMA'])) {
                if (key === key2) {
                  datosTecnicos.push(
                    {
                      date: Date.parse(key),
                      open: value['1. open'],
                      high: value['2. high'],
                      low: value['3. low'],
                      close: value['4. close'],
                      volume: value['5. volume'],
                      sma: value2['SMA']
                    });
                }
            }
            }
          });
        this.velas = datosTecnicos;
      });

    // Obtiene los fundamentales e historico de la empresa seleccionada
    this.dataService.getFundamentalesEmpresa(this.nft.icono)
      .subscribe( (accion: Accion) => {
      // console.log(this.nft.icono, accion);
        this.fundamentales = accion['results'][0];
        console.log(this.fundamentales);
        this.arraySlider = [this.fundamentales['WeekLowYear'], this.fundamentales['WeekHighYear'], this.fundamentales['DayMovingAverage200'], this.fundamentales['DayMovingAverage50']]
      });
  this.dataService.gethistoricoEmpresa(this.nft.icono)
    .subscribe( (accion: Accion) => {
      this.historico = accion['results'];
      console.log(this.historico);
      this.historico = this.historico.sort(function(a, b) {
        return a.fiscalDateEnding - b.fiscalDateEnding;
      });
      // Tabla ingresos
      this.charLabelsFundamentales = [ 'Ingresos', 'Benef. Bruto', 'EBITDA'];
      this.chartDataFundamentales = this.historico.map(function(a) {
        return {data: [ a.totalRevenue/1000000, (a.totalRevenue - a.costOfRevenue)/1000000, a.ebitda/1000000 ], label: a.fiscalDateEnding};
      });
      this.charLabelsFundamentales2 = [ 'EBIT', 'EBT', 'Benef. Neto'];
      this.chartDataFundamentales2 = this.historico.map(function(a) {
        return {data: [ (a.ebitda - a.dandp)/1000000, (a.netIncome + a.tax)/1000000, a.netIncome/1000000 ], label: a.fiscalDateEnding};
      });
      // Doughnut Balance
      this.doughnutChartLabels = ['Corto plazo', 'Largo plazo', 'Patrimonio'];
      let totalCurrentAssets = this.historico.map(function(a) {return a.totalCurrentAssets;});
      let totalNonCurrentAssets = this.historico.map(function(a) {return a.totalNonCurrentAssets;});
      let totalCurrentLiabilities = this.historico.map(function(a) {return a.totalCurrentLiabilities;});
      let totalNonCurrentLiabilities = this.historico.map(function(a) {return a.totalNonCurrentLiabilities;});
      let patrimonio = totalCurrentAssets.pop() + totalNonCurrentAssets.pop() - totalCurrentLiabilities.pop() - totalNonCurrentLiabilities.pop();
      this.doughnutChartData = [
        [totalCurrentAssets.pop()/1000000, totalNonCurrentAssets.pop()/1000000, 0], 
        [ totalCurrentLiabilities.pop()/1000000, totalNonCurrentLiabilities.pop()/1000000, patrimonio/1000000 ]
      ];
      // Tabla Balance
      this.charLabelsBalance = [ 'Activos', 'Patrimonio', 'Deuda Corto', 'Deuda Largo'];
      this.chartDataBalance = this.historico.map(function(a) {
        return {
          data: [ 
            (a.totalCurrentAssets + a.totalNonCurrentAssets)/1000000, 
            (a.totalCurrentAssets + a.totalNonCurrentAssets - a.totalCurrentLiabilities - a.totalNonCurrentLiabilities)/1000000, 
            a.shortTermDebt/1000000, 
            a.longTermDebt/1000000, 
          ], 
          label: a.fiscalDateEnding};
      });
      // Dividendo
      this.barChartLabels = this.historico.map(function(a) {return a.fiscalDateEnding;});
      let dpa = this.historico.map(function(a) {return a.dividendPayout/a.commonStockSharesOutstanding;});
      let bpa = this.historico.map(function(a) {return a.netIncome/a.commonStockSharesOutstanding;});
      this.barChartData = [
        { data: dpa, label: 'DPA' },
        { data: bpa, label: 'BPA' }
      ];
      let fcf = this.historico.map(function(a) {return (a.operatingCashflow - a.capitalExpenditures)/1000000;});
      let div = this.historico.map(function(a) {return a.dividendPayout/1000000;});
      this.barChartData2 = [
        { data: fcf, label: 'FCF' },
        { data: div, label: 'Div' }
      ];
      // Historico Dividendos
      // Historico Dividendos
      this.dataService.getDividendosEmpresa()
        .subscribe( (divs: any) => {
          this.dividendos = divs.filter( d => d.empresa.symbol == this.nft.icono);
          //let datos = this.dividendos.map(function(a) {return Number(a.dividendo);});
          let datos = this.dividendos.sort(function(a, b){return a.date.substring(0,4)-b.date.substring(0,4)});
          console.log(divs);
          this.barChartLabels3 = datos.map(function(a) {return a.date});
          this.barChartData3 = [ { data: datos.map(function(a) {return Number(a.dividendo);}), label: 'Dividendo'}];
        });
      // Calendar
      let lastYearHistorico = accion['results'][accion['results'].length - 1];
      this.dataService.getQhistoricoEmpresas()
        .subscribe( (trim: any) => {
          console.log(trim);
          trim
            .filter(i => i.historico.empresa.id == this.fundamentales.empresa)
            .forEach(t => {
              console.log(t);
              this.datesToHighlight.push({
                  strDate: t.reportedDate,
                  yearFiscal: t.historico.fiscalDateEnding,
                  confirmado: t.confirmado, 
                  title: this.data.nombre, 
                  eps0: t.estimatedEPS, 
                  eps1: t.reportedEPS, 
                  netIncome: t.netIncome,
                  totalRevenue: t.totalRevenue,
                  estimatedTotalRevenue : t.estimatedTotalRevenue,
                  comentario: t.TextField
                })
            });
          // Tabla de Q
          this.barChartLabelsCalendar = ['Q1', 'Q2', 'Q3', 'Q4'];
          let ing = trim
            .filter(i => i.historico.empresa.id == this.fundamentales.empresa && i.confirmado == true)
            .map(function(a) {
              return a.totalRevenue/1000000;
            });
          let netIncome = trim
            .filter(i => i.historico.empresa.id == this.fundamentales.empresa && i.confirmado == true)
            .map(function(a) {
                return a.netIncome/1000000;
            });
          let eps = trim
            .filter(i => i.historico.empresa.id == this.fundamentales.empresa && i.confirmado == true)
            .map(function(a) {
                return a.reportedEPS;
            });
          this.barChartDataCalendar = [
            { data: ing, yAxisID: "y-axis-1", label: 'Ingresos' },
            { data: netIncome, yAxisID: "y-axis-1", label: 'Beneficios' },
            { data: eps, yAxisID: "y-axis-2", label: 'BPA' }
          ];
        });
    });

  }


  objetoCalendario( evento: any ){
    if (evento) {
      this.comentario = evento;
    }
    
  }

  getIcono(sector: string) {
    switch(this.data.sector) { 
      case 'ConsumoDef': { 
        return 'shopping_basket';
      } 
      case 'salud': { 
         return 'health_and_safety';
      } 
      case 'utilities': { 
        return 'water_drop';
      } 
      case 'comunicaciones': { 
        return 'phone_in_talk';
      } 
      case 'elect': { 
        return 'electric_bolt';
      } 
      case 'industrial': { 
        return 'factory';
      } 
      case 'tecno': { 
        return 'tablet_mac';
      } 
      case 'materiales': { 
        return 'air';
      } 
      case 'consumoCic': { 
        return 'lunch_dining';
      } 
      case 'finanzas': { 
        return 'wallet';
      } 
      case 'inmo': { 
        return 'holiday_village';
      } 
      default: {
        return 'otros';
      } 
   } 
  }


  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + '$';
    }
    return value;
  }

}
