import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})

export class EmpresasComponent implements OnInit {

  data: any[];
  textoBuscar = '';
  estrategia = 'estrategia';
  toppings = new FormControl();
  direction = "";
  nfts = [];
  precio = 0;
  @ViewChild('tooltip') tooltip;

  estrategias = [
    {value: '', viewValue: 'Todas'}, 
    {value: 'Dividendos', viewValue: 'Dividendos'},
    {value: 'growth', viewValue: 'Growth'},
    {value: 'trading', viewValue: 'Trading'}
  ];

  constructor(
    private dataService: DataService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.direction = localStorage.getItem('myAddress');
    this.dataService.getEmpresasRadar()
      .subscribe( (empresas: any[]) => {
        empresas.forEach( empresa => {empresa['precio']= 0});
        this.data = empresas;
        this.getPreciosActualesEmpresas();
    });
  }

  onFilterChange(event) {
    this.textoBuscar = event.value;
  }

  getPreciosActualesEmpresas(){
    // this.tooltip.toggle();
    let symbols = this.data.map( a => a.empresa.symbol);
    console.log(symbols);
    this.dataService.getPrecioEmpresa(symbols)
      .subscribe( (precios) => {
        console.log(precios['quoteResponse']['result']);
        this.data.forEach((obj) => {
          precios['quoteResponse']['result'].forEach((precio) => {
            if(obj.empresa.symbol === precio.symbol){
              obj.precio = precio.regularMarketPrice;
            }
          });
        });
      });
  }

  async getMisNtfs(contrato) {
    return await contrato.methods.getOwnerNfts(this.direction).call();
  } 

}

export interface Accion {
  id: string;
  nombre: string;
  symbol: string;
  favorito: boolean;
  recomendado: boolean;
  pais: string;
  logo?: string;
  precio?: number;
}
