<mat-card>

    <mat-card-header>
      <mat-card-subtitle>Mi histórico de casillas</mat-card-subtitle>
    </mat-card-header>
    
    <mat-card-content>
      <div style="text-align:center">


          <table mat-table [dataSource]="arrayHistorico" class="mat-elevation-z8">

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd/MM/yyyy HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="casilla">
              <th mat-header-cell *matHeaderCellDef>Casilla </th>
              <td mat-cell *matCellDef="let element"> {{element.casilla}} </td>
            </ng-container>

            <ng-container matColumnDef="hblocks">
                <th mat-header-cell *matHeaderCellDef> Tokens </th>
                <td mat-cell *matCellDef="let element"> {{element.hblocks | number : '1.0-0' }}  </td>
              </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumnsHistorico"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsHistorico;"></tr>

          </table>

          <br>
          
          <h3>TOTAL: {{ totalHblocks | number: '1.0-0' }} HBLOCK</h3>

      </div>
    </mat-card-content>
  </mat-card>