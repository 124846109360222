import { Component, OnInit } from '@angular/core';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  today: number = Date.now();

  teasers = [
    {
      title: "1. JUEGA",
      image: "tablero",
      alt: "Gamificación de HamperBlock -  diferentes carteras de inversión y sorpresas en nuestro tablero de juego",
      desc: "Ve descubriendo las diferentes carteras y sorpresas en nuestro tablero de juego. Cada color es un tipo de cartera. Dasafía a otros amigos con nuestro modo Batalla"
    },
    {
      title: "2. APRENDE",
      image: "aprende",
      alt: "Desbloquea carteras para estudiar cada tipología de inversión - cryptos, criptomonedas, acciones, fondos de inversión y más",
      desc: "Desbloquea carteras para estudiar cada tipología de inversión. Si ya las conoces sáltate este paso mediante un holdeo de HBLOCK"
    },
    {
      title: "3. INVIERTE",
      image: "carteras",
      alt: "Podrás invertir con diferentes tokens, como hblock o USDC",
      desc: "Una vez pases el test de cada cartera puedes invertir en ella mediante USDT, pero no te preocupes, el nivel de riesgo estará claro"
    }
  ];

  tokenomics1 = [
    {
      title: "BLOCKCHAIN DE POLYGON",
      image: "flaticon-signal",
      desc: "Es como la red de Ethereum, pero con muchas menos comisiones. La blockchain del momento para un juego de aprendizaje único"
    },
    {
      title: "CARTERAS SENCILLAS Y COMPLEJAS",
      image: "flaticon-global",
      desc: "Comienza a invertir en carteras de fondos indexados, dividendos crecientes, criptomonedas y hasta en activos inmobiliarios"
    },
    {
      title: "GRAN COMUNIDAD",
      image: "flaticon-communication",
      desc: "Nuestro objetivo es formar una gran comunidad que apoye nuestro proyecto a largo plazo con lo que te recompensaremos si lo haces"
    }
  ];

  tokenomics2 = [
    {
      title: "HBLOCK NUESTRO TOKEN",
      image: "flaticon-paper-plane",
      desc: "Es la criptomoneda con la que podrás jugar y aprender a invertir. Además, recibirás más HBLOCK en forma de recompensas"
    },
    {
      title: "TAMBIÉN CON NFTs",
      image: "flaticon-picture",
      desc: "Colecciona las mejores empresas del mundo a través de nuestros tokens no fungibles. Con estas tarjetas accederás a info privilegiada"
    },
    {
      title: "¡ENTRA YA!",
      image: "flaticon-plug",
      desc: "Si quieres estar con nosotros ahora es el momento. Compra HBLOCK con tu MetaMask. Tienes videos de ayuda para dar tus primeros pasos"
    }
  ];

  fases = [
    {
      title: "IDO: 24 Enero 15:00h CGT+1  Lookup: 1 semana",
      image: "flaticon-clock-1",
      link: "https://dx.app/app/v3/defipresale?saleID=104&chain=Matic"
    },
    {
      title: "Precio: 0.004€/Token  35.000.000 Tokens",
      image: "flaticon-light-bulb",
      link: "https://dx.app/app/v3/defipresale?saleID=104&chain=Matic"
    },
    {
      title: "Compra Mín: 4 Matic  Compra Máx: 5000 Matic",
      image: "flaticon-light-bulb",
      link: "https://dx.app/app/v3/defipresale?saleID=104&chain=Matic"
    }
  ];

  datos = [
    {
      title: "TARJETAS",
      numero: 3900
    },
    {
      title: "TARJETAS",
      numero: "3974"
    },
    {
      title: "TARJETAS",
      numero: "398"
    },
  ];
  
  constructor(
    private contract: ContractService,
  ) { }

  ngOnInit() {
  }

  connectAccount() {
    this.contract
    .connectAccount()
    .then((value: any) => {
      console.log(value);
      
    })
    .catch((error: any) => {
      this.contract.failure(
        "Could't get the account data, please check if metamask is running correctly and refresh the page"
      );
    });
  }

}
