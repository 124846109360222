import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-dialog-blockchain',
  templateUrl: './dialog-blockchain.component.html',
  styleUrls: ['./dialog-blockchain.component.scss']
})
export class DialogBlockchainComponent implements OnInit {

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public tarjeta,
    public dialogRef: MatDialogRef<DialogBlockchainComponent>
  ) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(this.tarjeta);
  }

}
