import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.scss']
})
export class ProfitComponent implements OnInit {

  data : MatTableDataSource < any[] > = new MatTableDataSource<any[]>([]);
  displayedColumns: string[] = ['fecha', 'valor', 'profit', 'balance',];
  movimientos = [];
  empresas = [];
  total_invertido = 0;

  public charLabels: Label[] = [];
  public chartData: ChartDataSets[] = [];
  public chartDataBalance: ChartDataSets[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], 
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: true
      }
    }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData = [];
  public doughnutChartLabels2: Label[] = [];
  public doughnutChartData2 = [];
  public doughnutChartLabels3: Label[] = [];
  public doughnutChartData3 = [];

  chartColors = [
    { 
      backgroundColor:["#B9E8E0", "#FFFCC4", "#FF7360"] 
    }];
    
    public chartColorsCash: Array<any> = [
      {  
  
        backgroundColor: 'rgba(255, 0, 0, 0.2)',  
        borderColor: 'rgba(255,153,51,1)',  
        pointBackgroundColor: 'rgba(0,128,0,1)',  
        pointBorderColor: '#fff',  
        pointHoverBackgroundColor: '#fff',  
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',  
        pointRadius: 8,  
    },  
    {  

        backgroundColor: 'rgba(0, 0, 255, 0.4)',  
        borderColor: 'rgba(77,83,96,1)',  
        pointBackgroundColor: 'rgba(77,83,96,1)',  
        pointBorderColor: '#fff',  
        pointHoverBackgroundColor: '#fff',  
        pointHoverBorderColor: 'rgba(77,83,96,1)',  
        lineTension: 0,  
        pointRadius: 10,  
        pointStyle: 'rectRounded'  
    },];

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit(): void {
    this.dataService.getProfitCartera()
      .subscribe( (profits: any) => {
        console.log(profits.filter(car =>{return car.cartera.id == 1}));
        this.data = profits.filter(car =>{return car.cartera.id == 1});
        let values = profits.filter(car =>{return car.cartera.id == 1})
        // Char
        this.charLabels = profits.filter(car =>{return car.cartera.id == 1}).map(function(a) {return a.fecha;}).reverse();
        let valor = values.map(function(a) {return a.valor;});
        let profit = values.map(function(a) {return a.profit;});
        let balance = values.map(function(a) {return a.balance;});
        let dividendos = values.map(function(a) {return a.dividendos;});
        // console.log(values);
        this.chartData = [
          { data: valor.reverse(), label: 'Valor' },
          { data: balance.reverse(), label: 'Cash disponible' },
          { data: dividendos.reverse(), label: 'Dividendos'}
        ];
        this.chartDataBalance = [{ data: profit.reverse(), label: 'Profit' }];
        // Doughnut Balance
        // console.log('eeee', [balance.length -1]);
        this.doughnutChartLabels = ['Cash', 'Inversión'];
        this.doughnutChartData = [
          [balance[balance.length -1], valor[balance.length -1] - balance[balance.length -1]]
        ];
      });

      this.dataService.getMovimientosCartera()
      .subscribe( (movimientos: any[]) => {
        this.movimientos = movimientos.filter(item => item.cartera.id == 1);
        // console.log(this.movimientos.filter(item => item.cartera.id == 1));
        let orders = [];
        this.movimientos.forEach( mov => {
          if( mov.tipo == 'BUY' || mov.tipo == 'SELL'){
            orders.push([mov.empresa.symbol, mov.tipo, mov.acciones, mov.empresa.pais, mov.empresa.sector, mov.precio, mov.empresa.logo, mov.empresa.nombre]);
          }
        });
        
        const result = orders.reduce((acc, [key, action, amount, country, sector, precio, logo, nombre]) => { //todo precio medio compra
          acc[key] = acc[key] || [0, 0, '', '', 0, '', '', 0];
          if (action === "BUY") {
            acc[key][0] += amount;
          }
          if (action === "SELL") acc[key][1] += amount;
          acc[key][2] = country;
          acc[key][3] = sector;
          acc[key][4] = precio;
          acc[key][5] = logo;
          acc[key][6] = nombre;
          acc[key][7] += amount * precio;
          return acc;
        }, {});

        // console.log(result);

        // let consumoDef = this.movimientos.filter(function(mov) {return mov.empresa.sector == 'ConsumoDef';});
        // Doughnut Type Stocks
        for (const [key, value] of Object.entries(result)) {
          console.log(key, value[0] - value[1], 'acciones');
          this.dataService.getDividendosEmpresa()
            .subscribe( (divs: any) => {
              let datos = divs
                .filter( d => d.empresa.symbol == key)
                .sort(function(a, b){return a.date.substring(0,4)-b.date.substring(0,4)});
              // console.log(key, datos)
              let lastDividend = datos.filter( a => a.date == "2022");
              let dividend = 0;
              if (lastDividend.length > 0) {
                console.log(lastDividend);
                dividend = lastDividend.map( d => d.dividendo ).reduce( (a, b) => a + b, 0 );
                // console.log(dividend);
              }
              // moneda
              let moneda = '€';
              if (value[2] == 'uk') {
                moneda = '£'
              } else if (value[2] == 'eeuu'){
                moneda = '$'
              }
              this.empresas.push({symbol: key, nombre: value[6], logo: value[5], amount: value[0] - value[1], 
                country: moneda, sector: value[3], coste: value[7], pmc: value[7]/(value[0] - value[1]),
                dividendo: dividend
              });

              // console.log(this.empresas);
              let eeuu = this.empresas.filter(function(e) {return e.country == 'eeuu'}).map(function(a) {return a.coste;});
              let uk = this.empresas.filter(function(e) {return e.country == 'uk'}).map(function(a) {return a.coste;});
              let resto = this.empresas.filter(function(e) {return (e.country != 'eeuu') && e.country != 'uk'}).map(function(a) {return a.coste;});
              // Sectores
              console.log(eeuu);
              let defensivo = this.empresas
                .filter(function(e) {return (e.sector == 'ConsumoDef') || (e.sector == 'salud') || (e.sector == 'utilities') })
                .map(function(a) {return a.coste;});
              let sensitivo = this.empresas
                .filter(function(e) {return (e.sector == 'comunicaciones') || (e.sector == 'elect') || (e.sector == 'industrial') || (e.sector == 'tecno') })
                .map(function(a) {return a.coste;});
              let ciclico = this.empresas
                .filter(function(e) {return (e.sector == 'materiales') || (e.sector == 'consumoCic') || (e.sector == 'finanzas') || (e.sector == 'inmo') })
                .map(function(a) {return a.coste;});
              console.log(defensivo);
              
              this.total_invertido = defensivo.reduce((a, b) => a + b, 0) + sensitivo.reduce((a, b) => a + b, 0) + ciclico.reduce((a, b) => a + b, 0)
              this.doughnutChartLabels3 = ['EEUU', 'UK', 'UE'];
              this.doughnutChartData3 = [[eeuu.reduce((a, b) => a + b, 0),uk.reduce((a, b) => a + b, 0),resto.reduce((a, b) => a + b, 0)]];
      
              this.doughnutChartLabels2 = ['Defensivo', 'Sensible', 'Cíclico'];
              this.doughnutChartData2 = [
                [defensivo.reduce((a, b) => a + b, 0), sensitivo.reduce((a, b) => a + b, 0), ciclico.reduce((a, b) => a + b, 0)]
              ];

            });
        }
        
      });
  }
}
