import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabla-dividendos',
  templateUrl: './tabla-dividendos.component.html',
  styleUrls: ['./tabla-dividendos.component.scss']
})
export class TablaDividendosComponent implements OnInit {

  @Input() dividendos;
  
  constructor() { }

  ngOnInit(): void {
  }

  getClassFromDate(date: any) {
    // let match = date.getTime(); // convert date to number
    let match = new Date(date).getTime()
    let today = new Date().setHours(0,0,0,0); // get present day as number
    let day = (n) => today + (86400000 * n); // assuming all days are 86400000 milliseconds, then add or remove days from today
 
    // console.log(match, day(60));

    if (match >= day(1) && match < day(2))
       return 'tomorrow';
    else if (match <= today)// && match < day(60))
       return 'today';
    // else if (match < today && match > day(-2))
    //    return 'yesterday';
    else
       return 'other';
  }

  // getMoneda(country: string) {
  //   let moneda = '€';
  //   if (country == 'uk') {
  //     moneda = '£'
  //   } else if (country == 'eeuu'){
  //     moneda = '$'
  //   }
  //   return moneda;
  // }

}
