<div class="body">
  <mat-card *ngIf="leccion">
    <mat-card-header>
      <mat-card-title>
          <h2>{{ leccion.nombre }} - Casilla {{ leccion.cartera }}</h2>
      </mat-card-title>
      <mat-card-subtitle>
          <h3>Cartera de {{ leccion.tipo }}</h3>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p innerHTML={{leccion.intro}}>{{ leccion.intro }}</p>
    </mat-card-content>
  </mat-card>

  <div *ngIf="temario.length > 0">
    <mat-accordion *ngFor="let i of temario">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ i.titulo }}
          </mat-panel-title>
          <!-- <mat-panel-description>
            {{panelOpenState ? 'open' : 'closed'}} Clic para desplegar...
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        <p innerHTML={{i.texto}}>{{ i.texto }}</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</div>




