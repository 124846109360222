
<div class="body">
    <h2>Calendario de Empresas NFT HamperBlock</h2>

    <app-calendar *ngIf="datesToHighlight.length > 0" 
      [datesToHighlight]="datesToHighlight"
      (onResultadoCalendario)="objetoCalendario($event)">
    </app-calendar>

    <app-tabla-dividendos [dividendos]="dividendos"></app-tabla-dividendos>

    <h2>Detalle del dividendo seleccionado</h2>
    <div fxLayout="row wrap" fxLayoutGap="10px grid">
      <div *ngFor="let i of items; let idx = index" fxFlex="22%" fxFlex.xs="100%" fxFlex.sm="45%">

        <mat-card>

          <mat-card-header *ngIf="i.esDividendo">
            <mat-card-title>
              {{ i.title }} {{ i.strDate }}
            </mat-card-title>
            <mat-card-subtitle>
              Año fiscal: {{ i.yearFiscal }}
            </mat-card-subtitle>
          </mat-card-header>

          <mat-card-content *ngIf="i.esDividendo">
            Se reciben: <h3>{{ i.dividendo }} $/acción.</h3>
          <h4>{{ i.comentario }}</h4>
          </mat-card-content>

          <mat-card-actions>
            <button mat-button (click)="delete(idx)">Borrar</button>
          </mat-card-actions>

        </mat-card>
    </div>
  </div>
  </div>