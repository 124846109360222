<div class="grid-container">
  <h1 class="mat-h1">Dashboard</h1>
  <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="250px">
    <!--Mini Cards-->
    <mat-grid-tile *ngFor="let mc of miniCardData" [colspan]="( cardLayout | async )?.miniCard.cols"
    [rowspan]="( cardLayout | async )?.miniCard.rows">
    <app-mini-card 
      [title]="mc.title" [textValue]="mc.textValue" [value]="mc.value" [color]="mc.color" [icon]="mc.icon" 
      [percentValue]="mc.percentValue" [duration]="mc.duration" [isIncrease]="mc.isIncrease"
      >
    </app-mini-card>
  </mat-grid-tile>
    <!--Charts-->
    <mat-grid-tile [colspan]="( cardLayout | async )?.chart.cols" [rowspan]="( cardLayout | async )?.chart.rows">
      <!-- <app-card title="{{ 'dashboard.monthly_revenue' | translate }}"> -->
        <app-card title="Tokenomics Real Time">
        <app-donut [doughnutChartLabels]="doughnutChartLabels" [doughnutChartData]="doughnutChartData" ></app-donut>
      </app-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="( cardLayout | async )?.chart.cols" [rowspan]="( cardLayout | async )?.chart.rows">
      <app-card title="Evolution Holders">
        <div>
          <div>
            <div style="display: block">
              <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>
      </app-card>
    </mat-grid-tile>


    <!-- <mat-grid-tile [colspan]="( cardLayout | async )?.chart.cols" [rowspan]="( cardLayout | async )?.chart.rows">
      <app-card title="Sales by Traffic Source">
        <app-line-chart></app-line-chart>
      </app-card>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="( cardLayout | async )?.chart.cols" [rowspan]="( cardLayout | async )?.chart.rows">
      <app-card title="Online Store Sessions by Traffic Source">
        <app-line-chart></app-line-chart>
      </app-card>
    </mat-grid-tile> -->

    <!--Evolution-->
    <!-- <mat-grid-tile [colspan]="( cardLayout | async )?.table.cols" [rowspan]="( cardLayout | async )?.table.rows">
      <app-card title="{{ 'dashboard.market_cap' | translate }}"><div>
        <app-line-chart></app-line-chart>
      </div></app-card>
    </mat-grid-tile> -->

  </mat-grid-list>
 </div>
