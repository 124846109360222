
<mat-dialog-content class="mat-typography">
    <mat-icon aria-hidden="false" aria-label="Example home icon">crop_rotate</mat-icon>
    <h1 mat-dialog-title>{{ tarjeta.nombre }}</h1>

    <h2>{{ texto1 }}</h2>
    <img class="img" width="300" [src]="tarjeta.class == 'community-chest' ? 'assets/icons/bonus.png' : 'assets/icons/special.png'">
    <h2>{{ texto2 }}</h2>

      <button mat-raised-button color="primary" [routerLink]="['/pages/learning', tarjeta.id]">Aprende sobre NFTs</button>
      <p></p>

      <mat-spinner *ngIf="loaded"></mat-spinner>
      <h3 *ngIf="loaded">Estamos generando tu NFT. ¡Suerte!</h3>

      <!-- <img *ngIf="tarjeta.class == 'community-chest'" width="300" src="../../../assets/monopoly_tarjeta.jpg">
      <img *ngIf="tarjeta.class != 'community-chest'" width="300" src="../../../assets/monopoly-jail.gif"> -->

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Salir</button>
  </mat-dialog-actions>