
<mat-dialog-content class="mat-typography">
    <mat-icon aria-hidden="false" aria-label="Example home icon">card_travel</mat-icon>
    <h1 mat-dialog-title>Selecciona el NFT a vender</h1>

    <mat-spinner *ngIf="loaded"></mat-spinner>

    <label id="example-radio-group-label">Elige un NFT de tu colección y pon un precio</label>

    <br>

    <div *ngIf="nftChoose">NFT seleccionado: {{ nftChoose.nombre }}</div>

    <mat-form-field appearance="fill">
        <input placeholder="Indica Cantidad" matInput type="number" [(ngModel)]="num_tokens">
    </mat-form-field>

    <mat-form-field appearance="fill" class="example-full-width">
        <app-select-tokens class="btn btn-secudnary" (onNftSelected)="nftSelected($event)" (onClean)="cleanNft($event)"></app-select-tokens>
        <input placeholder="Indica Dirección Token" matInput type="text" [(ngModel)]="token.token">
        <mat-hint>Por defecto HBLOCK</mat-hint>
    </mat-form-field>

    <br>

    <br>

    <button *ngIf="nftChoose && num_tokens>0" mat-raised-button color="primary" (click)="venderNft(nftChoose.idMint, num_tokens)"><mat-icon>input</mat-icon>&nbsp;Vender</button>

    <mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="nftChoose">
    <mat-radio-button class="example-radio-button" *ngFor="let nft of misNfts" [value]="nft">
        {{ nft.nombre }} (nivel: {{ nft.level }}) #{{ nft.idMint }}
    </mat-radio-button>
    </mat-radio-group>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>