import { Component, Input, OnInit, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit  {

  @Input() datesToHighlight: any[] = [];
  @Output() onResultadoCalendario: EventEmitter<any> = new EventEmitter(); 
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;
  selectedDate: Date | null;
  selectedObjeto; 

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {

  }


  prueba(){
    this.calendar.updateTodaysDate();
  }

  onSelect(event){
    this.displayMonth();
    // console.log(event.getFullYear() + '-' + ("0" + (event.getMonth() + 1)).slice(-2) + '-' + event.getDate());
    this.selectedObjeto = this.getItem(event.getFullYear() + '-' + ("0" + (event.getMonth() + 1)).slice(-2) + '-' + ("0" + event.getDate()).slice(-2) )[0];
    this.selectedDate = event;
    this.onResultadoCalendario.emit(this.selectedObjeto);
  }

  dateClass() {
    // this.displayMonth();
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.datesToHighlight
        .filter( d => d.esDividendo)
        .map(item => new Date(item.strDate))
        .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());

        const highlightDate2 = this.datesToHighlight
        .filter( d => d.esDividendo == false)
        .map(item => new Date(item.strDate))
        .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());

      if(highlightDate){
        return highlightDate ? 'example-custom-date-class' : undefined;
      }
      else if(highlightDate2){
        return highlightDate2 ? 'example-custom-date-class2' : undefined;
      }
      else {
        return undefined;
      }
    };
  }

  getItem(fecha: string) {
    return this.datesToHighlight.filter(item => item.strDate == fecha)
  }

  dateToString(date: any) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }

  displayMonth() {
    setTimeout(()=>{
      const cells = Array.from(document.querySelectorAll<HTMLDivElement>('.mat-calendar .mat-calendar-body-cell'));
      cells.forEach(y => {
        let dateSearch = this.dateToString(
          new Date(y.getAttribute("aria-label"))
        );
        let data = this.datesToHighlight.find(f => f.strDate == dateSearch);
        if(data) {
          y.setAttribute("aria-label", data.title + ' (' + data.yearFiscal + ')');
        }
      });
    })
  }

}




import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from '@angular/core';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

}
