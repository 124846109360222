<div style="display: block;">
    <mat-card>
      <h3>Acciones en cartera</h3>
      <br>
      <mat-list>
        <mat-list-item *ngFor="let empresa of acciones">
            <img src="https://logo.clearbit.com/{{ empresa.logo }}.com" matListAvatar alt="">
            <mat-divider></mat-divider>
            <div matLine>{{ empresa.nombre }} ({{ empresa.symbol }})</div>
            <div matLine>{{ (empresa.coste / total_invertido)*100 | number : '1.1-1' }} %</div>
            <div matLine *ngIf="empresa.dividendo" class="green">YOC: {{ (empresa.dividendo / empresa.pmc)*100  | number : '1.1-1' }} %</div>
            <p mat-line-end class="abu">{{ empresa.amount }} acc </p>
            <p mat-line-end class="abu">{{ empresa.pmc | number : '1.2-2' }} {{ empresa.country }} </p>
        </mat-list-item>
      </mat-list>
    </mat-card>
  </div>
