
 <h2>Please complete the test.</h2>

 <form #quizForm="ngForm" (ngSubmit)="onSubmit(quizForm)">

    <div *ngFor="let quiz of questionList"> 
        <mat-card>
            <mat-card-subtitle> {{ getQuestion(quiz) }}</mat-card-subtitle> 

            <div *ngFor="let option of quiz.options; let i=index">
                <div class="custom-control custom-radio">
                    <input 
                    type="radio" 
                    class="custom-control-input" 
                    id="{{quiz.questionKey+''+i}}" 
                    name={{quiz.questionKey}}
                    ngModel
                    [value]="option.value"
                    (change)="onSelectionAnswer(quiz.questionKey, option.value, option)">
                    <label class="custom-control-label" for="{{quiz.questionKey+''+i}}">{{option.key}}</label>
                  </div>
            </div>

            <!-- <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                name="favoriteSeason"
                formControlName="quiz.questionKey"
                >
                <mat-radio-button class="example-radio-button" *ngFor="let option of quiz.options; let i=index" 
                    [value]="option"
                    >
                    {{option.key}}
                </mat-radio-button>
            </mat-radio-group> -->
            
        </mat-card>
</div>
 <button mat-raised-button color="primary" class="big-round-shape-button" type="submit">Submit Test</button>
</form>