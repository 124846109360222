import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { ContractService } from 'src/app/services/contract/contract.service';
import { DataService } from 'src/app/services/data.service';
import { carteras } from '../../interfaces/cartera';
import { DialogComponent } from '../dialog/dialog.component';


interface NFT {
  nombre: string, 
  pais: string, 
  mercado: string, 
  color: string, 
  tipo: string, 
  sector_icon: string, 
  icono: string
}

@Component({
  selector: 'app-carteras',
  templateUrl: './carteras.component.html',
  styleUrls: ['./carteras.component.scss']
})

export class CarterasComponent implements OnInit {

  carteras = [];
  direction = "";
  contrato;
  contratoNFT;
  owner: string = "";
  datosCasilla;
  hidden = false;
  tipo = '';
  toppings = new FormControl();
  
  tipos = [
    {value: '', viewValue: 'Todas'}, 
    {value: 'estrategia', viewValue: 'Estrategia'},
    {value: 'indexados', viewValue: 'Indexados'},
    {value: 'staking', viewValue: 'Staking'},
    {value: 'dividendos', viewValue: 'Dividendos'},
    {value: 'criptomonedas', viewValue: 'Criptomonedas'},
    {value: 'inmobiliario', viewValue: 'Inmobiliario'},
    {value: 'growth', viewValue: 'Growth'},
    {value: 'trading', viewValue: 'Trading'}
  ];

  nfts = [];
  nftsMinteados = 0;

  constructor(
    public dialog: MatDialog,
    private contract: ContractService,
    private translate: TranslateService
    ) {

      this.contract
      .getContract()
      .then((contrato: any) => {
        this.contrato = contrato;
        this.contract.getCasillas(contrato).then((datosCasillas: any) => {
          let car = datosCasillas.filter(e => e.class == "property");
          this.carteras = car.sort((a, b) => a.id - b.id);
          // Ahora tengo que desbloquear las del usuario que haya entrado
          this.contract.miUltimaCartera(contrato, this.direction)
          .then((ultimaCartera: any) => {
            this.setCasillasUnlocked(ultimaCartera, this.carteras);
        });

        });

        this.contract
          .getContractNFT()
          .then(async (contratoNFT: any) => {
            this.getURINtf(contratoNFT).then( (uri) => {
              // console.log("La UIR es: ", uri);
            });
            this.getNtfsMinted(contratoNFT).then( (ntfs) => {
              this.nftsMinteados = ntfs;
            });

            this.getMisNtfs(contratoNFT).then( (nfts: any) => {
              this.nfts = nfts;
            });
 

            // Cambiar la url baseURI para los json de las empresas
            // this.changeBaseUri(contratoNFT).then( (value: any) => {
            //   console.log('Cambiada la baseURI', value);
            // });


            this.contratoNFT = contratoNFT;

        });

        
    });

    }

  ngOnInit() {

    this.direction = localStorage.getItem('myAddress');

    // Pido al servicio totas las traducciones
    this.translate.get('tiposCarteras')
      .subscribe((texto:string)=> {
        this.tipos[0].viewValue = texto['all'];
        this.tipos[1].viewValue = texto['strategy'];
        this.tipos[2].viewValue = texto['index'];
        this.tipos[3].viewValue = texto['staking'];
        this.tipos[4].viewValue = texto['dividend'];
        this.tipos[5].viewValue = texto['cryptocurrency'];
        this.tipos[6].viewValue = texto['real_estate'];
        this.tipos[7].viewValue = texto['growth'];
        this.tipos[8].viewValue = texto['trading'];
    });

  }

  setCasillasUnlocked(id: number, carteras: any[]) {
    let i = 0;
    while(i < id){
      carteras[i].bloqueada = true;
      i++;
    }
  }

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  async getNtfsMinted(contrato) {
    return await contrato.methods.getNftsMinted().call();
  } 

  async getMisNtfs(contrato) {
    return await contrato.methods.getOwnerNfts(this.direction).call();
  } 

  async getURINtf(contrato) {
    return await contrato.methods.tokenURI(1).call();
  } 

  async changeBaseUri(contrato) {
    return await contrato.methods.setBaseURI('https://gateway.pinata.cloud/ipfs/QmQfXU9L9DkHcm9M29US7XMtAntK9EYUQzxFwjGegFyDDN/').send( 
      {from: this.direction});
  } 

  async mintNFT() {
    console.log('Mi direccion para mintear es: ', this.direction);
    this.contract.costeJugar("0").then(async (coste:any) => {
      console.log(this.owner);
      return await this.contratoNFT.methods.newNFT(this.direction, 4).send(
        {from: this.direction, value: coste})
    });
  }

  openDialog(data: any) {
    console.log(data);
    const dialogRef = this.dialog.open(DialogComponent, {
      data: data
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        //this.tarjeta.bloqueada = true;
        console.log(`Dialog result: ${result}`);
      });
  }

  // async getDatosCasillas(contrato){
  //   console.log(contrato);
  //   return await contrato.methods.GetAllDatosCasilla().call();
  // }

  desbloquea(data: any) {
    //todo pago de HBLOC para desbloquear
  }

  onFilterChange(event) {
    this.tipo = event.value;
  }

}
