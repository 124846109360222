import { Component, OnInit } from '@angular/core';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  menuItems = ['Home', 'Play', 'Transaction'];

  public activeLang = 'en';

  ngOnInit(): void {
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private contract: ContractService,
    ) {

     }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
  }


  connectAccount() {
    this.contract
    .connectAccount()
    .then((value: any) => {
      console.log(value);
     /* this.profile = this.threebox.getProfile(this.direction).then((response) => {
          console.log(response);
          this.profile = response;
          this.url = this.profile.image[0].contentUrl["/"];
        }); */
    })
    .catch((error: any) => {
      this.contract.failure(
        "Could't get the account data, please check if metamask is running correctly and refresh the page"
      );
    });
  }



}
