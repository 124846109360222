<div style="display: block;">
    <canvas baseChart
              [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"

              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">
    </canvas>
  </div>
        