import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lecciones } from '../../interfaces/cartera';

import { DomSanitizer } from '@angular/platform-browser'
import { MatStepper } from '@angular/material/stepper';
import { ContractService } from 'src/app/services/contract/contract.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Decimal from '../../../assets/js/decimal.js';
import { DataService } from 'src/app/services/data.service';


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(
     private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.scss']
})
export class LearningComponent implements OnInit {

   id: number = 0;
   leccion: any;
   temario = [];
   num_tokens = 0;
   num_usdc = 0;
   ultimaCasilla = 0;
   panelOpenState = false;
   loaded = false;
   direction = '';
   misNfts;

   amountCrypto=(amount,decimals)=>Decimal.mul(amount,Decimal.pow(10, decimals));
   totalInvestedUSDC = 0;
   balancesTokensInUSDC = [];
   distributionTokens = [0, 0, 0, 0];
   balancesTokens = 0;
   priceOffChainETH_USD = 0;
   tokens = [];

   // Doughnut Data
  doughnutChartLabels = ['BTC', 'ETH', 'USDC', 'Hblock'];
  doughnutChartData = [
    [0, 0, 0, 0],
  ];

   //Tokens Cartera
   usdc = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
   symbols = [
      { 'address': '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 'symbol': 'USDC', 'decimals': 6}, 
      { 'address': '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 'symbol': 'ETH', 'decimals': 18},
      { 'address': '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', 'symbol': 'BTC', 'decimals': 8}
   ];

   data = [];// :MatTableDataSource < any[] > = new MatTableDataSource<any[]>([]);
   displayedColumns: string[] = ['time', 'tokenA', 'tokenB', 'price', 'distribution'];

   @ViewChild('stepper') stepper: MatStepper;
   firstFormGroup: FormGroup;
   secondFormGroup: FormGroup;

   // Test
   selectedCountry;
   toppings = new FormControl();
   toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

   constructor(
      private _formBuilder: FormBuilder,
      private contract: ContractService,
      private dataService: DataService,
      public snackBar: MatSnackBar,
      private router: Router,
      private route: ActivatedRoute
   ) {}

   ngOnInit() {
      this.direction = localStorage.getItem('myAddress');
      this.ultimaCasilla = parseInt(localStorage.getItem('ultimaCasilla'));
      this.firstFormGroup = this._formBuilder.group({
         firstCtrl: [
            ['', Validators.required],
         ]
      });
      this.secondFormGroup = this._formBuilder.group({
         // q1Ctrl: ['', Validators.required]
      });

      this.route.params.subscribe(params => {
         this.id = +params['id']; // (+) converts string 'id' to a number
         // LLamada GET id de la leccion
         if(this.ultimaCasilla  >= this.id){
            // this.leccion = lecciones.find(element => element.cartera == this.id);
            //bbdd
            this.dataService.getLeccionesCartera(this.id)
               .subscribe((leccion: any) => { 
                  console.log(leccion);
                  this.leccion = leccion[0]['leccion'];
                  this.temario = leccion;
               });
         } else {
            console.log(this.ultimaCasilla, this.id);
            alert('Esta leccion no está desbloaqueada');
         }
      });

      this.contract.getContractPortfolioCrypto()
         .then((contratoPortfolioCrypto: any) => { 
            console.log('contrato PortfolioCrypto: ', contratoPortfolioCrypto);
            contratoPortfolioCrypto.methods.totalInvestedUSDC().call().then(totalInvestedUSDC => {
               this.totalInvestedUSDC = totalInvestedUSDC;
            });
            // contratoPortfolioCrypto.methods.getBalancesTokensInUSDC().call().then(getBalancesTokensInUSDC => {
            //    console.log(getBalancesTokensInUSDC);
            //    this.doughnutChartData = getBalancesTokensInUSDC['balancesTokensInUSDC'].map((item) => { return item/1000000 });
            //    this.balancesTokensInUSDC = getBalancesTokensInUSDC;
            // });
            contratoPortfolioCrypto.methods.getDistributionTokens().call().then(distributionTokens => {
               console.log(distributionTokens);
               this.doughnutChartData[0][0] = distributionTokens[2]/10000;
               this.doughnutChartData[0][1] = distributionTokens[1]/10000;
               this.doughnutChartData[0][2] = distributionTokens[0]/10000;
               this.doughnutChartData[0][3] = distributionTokens[3]/10000;
               this.doughnutChartData= [...this.doughnutChartData];
            });
            contratoPortfolioCrypto.methods.getBalancesTokens(0,1).call().then(balancesTokens => {
               console.log(balancesTokens);
               this.balancesTokens = balancesTokens;
            });
            contratoPortfolioCrypto.methods.tokens(0).call().then(tokens => {
               console.log(tokens);
               this.tokens = tokens;
            });
            contratoPortfolioCrypto.methods.priceOffChainETH_USD().call().then(priceOffChainETH_USD => {
               this.priceOffChainETH_USD = priceOffChainETH_USD;
            });
            // Distribution para chart
            contratoPortfolioCrypto.methods.sizeTokens().call().then((sizeTokens:number) => {
               if (sizeTokens > 0) {
                  contratoPortfolioCrypto.methods.getBalancesTokens(0, sizeTokens).call().then((balancesTokens) => {
                     console.log(balancesTokens);
                     balancesTokens.forEach(element => {
                        console.log(element['token']);
                        switch(element['token']){
                           case "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6":
                              // console.log('este esta', element['currentBalance'])
                             this.distributionTokens[0] = Number(element['currentBalance'])/(10**element['decimals']); 
                             break;
                           case "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619":
                             this.distributionTokens[1] = Number(element['currentBalance'])/(10**element['decimals']); 
                             break;
                           case "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174":
                             this.distributionTokens[2] = Number(element['currentBalance'])/(10**element['decimals']); 
                             break;
                           case "0x1b69d5b431825cd1fc68b8f883104835f3c72c80":
                             this.distributionTokens[3] = Number(element['currentBalance'])/(10**element['decimals']); 
                             break;
                           default:
                             console.log('que pasa');
                         }
                     });
                  });
               }
            });

            // Historical
            contratoPortfolioCrypto.methods.sizeHistorical().call().then((sizeHistorical:number) => {
               console.log(sizeHistorical);
               if (sizeHistorical > 0) {
                  let arrayHist = [];
                  for (let i = 0; i < sizeHistorical; i++) {
                     contratoPortfolioCrypto.methods.historical(i).call().then(historico => {
                        console.log('historico', historico);
                        const tokA = this.getSymbolByAddress(historico['tokenA']);
                        const tokB = this.getSymbolByAddress(historico['tokenB']);

                        arrayHist.push({
                           time: this.toDateTime(historico['time']), tokenA: tokA.symbol, 
                           tokenB: tokB.symbol, price: (historico['price']/10**tokB.decimals), 
                           distribution: historico['distribution']/10000
                        });
                        console.log(arrayHist);
                        this.data = arrayHist.sort((a, b) => b.time - a.time);;
                        this.data = [ ...this.data ]; 
                     });
                  }
                  console.log(this.data);
               }
            });
         });

   }

   getSymbolByAddress(address: string): any{
      const token = this.symbols.find((obj) => obj.address == address);
      return token;
   }

   toDateTime(secs) {
      var t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(secs);
      return t;
  }

   changeOption(e) {
      console.log(e.value);
      if (e.value == 1) {
         console.log('es correcto: pasa');
         this.stepper.next();
      } else {
         alert('Opción no válida, prueba de nuevo, te quedan 2 intentos');
      }
   }

   staking(){
      console.log(this.num_tokens);
      console.log(this.id);
      let tipo = 0;

      if( this.id == 1 ) {
         tipo = 0;
      }
      else if( this.id == 3 ) {
         tipo = 1;
      }
      else if( this.id == 6 ) {
         tipo = 3;
      } else {
         alert("Vas a hacer staking con USDC, espera que abramos las ventanas de liquidez");
      }
      let tokens = (this.num_tokens).toString();
      console.log('vas a hacer staking de tipo: ', tipo, tokens + "000000000000000000");
      
      if(this.num_tokens>199) {
         this.contract
         .getContractStaking()
         .then((contrato: any) => { 
            console.log('contrato Staking: ', contrato);
            console.log(this.direction);
            this.contract.getContractToken().then((cont: any) => {
               cont.methods.approve('0x082B0c389A77558A86081691aBc4270D291a594e', tokens + "000000000000000000").send({from: this.direction}).then(receipt => {
                  console.log(receipt); //String()

                  this.stake(contrato, tokens + "000000000000000000", tipo)
                     .then((value: any) => {
                        console.log(value);
                     });
               });
            });
         });
      } else {
         alert("Es necesario hacer staking con al menos 200 HBLOCK");
      }
   }

   async stake(contrato, amount: string, type: number) {
      console.log(type);
      return await contrato.methods.stake(amount, String(type)).send({from: this.direction});
   }

   compareNtfs(nft1,nft2){
      return nft1.filter(x=>x.level!=nft2.filter(y=>y.id==x.id)[0].level)[0]
    }

   buyNFT(casilla: number){
      
      let precio = (casilla == 8) ? 2 : 5;
      let tipo = (casilla == 8) ? 0 : 1;
      this.loaded = true;

      this.contract.getContractNFT().then(async (contratoNFT: any) => {
         this.contract.getMisNtfs(contratoNFT, this.direction).then( (nfts1: any) => {
            console.log('Mis NFTs antes son:', nfts1, nfts1.length);
            // checkNFTsAntes
            this.contract.getContractMintNFT().then((contratoBuyNFT: any) => {
               console.log('contratoBuyNFT', contratoBuyNFT);
                  contratoBuyNFT.methods.amountTokensForUSD(precio).call().then((hblock: number) => {
                     console.log(hblock/10**18);
                     this.contract.getContractToken().then((cont: any) => {
                        // Miro si el boton esta activo
                        let infinity = Boolean(localStorage.getItem('operacionesDefault'));
                        console.log(infinity);
                        this.contract.allowance_approve(cont, this.direction, '0xc37db02771128936775bd32d94e41fa9ec8c87d3', String(hblock*1.05), infinity, ()=>{
                           contratoBuyNFT.methods.buyNFT(tipo).send({from: this.direction}).then(ok => {
                              console.log(ok);
                              if(ok.status){
                                 this.loaded = false;
                                 this.contract.getMisNtfs(contratoNFT, this.direction).then( (nfts2: any) => {
                                    console.log('Mis NFTs despues son:', nfts2, nfts2.length);
                                    // Veo el que le ha tocado
                                    if(nfts2.length > nfts1.length) {
                                       console.log('Mi new NFT es:', nfts1[nfts1.length - 1]);
                                       this.openSnackBar('¡Nuevo NFT! ', nfts1[nfts1.length - 1].nombre);
                                    } else if (nfts2.length == nfts1.length) {
                                       let newLevel = this.compareNtfs(nfts2, nfts1);
                                       console.log('Mi NFT que ha subido nivel es:', this.compareNtfs(nfts2, nfts1), nfts2, nfts1);
                                       this.openSnackBar('Sube de nivel tu NFT: ', newLevel.nombre);
                                    } else {
                                       console.log('Hay un problema para saber el NFT que ha tocado' );
                                    }
                                 });
                              } else {
                                 alert("No se pudo realizar la compra del NFT");
                              }
                              });
                        });
                  });
               });
            });
         });
      });

      // let nfts0 = empresasPrueba0;
      // let nfts1 = empresasPrueba1;

      // console.log('Longitudes:', nfts0.length, nfts1.length)
      // // last element que ha entrado al array
      // // let lastNft = nfts0[nfts0.length - 1];
      // if(nfts1.length > nfts0.length) {
      //    console.log('Mi new NFT es:', nfts1[nfts1.length - 1]);
      // } else if (nfts1.length == nfts0.length) {
      //    let newLevel = this.compareNtfs(nfts0, nfts1);
      //    console.log('Mi NFT que ha subido nivel es:', newLevel);
      //    this.openSnackBar('Sube de nivel tu NFT: ', newLevel.nombre);
      // } else {
      //    console.log('Hay un problema para saber el que ha tocado' );
      // }

   }

   invest(){
      console.log(this.num_usdc, this.amountCrypto(this.num_usdc, 6).toFixed(0));
      let infinity = Boolean(localStorage.getItem('operacionesDefault'));
      if(this.num_usdc>=1) {
         this.contract.getContractPortfolioCrypto()
            .then((contratoPortfolioCrypto: any) => { 
               console.log('contrato PortfolioCrypto: ', contratoPortfolioCrypto);
               this.contract.getContractAnyToken(this.usdc).then((contratoUSDC: any) => {
                  this.contract.allowance_approve(contratoUSDC, this.direction, '0x3839AF5bF254A2B1c698fAf5D5bac541576f4A68', this.amountCrypto(this.num_usdc, 6).toFixed(0), infinity, ()=>{
                     contratoPortfolioCrypto.methods.invest(this.amountCrypto(this.num_usdc, 6).toFixed(0)).send({from: this.direction}).then(ok => {
                        console.log(ok);
                     });
                  });
               });
            });
      } else {
         alert("Es necesario invertir al menos 1 USDC");
      }
   }

   openSnackBar(msg: string, value: any) {
      const snackBarRef = this.snackBar.open(msg + String(value), '¡Enhorabuena!, clic para seguir...', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      snackBarRef.afterDismissed().subscribe(() => {
        console.log('The snackbar was dismissed');
        this.router.navigate(['/pages/board']);
      });
    }

}
