import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PresaleComponent } from './ui/presale/presale.component';
import { AccountComponent } from './ui/account/account.component';
import { BoardComponent } from './ui/board/board.component';
import { CarterasComponent } from './ui/carteras/carteras.component';
import { DashboardComponent } from './ui/dashboard/dashboard.component';
import { ErrorComponent } from './ui/error/error.component';
import { HomeComponent } from './ui/home/home.component';
import { LearningComponent } from './ui/learning/learning.component';
import { LearningBcComponent } from './ui/learningbc/learningbc.component';
import { OnlineTestComponent } from './ui/online-test/online-test.component';
import { FooterComponent } from './ui/shared/footer/footer.component';
import { TopNavComponent } from './ui/top-nav.component';
import { TransactionComponent } from './ui/transaction/transaction.component';
import { ColeccionComponent } from './ui/coleccion/coleccion.component';
import { AuthGuard } from './services/auth.guard';
import { NftTarjetaComponent } from './components/nft-tarjeta/nft-tarjeta.component';
import { CalendarioComponent } from './ui/calendar/calendario.component';
import { MarketplaceComponent } from './ui/marketplace/marketplace.component';
import { EmpresasComponent } from './info/empresas/empresas.component';
import { MovimientosComponent } from './info/movimientos/movimientos.component';
import { ProfitComponent } from './info/profit/profit.component';
import { SwapComponent } from './info/swap/swap.component';

const routes: Routes = [{
  path: 'pages', 
  // loadChildren: () => import('./ui/ui.module').then(m => m.UiModule)
  component: TopNavComponent,
  children: [
    { path: '', redirectTo: 'home', pathMatch: 'full'},
    { path: 'presale', component: PresaleComponent },
    { path: 'coleccion', component: ColeccionComponent },
    { path: 'home', component: HomeComponent},
    { path: 'dashboard', component: DashboardComponent},
    { path: 'board', component: BoardComponent},
    { path: 'carteras', component: CarterasComponent},
    { path: 'calendar', component: CalendarioComponent},
    { path: 'market', component: MarketplaceComponent},
    { path: 'footer', component: FooterComponent},
    { path: 'learning/:id', component: LearningComponent},
    { path: 'learning/bc/:id', component: LearningBcComponent},
    { path: 'account', component: AccountComponent},
    { path: 'empresas', component: EmpresasComponent},
    { path: 'movimientos', component: MovimientosComponent},
    { path: 'profit', component: ProfitComponent},
    { path: 'swap', component: SwapComponent},
    { path: '404', component: ErrorComponent },
    { path: '**', redirectTo: '/404' },
  ]
  },
  { path: 'info', 
    component: TopNavComponent,
    loadChildren: () => import('./info/info.module').then( m => m.InfoModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  { path: '', component: FooterComponent },
  { path: 'nft/:id/:level', component: NftTarjetaComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
