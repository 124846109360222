import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { empresas } from 'src/app/interfaces/empresas';
import { ContractService } from 'src/app/services/contract/contract.service';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { EmpresaComponent } from 'src/app/info/empresa/empresa.component';
import { DataService } from 'src/app/services/data.service';
import { CryptoComponent } from 'src/app/info/crypto/crypto.component';

@Component({
  selector: 'app-nft-tarjeta',
  templateUrl: './nft-tarjeta.component.html',
  styleUrls: ['./nft-tarjeta.component.scss']
})
export class NftTarjetaComponent implements OnInit {

  id = 0;
  // level = 0;

  @Input() nft;
  contratoNFT;
  direction = "";
  nfts = [];
  loaded = false;

  constructor(
    private contract: ContractService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private dataService: DataService,
  ) {}

  ngOnInit(): void {

  // Codigo para generar NFTs
  //   this.route.params.subscribe(params => {
  //     this.id = +params['id'];
  //     this.level = +params['level'];
  //     this.nft = empresas.find(element => element.id == this.id);
  //  });

    this.nft.desc = empresas[this.nft.id].desc || "";
    this.direction = localStorage.getItem('myAddress');

    this.contract
      .getContractNFT()
      .then((contrato: any) => {
        this.contratoNFT = contrato;
        this.contract.getNtfsMinted(contrato, this.direction).then( (nfts: any[]) => {
          // console.log('todos los NFTs Minteados:', nfts);
          let index = nfts.findIndex(nft => nft.nombre === this.nft.nombre);
          // console.log(index);
          this.nft.index = index;
          this.nfts = nfts;
        });
      });
  }

  info(nft){
    console.log(nft);
    if (nft.tipo == "Cryptocurrency") {
      this.dataService.getCryptos()
      .subscribe( (cryptos: any[]) => {
        console.log(cryptos);
        let crypto = cryptos['results'].find(i => i.symbol === nft.icono);
        console.log(crypto);
        this.dialog.open(CryptoComponent, {
          height: '70%',
          width: '80%',
          data: {
            nft: nft,
            crypto: crypto,
          },
        });
      });
    } else {
      this.dataService.getEmpresas()
        .subscribe( (acciones: Accion[]) => {
          console.log(acciones);
          let accion = acciones.find(i => i.symbol === nft.icono);
          console.log(nft.icono, accion);
          this.dialog.open(EmpresaComponent, {
            height: '70%',
            width: '80%',
            data: {
              nft: nft,
              accion: accion,
            },
          });
        });
      }

  }

  generateImage(id, level){

    let node = document.getElementById('image-section');
    
    domtoimage.toBlob(node)
      .then(function (blob) {
          saveAs(blob, id + '_' + level +  '.png');
      });

    // var node:any = document.getElementById('image-section');
    // htmlToImage.toPng(node)
    //   .then(function (dataUrl) {
    //     var img = new Image();
    //     img.src = dataUrl;
    //     document.body.appendChild(img);
    //   })
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   });
  }

  async balanceOfAddress(direccion: string) {
    return await this.contratoNFT.methods.balanceOf(direccion).call();
  } 

  async getOwnerNFTIndex(index: number) {
    return await this.contratoNFT.methods.ownerOf(index).call();
  } 

  async getNumberOfNFT(direccion: string) {
    return await this.contratoNFT.methods.balanceOf(direccion).call();
  } 

  async upLevelEmpresa(index: number) {
    console.log(index);
    // let carga = this.loaded;
    // this.loaded = true;
    // this.contract.costeJugar("0").then(async (coste:any) => {
    //   await this.contratoNFT.methods.level_up(index).send( //cambiar funcion level_up de internal a public
    //     {from: this.direction, value: coste})
    //     .on('transactionHash', function(receipt){
    //       carga = false;
    //       console.log("Transaction confirmed", receipt);
    //       window.location.reload();
    //     })
    //     .on('error', console.error);
    // });
  } 

}

export interface Accion {
  id: string;
  nombre: string;
  symbol: string;
  favorito: boolean;
  recomendado: boolean;
  pais: string;
  logo?: string;
}
