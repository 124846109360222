import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


// Components
import { AccountComponent, DialogHistoricoDialog } from './account/account.component';
import { HomeComponent } from './home/home.component';
import { TopNavComponent } from './top-nav.component';
import { TransactionComponent } from './transaction/transaction.component';
import { ErrorComponent } from './error/error.component';
import { AppMaterialModule } from "../app-material.module";

// Routing
import { UiRoute} from "./ui.routes";
import { RouterModule} from "@angular/router";

// Services
import { ContractService } from "../services/contract/contract.service";
import { ThreeBox } from "../services/3box.service"
import { TranslateModule } from '@ngx-translate/core';
import { BoardComponent } from './board/board.component';

import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarterasComponent } from './carteras/carteras.component';
import { LearningComponent, SafePipe } from './learning/learning.component';
import { DialogComponent } from './dialog/dialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { CardComponent } from './components/card/card.component';
import { MiniCardComponent } from './components/mini-card/mini-card.component';
import { CarteraTarjetaComponent } from './components/cartera-tarjeta/cartera-tarjeta.component';
// import { NftTarjetaComponent } from './components/nft-tarjeta/nft-tarjeta.component';
import { FooterComponent } from './shared/footer/footer.component';
import { OnlineTestComponent } from './online-test/online-test.component';
import { OnlineTestService } from '../services/online-test.service';
import { TopBarComponent } from './shared/top-bar/top-bar.component';
import { DialogBonusComponent } from './dialog-bonus/dialog-bonus.component';
import { DialogBlockchainComponent } from './dialog-blockchain/dialog-blockchain.component';
import { DialogMineriaComponent } from './dialog-mineria/dialog-mineria.component';
import { LearningBcComponent } from './learningbc/learningbc.component';
import { PresaleComponent } from './presale/presale.component';
import { ColeccionComponent } from './coleccion/coleccion.component';
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { CalendarioComponent } from './calendar/calendario.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { VenderComponent } from './marketplace/vender/vender.component';
// import { LineComponent } from '../shared/charts/line/line.component';
// import { DonutComponent } from '../shared/charts/donut/donut.component';

@NgModule({
  declarations: [
    AccountComponent,
    DialogHistoricoDialog,
    PresaleComponent,
    HomeComponent,
    TopNavComponent,
    FooterComponent,
    TransactionComponent,
    ErrorComponent,
    DialogComponent,
    BoardComponent,
    CarterasComponent,
    LearningComponent,
    LearningBcComponent,
    SafePipe,
    DashboardComponent,
    CardComponent,
    MiniCardComponent,
    // LineComponent,
    // DonutComponent,
    DialogMineriaComponent,
    DialogBonusComponent,
    DialogBlockchainComponent,
    CarteraTarjetaComponent,
    // NftTarjetaComponent,
    OnlineTestComponent,
    ColeccionComponent,
    CalendarioComponent,
    MarketplaceComponent,
    VenderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(UiRoute),
    AppMaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    MatDialogModule,
    ChartsModule,
    ComponentsModule,
    FormsModule,
    SharedModule
  ],
  exports: [
    TopNavComponent,
    FooterComponent,
    HomeComponent,
    BoardComponent,
    CarteraTarjetaComponent,
    OnlineTestComponent
  ],
  providers: [
    ContractService,
    ThreeBox,
    OnlineTestService
  ],
})
export class UiModule { }
