import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { tokens } from './tokens';

export interface Token {
  token: String;
  symbol: String;
  logo: String;
}

@Component({
  selector: 'app-select-tokens',
  templateUrl: './select-tokens.component.html',
  styleUrls: ['./select-tokens.component.scss']
})
export class SelectTokensComponent implements OnInit {

  tokens = tokens;
  tokenCtrl = new FormControl('');
  filteredTokens: Observable<any[]>;

  token: Token;
  @Output() onNftSelected: EventEmitter<any> = new EventEmitter(); 
  @Output() onClean: EventEmitter<any> = new EventEmitter(); 

  constructor() {
    this.filteredTokens = this.tokenCtrl.valueChanges.pipe(
      startWith(''),
      map(token => (token ? this.filterTokens(token) : this.tokens.slice())),
    );
  }

  clean(){
    this.tokenCtrl.reset();
    this.token = {token: "", symbol: "", logo: ""}
    this.onClean.emit({token: "", symbol: "", logo: ""});

    this.filteredTokens = this.tokenCtrl.valueChanges.pipe(
      startWith(''),
      map(token => (token ? this.filterTokens(token) : this.tokens.slice())),
    );
  }

  onChange(event){
    this.token = {token: event.value.token, symbol: event.value.symbol, logo: event.value.logo};
    this.onNftSelected.emit(this.token);
  }

  displayFn(token): string {
    return token && token.symbol ? token.symbol : '';
  }

  private filterTokens(value): any[] {
    if(value.length > 0){
      const filterValue = value.toUpperCase();
  
      return this.tokens.filter(token => token.symbol.toUpperCase().includes(filterValue));
    }
  }

  ngOnInit(): void {
  }

}
