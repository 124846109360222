import {
  faLongArrowAltLeft,
  faQuestion,
  faSubway,
  faCube,
  faFrownOpen,
  faLightbulb,
  faCar,
  faTint,
  faGavel,
  faGem,
} from '@fortawesome/free-solid-svg-icons';

// Tarjeta todo a nuevo component
export const tarjetas = [
  {
    'number': 9,
    'nombre': 'Cartera 5',
    'class': 'property',
    'tipo': '',
    'color': 'color-bar light-blue',
    'icon': '',
    'instructions': '',
    'price': 30,
    'home': false,
    'bloqueada': false},
  {
    'number': 8,
    'nombre': 'Cartera 4',
    'class': 'property',
    'tipo': '',
    'color': 'color-bar light-blue',
    'icon': '',
    'instructions': '',
    'price': 25,
    'home': false,
    'bloqueada': false},
  {
    'number': 7,
    'nombre': '¡Suerte!',
    'class': 'chance',
    'tipo': '',
    'color': '',
    'icon': faQuestion,
    'instructions': 'CARTA',
    'price': 0,
    'home': false,
    'bloqueada': false},
  {
    'number': 6,
    'nombre': 'Cartera 3',
    'class': 'property',
    'tipo': '',
    'color': 'light-blue',
    'icon': '',
    'instructions': '',
    'price': 20,
    'home': true,
    'bloqueada': false},
  {
    'number': 5,
    'nombre': 'Estación',
    'class': 'railroad',
    'tipo': '',
    'color': '',
    'icon': faSubway,
    'instructions': 'CARTA',
    'price': 50,
    'home': false,
    'bloqueada': false},
  {
    'number': 4,
    'nombre': 'Impuestos',
    'class': 'fee income-tax',
    'tipo': '',
    'color': '',
    'icon': '',
    'instructions': 'Paga 10%',
    'price': 20,
    'home': false,
    'bloqueada': false},
  {
    'number': 3,
    'nombre': 'Cartera 2',
    'class': 'property',
    'tipo': '',
    'color': 'color-bar dark-purple',
    'icon': '',
    'instructions': 'CARTA',
    'price': 10,
    'home': false,
    'bloqueada': false},
  {
    'number': 2,
    'nombre': 'Comunidad',
    'class': 'community-chest',
    'tipo': '',
    'color': '',
    'icon': faCube,
    'instructions': 'CARTA',
    'price': 8,
    'home': false,
    'bloqueada': true},
  {
    'number': 1,
    'nombre': 'Cartera 1',
    'class': 'property',
    'tipo': 'dividendos',
    'color': 'dark-purple',
    'icon': '',
    'instructions': '',
    'price': 20,
    'home': true,
    'bloqueada': true
  }
];

// Tarjeta todo a nuevo component
export const carteras = [
  {
    'id': 4,
    'nombre': 'Cartera 4',
    'class': 'property',
    'tipo': 'criptomonedas',
    'color': 'color-bar light-blue',
    'icon': '',
    'instructions': '',
    'price': 25,
    'home': false,
    'bloqueada': false},
  {
    'id': 3,
    'nombre': 'Cartera 3',
    'class': 'property',
    'tipo': 'criptomonedas',
    'color': 'light-blue',
    'icon': '',
    'instructions': '',
    'price': 20,
    'home': true,
    'bloqueada': false},
  {
    'id': 2,
    'nombre': 'Cartera 2',
    'class': 'property',
    'tipo': 'dividendos',
    'color': 'color-bar dark-purple',
    'icon': '',
    'instructions': '',
    'price': 10,
    'home': false,
    'bloqueada': false},
  {
    'id': 1,
    'nombre': 'Cartera 1',
    'class': 'property',
    'tipo': 'dividendos',
    'color': 'dark-purple',
    'icon': '',
    'instructions': '',
    'price': 20,
    'home': true,
    'bloqueada': true
  }
];

export const lecciones = [
  {
    'cartera': 1,
    'nombre': 'Cartera 1',
    'tipo': 'estrategia',
    'riesgo': '0',
    'intro': `<h3>¡Bienvenido/a a tu primera cartera!</h3>
    ¿Conoces aquella frase de “no pongas en la misma cesta todos tus huevos”? Pues te voy a contar una estrategia de diversificación basada en esta teoría.<br>
    Lo primero, no corramos demasiado y veamos que fases son necesarias para llegar a la última, la de inversión:<br><br>
    <center><img class="img" width="500px" src="assets/lecciones/L1-1.png"></center>
    <h2>Funcionamiento de todas las carteras</h2>
    <p>Ya es hora de que descubras tu primera cartera. Ahora toca estudiar un poco, pero te lo ponemos fácil, un texto que va al grano y un video resumen de lo visto. Una vez completo un pequeño test conseguirás desbloquear esta cartera y tendrás la posiblidad de depositar HBLOCK para invertir.<br><br>
    <h3>Haz clic en EMPEZAR para debloquear esta cartera. ¡Ánimo! Nos vemos en la siguiente cartera.</h3>
  `,
    'estrategia': `

  `,
  'temario': 
    [ 
      { 
          'titulo': 'Estrategia',
          'texto':`
          Lo primero, no corramos demasiado y veamos que fases son necesarias para llegar a la última, la fase de la inversión:<br><br>
          Salvo que heredemos una cantidad de dinero relativamente importante, no es posible llegar a esta fase de inversión sin trabajar duro y ahorrar. Por lo cual, una buena formación y trabajo duro son esenciales antes de invertir. En esta primera cartera vamos a hablar de la importancia de una estrategia clara que sirva como hoja de ruta para llegar a nuestras metas.
           `
      },
      { 
        'titulo': 'Dinero necesario para invertir',
        'texto':`
        Pero claro, esto hace plantearnos la pregunta del millón: ¿Cuánto dinero es necesario ahorrar antes de invertir? Esto dependo de muchos factores (sueldo, gastos mensuales, etc) y se debería llegar, con el tiempo, a una tasa de ahorro del 50%. La buena noticia, tenemos otra forma de conseguirlo y, para ello, hemos creado diferentes carteras en esta plataforma.<br><br>
        Dedica tus primeros años a tener varias fuentes de ingresos y a formarte (en esto ya te estamos ayudando nosotros). No inviertas solo por prisas de querer empezar, es un error muy común. Sino tienes demasiados conocimientos, aprovecha la ocasión de invertir en nuestras carteras más moderadas mientras sigues formándote y ahorrando.<br><br>
      `
      },
      { 
        'titulo': 'Plan de Inversión',
        'texto':`
        Disponte a realizar tu Plan de Inversión siguiendo algunas pautas que te damos en este capítulo y en los siguientes. Por último, sólo te queda esperar para que el efecto bola de nieve haga su trabajo. La idea es que ganes dinero mientras duermes.<br><br>
        Llegados a este punto, vamos a hablar de cartera de inversión o cesta donde vamos a poner nuestro dinero, es decir, los huevos. Tu cartera (también la llamaremos portafolio) es la distribución de cómo vamos a invertir el dinero ahorrado. Recuerda siempre, nuestra herramienta de trabajo es el dinero.<br><br>
        Supongamos el Año 0, año en el que te planteas otra forma de ver la vida, y partimos de un valor inicial de capital ahorrado de 5.000€. En este momento dispones de una cesta con 5 huevos, suponiendo que cada huevo vale 1.000€. Entonces, en nuestro Plan de Inversión podríamos proceder a diversificar dicha cesta mediante los siguientes criterios:<br><br>
        1.Fondos Indexados: colocaremos 1 ó 2 huevos.<br><br>
        2. Acciones (renta variable): colocaremos 1 ó 2 huevos.<br><br>
        3. Criptomonedas: colocaremos 1 ó 2 huevos.<br><br>
        Más tarde (en los siguientes capítulos) te contaré que es cada cosa. Por ahora, lo que quiero que te quede claro en la mente es lo siguiente: si pongo varios huevos en varios lugares, la probabilidad de que pierda todos mis huevos es menor que si los pongo todos en la misma cesta.<br><br>
         `
      }
    ],
    'url_video': 'https://www.youtube.com/embed/A7oc1q04qAk',
    'cuestion': 'Indica la caracteristica principal de esta lección: ',
    'stakingTitulo': 'STAKING FLEXIBLE HBLOCKS',
    'stakingSubtitulo': 'Manten tus monedas el tiempo que quieras',
    'stakingPorcentaje': '5 %',
    'stakingTiempo': 'Flexible',
  },
  {
    'cartera': 2,
    'nombre': 'Análisis Fundamental',
    'tipo': 'blockchain',
    'riesgo': '0',
    'intro': `<h3>¡Bienvenido/a a tu primera lección de análisis!</h3>
En análisis fundamental de una empresa viene a decirnos cómo son sus números a nivel macro de ingresos y beneficios, de modo que nos sirve para filtrar las mejores empresas que van a ser aquellas que generan dinero de forma sostenida en el tiempo. Principalmente lo usaremos para la estrategia de dividendos y para empresas a largo plazo. Buscamos las empresas números uno en lo suyo, que apenas tienen rival en su sector, que son líderes en el presente y que generan grandes ingresos. Por tanto suelen tener buenos ratios PER (lo veremos más tarde).<br><br>
En el caso de estrategias de corto plazo no nos vamos a centrar en que ahora generen mucho dinero, sino en su crecimiento anual sea al menos del 20%, es decir, empresas que tienen expectativas en ser líderes en el mañana, bien por que tienen una tecnología rompedora, difícil de copiar o porque su producto o servicio es altamente innovador.<br><br>
Pero no te preocupes, tienes un capítulo específico para las empresas de dividendos. Las empresas tipo Coca Cola, Johnson & Johnson, P&G, Nestle, Unilever, etc... son ejemplos claros que veremos más tarde. Nadie tiene duda que son los líderes de su mercado y además, trimestralmente reparten dividendo, ¿qué más se puede pedir?<br><br>
En la siguiente sección, te cuento de qué tipo de empresas estamos hablando para las estrategias de corto y largo plazo, que quizás no las tengas tan claras. Con nombres se entiende todo más claro. Tras esta breve intro pasamos a comentar los mercados y las bolsas donde operan estas empresas. ¡Vamos a ello!
    <br><br>
  `,
'temario': 
[ 
  { 
    'titulo': '1. Tipos de empresas según estrategia',
    'texto':`
  Para ilustrarlo de forma más clara me apoyaré en ejemplos de empresas que todos conocemos. Para el caso de empresas de largo plazo un ejemplo claro sería Apple, empresa líder en el sector de los teléfonos inteligentes y cada vez más en servicios digitales. Otro caso sería el de Amazon, Google, Disney o incluso Tesla, que, aunque hoy en día no genera un gran volumen de ingresos, en los próximos 2-3 años no tendrá rival en su sector. Como ves para mí la clave está en ser el mejor en lo tuyo, porque de esa forma y si es un sector en auge, el dinero no tardará en llegar. Te habrás dado cuenta que hay un factor de expectativas, es decir, el mercado descuenta lo que prevé que las empresas pueden crecer.<br><br>
  En el caso de estrategias de corto plazo tenemos empresas menos conocidas, pero si quieres busca las siguientes: Nvidia, Datadog, Palantir, etc... Ojo que esta distinción a veces es compleja, bajo tu criterio podrías meter aquí a Tesla, y eso está bien. Te digo como yo lo hago: para mí es como si estas fueran de segunda división bajo el símil futbolístico, de tal modo que las mejores pasan a primera una vez si asientan. Aquí por ejemplo tengo el caso de AMD. Si no la conoces es una empresa que hace microprocesadores, los cerebros de cualquier aparato electrónico. Hasta ahora el líder era Intel, pero AMD tiene una mejor tecnología que va a hacer que pierda el reinado, así que para mí hasta que llegue ese momento sigue en segunda división.<br><br>
  Te animo a que busques estas empresas y otras que te gusten o interesen. En este libro encontrarás mis referencias, pero hay cientos de miles de empresas, para todos los gustos. Como ves no he comentado todavía ninguna empresa española, jajajaja... Como te dije evito invertir ahí por las razones que ya te comenté, pero por ejemplo las típicas Santander, Iberdrola o Telefónica son empresas que no me interesan o que no les veo mucho potencial. Si me pides elegir alguna seguramente sería Viscofan o Cellnex. Tampoco invierto en sectores de petróleo, tabaco o cannabis, creo que en la inversión hay un poco de la personalidad de cada uno.<br><br> 
  Pero todo esto es personal de cada uno, tú debes hacer lo que mejor veas. En ningún caso debes tener muchos sentimientos a las empresas, te recuerdo que aquí venimos a ganar. Tómatelo como un trabajo.
  `
},
{ 
  'titulo': '2. La bolsa y los mercados',
  'texto':`
  Como comentábamos anteriormente, el acceso a los mercados ahora es más sencillo que nunca. La llegada de internet ha venido a cambiar un sector que hasta ahora era para un pequeño grupo de selectos o una élite, como quieras llamarlo. Voy a intentar de explicarte quien hay detrás de los mercados de la bolsa de la manera más sencilla posible y usando un símil marino.<br><br>
En primer lugar, están las ballenas. Son aquellas instituciones que pueden comprar un volumen de acciones muy grande y por tanto su entrada marca el devenir de una acción. Normalmente se meten a largo plazo. Un ejemplo de estos sería el fondo de Noruega que como sabrás tiene bastantes acciones del Ibex 35, por ejemplo tenían de Repsol.<br><br>
Luego tenemos a los tiburones, que son aquellos fondos y bancos que intentan pescar aprovechando que tienen información privilegiada y normalmente manejan mucha pasta que proviene de pequeños inversionistas, de fondos de inversión o de fondos de pensiones, por ejemplo. Básicamente es lo que hace tu banco con el dinero mientras tú lo tienes parado.<br><br>
Por último, estamos nosotros, los pececillos. Estamos para ser devorados por los dos anteriores, aunque se trata de intentar que esto no pase. No lo he dicho, pero este mercado funciona de modo que, si compras una acción, “alguien” tiene que vendértela porque las acciones son los que son, a no ser que una empresa decida una ampliación de capital o haga un split (esto lo veremos más adelante).<br><br>
Otro actor en los mercados es tu broker. Actúa como intermediario para que tú puedas entrar al mercado. Además de estos actores, tenemos los reguladores, en España la CNMC, los bancos centrales, en EEUU, la reserva federal... Todos ellos pueden cambiar una sesión de bolsa con solo hablar, por ello también es importante que sepas cuando se publica, por ejemplo, el índice de desempleo de EEUU o los grandes datos macroeconómicos del país en cuestión... Una locura, ¿no? Bueno, recuerda que ganar dinero no es tan fácil como te lo pintan...
`
},
{ 
  'titulo': '3. Bolsa Nasdaq, bolsa NYSE y los índices',
  'texto':`
  Dijimos que el Nasdaq es uno de los mercados que más vamos a usar en el día a día, ya que gran parte de nuestra estrategia se centra en las acciones tecnológicas. El Nasdaq es la bolsa de valores electrónica automatizada más grande de EEUU. Tiene más volumen de intercambio por hora que cualquier otra bolsa de valores en el mundo. Lista a más de 7.000 acciones de pequeña y mediana capitalización. Se caracteriza por comprender las empresas de alta tecnología en electrónica, informática, telecomunicaciones, biotecnología, etc.<br><br>
  El NYSE (New York Stock Exchange) es la bolsa de valores de Nueva York. Seguramente hayas visto la famosa imagen del toro y el oso de la calle Wall Street en Manhattan, pues bien, esa es la bolsa más grande y antigua (1792) de los EEUU.<br><br>
  Una forma de representar las empresas que componen una bolsa es mediante los índices. Uno que seguro que conoces es el Ibex 35. Este índice no es más que la agrupación de las 35 mayores empresas de España, aunque esto como tal no es verdad, ya que por ejemplo Mercadona o Corte Inglés no están en el índice y deberían estar. Esto es así porque para estar en un índice, el primero que debe querer es dicha empresa, además de cumplir una serie de requisitos que luego veremos. Los índices van cambiando cada cierto tiempo. Por ejemplo, en España recientemente han entrado PharmaMar o Solaria y mientras ha salido Más Móvil, así que lo que van haciendo es una representación lo mejor posible de lo que se quiere representar. 
  
  Por esto, es complicado mejorar el rendimiento de un índice, y también por esto, un índice nunca caerá, ya que si una empresa quebrara la quitan y ponen otra, curioso, ¿verdad?<br><br>
  En la bolsa NYSE hay dos índices que debes conocer: el S&P500 y el Dow Jones 30. Normalmente ya te habrás dado cuenta de que el número del índice indica el número de empresas que lo componen . El índice S&P500 (Standard & Poors) considera a las 500 grandes empresas que cotizan en la bolsa de NYSE o NASDAQ. Si hay un índice a nivel mundial, está claro que el SyP es el índice de los índices.<br><br>
  Curiosidad<br><br>
  Es posible que una acción esté en varios índices a la vez, busca y verás que Apple está en el Dow Jones, en el Nasdaq y también en el S&P500.<br><br>
  El índice más importante del Nasdaq se llama Nasdaq 100 e incluye 100 de las mayores compañías no financieras que cotizan en el mercado de valores Nasdaq. Este índice recoge las compañías que han mostrado un mayor crecimiento en los principales sectores, todas deben tener una capitalización de mercado de al menos 500 millones de dólares y un volumen diario medio de negociación de al menos 100.000 acciones.<br><br>
  Existe la posibilidad de que compres todas las acciones de un índice, algo que podrás hacer si tienes mucho dinero, pero también puedes invertir en todo el índice a través de un instrumento llamado ETF, que ya veremos.
`
},
{ 
  'titulo': '4. Horarios de la bolsa y premarket',
  'texto':`
  Es importante que conozcas algunos de los horarios y lo que pasa en ellos para que puedas evitar errores o aprovechar oportunidades. Seguramente sepas que el mercado americano abre a las 15:30 horas y cierra a las 22:00 horas (en ambos casos hablo de hora de España).<br><br>
  El mayor volumen, concepto clave que veremos en el siguiente capítulo X TRADINGVIEW se da en la apertura. Eso quiere decir que todo el mundo está preparado para comprar y vender como si no hubiera un mañana. El número de operaciones antes de las 22h, sobre las 21h suele ir aumentando, pero en mucha menor medida que la apertura.<br><br>
  Desde las 10:00h, hora de España, comienza el premarket de las acciones. Durante esta hora y hasta el mediodía que abre, están operando otras zonas del mundo (Europa y Asia básicamente) que no están en EEUU, ya que ellos como te imaginarás están en la cama. Por tanto y debido a que nuestra cuenta básica de Degiro no nos lo permite, nosotros no hacemos nada, además en mi caso a esa hora yo estoy a tope de trabajo y tampoco podría operar. Si no tienes nada que hacer puedes operar otros mercados como el IBEX (jajajja, no seré yo quien te lo recomiende, aunque algo de renovables suelo llevar) o lo que, si te recomiendo, el DAX alemán, pero eso por ahora y a este nivel está fuera de nuestra estrategia.<br><br>
  Quiero comentarte también que tras el cierre de las 22h hay un postmarket hasta la madrugada que no nos sirve para nada, salvo para ver reacciones de presentaciones de resultados de empresas que suelen hacerse justo al cerrar el mercado.
`
},
{ 
  'titulo': '5. Valores fundamentales de una empresa',
  'texto':`
  Vamos a recordad una tabla que vimos de la web Investing.com en el capítulo Degiro. Os recuerdo que en la columna central nos indicaba la capitalización de mercado que para el ejemplo que vimos de Apple era de 2.296 Billones. También vimos dos cosas más: al multiplicar las acciones en circulación (16.788 millones) por el último precio de cierre (137,18$) nos salía ese valor de capitalización. Pues ahora vamos a ver otros conceptos interesantes.<br><br>
  <center><img class="img" width="800px" src="assets/lecciones/L2-1.png"></center><br><br>
  La capitalización de mercado nos sirve para ver el tamaño o el valor de una empresa y poder compararlo con otras. Por ejemplo, aquí te pongo las 10 mayores capitalizaciones a nivel mundial, es decir, las 10 empresas más valiosas del mundo:<br><br>
  <ul>
    <li>1. Apple: 2,3 MB</li>
    <li>2. Microsoft: 1,83 MB</li>
    <li>3. Amazon: 1,69 MB</li>
    <li>4. Alphabet (Google): 1,41 MB 5. Tesla: 0,81 MB</li>
    <li>6. Facebook: 0,76 MB</li>
    <li>7. Berkshire: 0,55 MB</li>
    <li>8. Visa: 0,46 MB</li>
    <li>9. Johson & Johson: 0,43 MB 10. JM Morgan: 0,42 MB</li>
</ul>
Nota: cada empresa tiene una abreviatura llamado ticker, en de Apple es AAPL y el de Tesla es TSLA.<br><br>
Si te das cuenta, los seis primeros son empresas de base tecnológica. Para encontrar el primer banco tenemos que ir a la posición décima. Por curiosidad, si conoces a Berkshire te cuento que es el fondo de inversión de Warren Buffett, un personaje muy importante en el mundo de las inversiones que tienes que leer sí o sí.<br><br>
Por cierto, vamos a ver algunos parámetros más de esta imagen, no todos ya que algunos de ellos los vamos a englobar en el capítulo de análisis técnico que se llama Capítulo TRADINGVIEW.
`
},
  
],
    'url_video': null,
    'cuestion': 'Indica la caracteristica principal de esta lección: ',
  },
  {
    'cartera': 3,
    'nombre': 'Cartera 2',
    'tipo': 'estrategia',
    'riesgo': '0',
    'intro': `<h3>Nuevo aprendizaje en esta primera cartera!</h3>
    ¿Pongo uno o dos huevos en la cesta? Bueno, pregúntate si tu aversión al riesgo es alta o baja. Si no te va la marcha y te afecta que tu cartera baje o suba en altos porcentajes, eres un inversor conservador. <br><br>En cambio, si duermes a pata suelta (aunque ese día tengas 1.000€ menos en tu cartera), eres joven, ambicioso y piensas que vida no hay más que una, entonces puedes ser un inversor riesgo. Ojo, no estoy diciendo que esto sea mejor, solo que no todo el mundo es capaz de afrontar los riesgos de igual forma.<br><br>En esta cartera introducimos por primera vez el término de Libertad Financiera, eso al que todos aspiramos y solo unos pocos consiguen :)
  `,
    'estrategia': `
    `,
    'temario': 
    [ 
      { 
          'titulo': 'Estrategia',
          'texto':`
          <p align="justify">En el mundo de la inversión, una gran parte del éxito está en tu cabeza. Por tanto, si en esta etapa de tu vida no estás bien contigo mismo (o con tu entorno), es momento de que lo soluciones antes de invertir nada de dinero. Es importante que leas mucho sobre la psicología de la inversión puesto que, esta habilidad, te dará muchas más operaciones ganadoras en tu forma de invertir. Recuerda, disponemos de varias carteras de inversión (desde más conservadoras a más arriesgadas) donde puedas elegir aquella/s con la que te sientas más cómodo.</p>
           `
      },
      { 
        'titulo': 'Nuestro método',
        'texto':`
        <p align="justify">Volvamos a nuestro camino y piensa que, gracias a las primeras dos etapas (trabajo y ahorro), consigues un nuevo huevo. ¿Qué hago ahora? Pues bien, En función de la evolución de tu cartera, vamos a ir balanceando las nuevas aportaciones. De este modo, si una parte sube más, invierto en la otra para llegar siempre al porcentaje inicialmente planificado.</p>
        <p align="justify">Un ejemplo: si las acciones y criptomonedas suben mucho, pero (por el contrario) no lo hacen tanto los fondos, vamos a poner el nuevo huevo en esta cesta de modo que queden bien repartidas.</p>
        <p align="justify">En esta etapa vamos a permanecer hasta que estemos en disposición de entrar en nuevos productos (que comentaremos más adelante). No tengas prisa por saltarte casillas, todo llegará. Tu siguiente objetivo está en tu hoja de ruta, que no es más que tu Plan de Inversión. Quizás este sea que en el Año 4 adquieras tu primer piso de alquiler (o quizás no).</p>
        <p align="justify">No copies planes, solo inspírate en otros a los que les haya funcionado, pero recuerda: tu plan es único y especial a tu tipo de vida y personalidad.</p>
    
      `
      },
      { 
        'titulo': 'Libertad Financiera',
        'texto':`
        <p align="justify">Atención, porque llegamos a uno de nuestros objetivos que estará siempre en la parte superior de nuestro Plan de Inversión: la Libertad Financiera. Este término se está poniendo muy de moda y, simplemente, trata de describir a aquellas personas que (de un modo u otro) son libres para poder dedicar tiempo a su familia, amigos o a ellos mismos, en el momento que lo deseen y no cuando tocan vacaciones o te lo permita tu jefe (por ejemplo). Fíjate que el término tiempo es la clave.</p>
        <p align="justify">Uno de los errores típicos es pensar que eres libre para poder aumentar tus gastos de forma continuada. Hay dos ideas básicas que tienes que marcarte: una, “haz que el dinero trabaje por ti” y dos, “si aumentas tus ingresos, que no lo hagan los gastos”. En este punto, hay que dejar muy claro la diferencia entre los activos y los pasivos en los bienes que tenemos. Si ese bien nos genera ingresos, es un activo, y si son salidas de dinero, es un pasivo.</p>
        <p align="justify">Un ejemplo: la casa donde vivo, que tiene una hipoteca, es un pasivo puesto que no me genera ingresos; sin embargo, un piso alquilado es un activo, ¿ves la diferencia?</p>
        <p align="justify">Por ahora me vale con que te quedes con el concepto, porque nuestro objetivo será comprar activos y los mínimos pasivos posibles. Ahora te preguntarás, ¿cómo compro un piso para alquilar si no tengo los 80.000€ que vale? Claro, necesitarás un pasivo (que es una hipoteca) pero, en ese caso para nuestro sistema, esa deuda la llamaremos deuda buena.</p><br>
    
        `
      }
    ],
    'url_video': 'https://www.youtube.com/embed/-CNwnsIXASU',
    'cuestion': 'Indica la caracteristica principal de esta lección: ',
    'stakingTitulo': 'STAKING 1 MES HBLOCKS',
    'stakingSubtitulo': 'Manten tus monedas al menos un mes',
    'stakingPorcentaje': '8 %',
    'stakingTiempo': '1 mes',
  },
  {
    'cartera': 4,
    'nombre': '',
    'tipo': 'blockchain',
    'riesgo': '0',
    'intro': '',
    'estrategia': '',
    'url_video': 'https://www.youtube.com/embed/BWeWkMOZzIE',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': '',
    'stakingSubtitulo': '',
    'stakingPorcentaje': '',
    'stakingTiempo': '',
    'temario': 
      [ 
        { 
          'titulo': '¿Qué es la minería de criptomonedas?',
          'texto':`
          <p align="justify">La minería es la piedra angular de criptomonedas como (por ejemplo) bitcoin, ether o monero. Los mineros se encargan de procesar bloques, los cuales están compuestos de transacciones realizadas por los usuarios. Estos bloques confirmados, a su vez, constituyen las cadenas de una red blockchain. Cada transacción debe ser aprobada por mineros,  por esto es que siempre que queremos retirar de un bot o enviarle criptos a alguien, nos pedirán que paguemos un porcentaje en fees (comisión). Este proceso brinda seguridad a la red al mismo tiempo que permite la generación de nuevas monedas.</p>
          <p align="justify">A este proceso se le conoce por el nombre de “minería”, solo que, en vez de tomar un pico y una carretilla para extraer valor de la tierra, usas programas y hardware de computación para extraer valor de la red. Cuando minas criptomonedas, pones el poder de procesamiento de tu hardware al servicio de una red (llámese Bitcoin, Ethereum, Litecoin, Monero, etc), puesto que necesitan el poder de tus equipos para confirmar que las transacciones de sus usuarios son válidas.</p>
          <p align="justify">Agregar nuevos bloques a la cadena te hace merecedor de una recompensa que consta de dos partes:</p>
          <p align="justify">1º Las comisiones pagadas por los usuarios que hicieron las transacciones que incluiste en el bloque.</p>
          <p align="justify">2º Las nuevas monedas que emite la red de forma programada.</p>
        `
      },
      { 
        'titulo': '¿Por qué es necesaria la minería?',
        'texto':`
        <p align="justify">La minería es la columna vertebral de gran parte de las criptomonedas. Por medio de este proceso, todos los datos de la red son transferidos y registrados en la blockchain. Debido a su estructura peer-to-peer, es posible que se procese dinero de manera descentralizada.</p>
        <p align="justify">La minería (conformada por nodos y mineros ubicados alrededor del mundo) hace posible que la blockchain “esté encendida” procesando datos sin tener que depender de un servidor central externo controlado por un intermediario. Un nodo es un ordenador conectado a otros ordenadores que sigue reglas y comparte información.</p>
        <p align="justify">Pero esto no es lo único que hace la minería. El proceso también resulta fundamental para garantizar la seguridad de las redes de criptomonedas. Gracias al trabajo de los mineros, se registran únicamente las transacciones que sean válidas, auditando la legitimidad de los datos. Por ende, protegen a la red ante posibles intentos de alteración de los datos registrados o de ataques para controlar su funcionamiento.</p>
      `
      },
      { 
        'titulo': '¿Cómo funciona la minería?',
        'texto':`
        <p align="justify">La minería de criptomonedas es el proceso mediante el cual se verifican transacciones comerciales de una red y se generan nuevas monedas. Esto se logra resolviendo problemas matemáticos (hash) con un  equipo informático de gran potencia (un ASIC, GPU o CPU) y software especializado. Este proceso es realizado bajo el concepto de  árbol de hash binario, que es el esquema con el que se añaden e identifican nuevos bloques de transacciones de la red.</p>
        <p align="justify">La blockchain se compone de múltiples bloques, los cuales escalan progresivamente hasta llegar a un elemento identificador único, llamado nodo raíz. El nodo raíz, combinado con otros datos como el hash del bloque anterior, la marca de tiempo, el objetivo de dificultad y el nonce, permiten que los mineros ejecuten la función hash para resolver el problema matemático que confirmará el bloque. Entonces, al procesar esta información por medio de un equipo especializado, los mineros pueden dar con el identificador de bloque (ID Block) que lo registrará de forma automática en la red.</p>
        <p align="justify">La función hash, de redes como Bitcoin (por ejemplo), está compuesta por una combinación alfanumérica de 64 caracteres. Las posibilidades de dar con la combinación correcta que libere los BTC y confirme las transacciones es realmente difícil. La situación se vuelve aún más complicada si hay miles de mineros tratando de conseguir dicha respuesta al mismo tiempo. Si se equivocan en las combinaciones o, peor aún, no llegan a la respuesta con la misma rapidez que otro minero, tienen que empezar de cero a minar otro bloque.</p>
        <p align="justify">La base de negocio de los mineros, se fundamenta en las recompensas por el poder computacional que prestaron y la electricidad usada para llevar a cabo este servicio.</p>
        
      `
      },
      { 
        'titulo': '¿Cómo un minero acredita un bloque de transacciones?',
        'texto':`
        <p align="justify">1º Los nodos mineros auditan las transacciones realizadas por los usuarios. Digamos que las criptomonedas que se están enviando no han sido utilizadas en otra transacción anteriormente.</p>
        <p align="justify">2º Los nodos mineros agrupan transacciones y bloques no confirmados.</p>
        <p align="justify">Por ejemplo: Todas las transacciones que no han sido confirmadas, esperan en la mempool para que sea seleccionadas.</p>
        <p align="justify">* Mempool: Es una memoria donde se almacenan de forma temporal las transacciones no confirmadas de los usuarios.</p>
        <p align="justify">3º Se agrega el hash del bloque anterior y otros datos al bloque no confirmado.</p>
        <p align="justify">4º El equipo especializado empieza a resolver el caso matemático para dar con un resultado.</p>
        <p align="justify">Por ejemplo: En la red Monero, puede hacerse con tarjetas gráficas (GPU) y en la red Bitcoin se utilizan dispositivos ASIC.</p>
        <p align="justify">5º El bloque se agrega a la red.</p>
      `
      }
    ]
  },
  {
    'cartera': 5,
    'nombre': '',
    'tipo': 'bonus',
    'riesgo': '0',
    'intro': '',
    'estrategia': '',
    'url_video': 'https://www.youtube.com/embed/XFBvHjthmbk',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': '',
    'stakingSubtitulo': '',
    'stakingPorcentaje': '',
    'stakingTiempo': '',
    'temario': 
      [ 
        { 
          'titulo': '¿Qué es un NFT?',
          'texto':`
          <p align="justify">Los tokens no fungibles (NFT por sus siglas en inglés "Non-fungible tokens") son activos criptográficos en una cadena de bloques con códigos de identificación únicos y metadatos que los distinguen entre sí. A diferencia de las criptomonedas, no se pueden comercializar ni intercambiar en equivalencia (porque no existen 2 NFT exactamente iguales).</p>
          <p align="justify">La construcción distinta de cada NFT tiene el potencial para varios casos de uso. Por ejemplo, son un vehículo ideal para representar digitalmente activos físicos como bienes inmuebles u obras de arte. Los NFT pueden eliminar intermediarios, simplificar transacciones y crear nuevos mercados.</p>
          <p align="justify">Algunas características de los NFT son: Los NFT son digitalmente únicos (no hay dos NFT iguales), Cada NFT debe tener un propietario y esto es de registro público y fácil de verificar para cualquier persona, o que los creadores pueden conservar los derechos de propiedad sobre su propio trabajo y reclamar regalías de reventa directamente.</p>
          <p align="justify">A modo de ejemplo, un NFT podría representar:</p>
          <p align="justify">- Arte digital: GIF, Coleccionables, Música, Vídeos...</p>
          <p align="justify">- Objetos del mundo real (esto es muy intersante por sus posibles aplicaciones): escrituras de un coche o vivienda, entradas para un evento del mundo real, facturas tokenizadas, documentos legales, firmas...</p>
          
        `
      },
      { 
        'titulo': 'Funcionamiento de los NFT',
        'texto':`
        <p align="justify">Los NFT funcionan a través de la tecnología blockchain o de cadena de bloques. Es la misma tecnología de las criptomonedas, que funcionan mediante una red de ordenadores descentralizada, con bloques o nodos enlazados y asegurados usando criptografía. Cada bloque enlaza a un bloque previo, así como una fecha y datos de transacciones, y por diseño son resistentes a la modificación de datos.</p>
        <p align="justify">A los NFT, se les asigna una especie de certificado digital de autenticidad, una serie de metadatos que no se van a poder modificar. En estos metadatos se garantiza su autenticidad, se registra el valor de partida y todas las adquisiciones o transacciones que se hayan hecho, y también a su autor.</p>
        <p align="justify">La escasez prevista de NFT es otro factor importante a tener en cuenta y depende del creador del mismo. Un creador puede tener la intención de hacer que cada NFT sea completamente único para crear escasez, o tener razones para producir varios miles de réplicas. Recuerde, esta información es toda pública.</p>
        <p align="justify">Para poder transferir estos activos, con operaciones de compra y venta, existen marketplace de NFT como pueden ser OpenSea, Rarible y SuperRare.</p>
              `
      },
      { 
        'titulo': 'Mintear (mint) o acuñar/crear un NFT ',
        'texto':`
        <p align="justify">Un NFT solo puede tener un propietario a la vez. La propiedad se administra a través del ID único y los metadatos que ningún otro token puede replicar. Los NFT se acuñan a través de contratos inteligentes que asignan la propiedad y gestionan la transferibilidad de los NFT. Cuando alguien crea o acuña un NFT, ejecuta código almacenado en contratos inteligentes que se ajustan a diferentes estándares, como ERC-721. Esta información se agrega a la cadena de bloques donde se administra el NFT. El proceso de acuñación, desde un alto nivel, tiene los siguientes pasos por los que pasa:</p>
        <p align="justify">- Creando un nuevo bloque</p>
        <p align="justify">- Validando información</p>
        <p align="justify">- Grabación de información en la cadena de bloques</p>    
      `
      },
      { 
        'titulo': 'Debes saber...',
        'texto':`
        <p align="justify">Si creas un NFT, debes saber que:</p>
        <p align="justify">- Puedes probar fácilmente que eres el creador.</p>
        <p align="justify">- Tú determinas la escasez.</p>
        <p align="justify">- Puedes ganar regalías cada vez que se vende.</p>
        <p align="justify">- Puede venderlo en cualquier mercado NFT o peer-to-peer. No estás encerrado en ninguna plataforma y no necesitas a nadie como intermediario.</p>
        <p align="justify">Mientras que, si eres propietario, debes saber que:</p>
        <p align="justify">- Puedes probar fácilmente que lo posees.</p>
        <p align="justify">- Nadie puede manipularlo de ninguna manera.</p>
        <p align="justify">- Puede venderlo y, en algunos casos, esto hará que el creador original obtenga regalías de reventa.</p>
        <p align="justify">- O bien, puede mantenerlo para siempre, descansando cómodamente sabiendo que su activo está asegurado por su wallet</p><br>        
      `
      }
    ]
  },
  {
    'cartera': 6,
    'nombre': 'Cartera 3',
    'tipo': 'criptomonedas',
    'riesgo': '7',
    'intro': 'Comenzamos con las carteras de nivel "INICIACIÓN". Seguro que tenías muchas ganas de llegar hasta aquí, por fín te mostramos como identificar las mejores inversiones en proyectos cripto.<br><br> Más tarde iremos subiendo de nivel, no te preocupes.',
    'estrategia': '',
    'temario': 
      [ 
        { 
          'titulo': 'Criptomonedas y tokens',
          'texto':`
          <p align="justify">A nivel general, las criptomonedas y los tokens son similares, pero son dos cosas diferentes: todas las criptomonedas son tokens, pero no todos los tokens se consideran criptomonedas. Muchos usuarios ni siquiera saben si están comprando tokens criptográficos o monedas criptográficas. A nivel básico, la diferencia clave entre ellos se basa simplemente en su utilidad.</p>
          <p align="justify">Bitcoin (BTC) fue la primera criptomoneda (o moneda criptográfica) y tiene características claras que la diferencian de un token. Entre ellas, por ejemplo, las criptomonedas operan en cadenas de bloques o pueden minarse.</p>
          <p align="justify">A diferencia de las criptomonedas, los tokens no tienen su propia cadena de bloques y operan en cadenas de bloques de criptomonedas ya existentes. Las transacciones de criptomonedas se manejan mediante blockchain, mientras que los tokens se basan en contratos inteligentes.</p>
          <p align="justify">Los contratos inteligentes son una serie de códigos que facilitan los intercambios entre dos usuarios. Cada cadena de bloques utiliza sus contratos inteligentes de números múltiples.</p>
        `
      },
      { 
        'titulo': '¿Qué es un protocolo blockchain?',
        'texto':`
        <p align="justify">Los protocolos son un componente de la tecnología de cadena de bloques que ayuda a compartir información a través de las redes de las criptomonedas de manera segura. También se usan para habilitar los nodos de los servidores para intercambiar información en la red del encadenado de bloques.</p>
        <p align="justify">Un protocolo de cadena de bloques puede establecer una amplia gama de reglas, como la interfaz de la cadena de bloques, la interacción de las computadoras participantes, el tipo de datos que deben compartirse, incentivos para que los desarrolladores participen en la red, etc.</p>
        <p align="justify">Los protocolos de blockchain hacen posibles las transacciones con criptomonedas. Todos los protocolos de la industria tienen como objetivo lograr o mantener los cuatro principios principales de la criptomoneda: descentralización, consistencia, seguridad y escalabilidad.</p>
        
      `
      },
      { 
        'titulo': '¿Qué es el Staking?',
        'texto':`
        <p align="justify">Es el proceso mediante el cual, compras criptomonedas y las mantienes bloqueadas en una wallet con la finalidad de recibir ganancias o recompensas. </p>
        <p align="justify">¿Qué protocolos intervienen en el Staking?</p>
        <p align="justify">La prueba de trabajo (PoW por sus siglas en inglés, "Proof-of-work") y la prueba de participación (PoS por sus siglas en inglés, "proof-of-stake") son mecanismos de consenso, o algoritmos, que permiten que las cadenas de bloques funcionen de forma segura. Estos mecanismos de consenso mantienen la seguridad de las cadenas de bloques al permitir que solo los usuarios auténticos añadan nuevas transacciones.</p>
        <p align="justify">Funcionan haciendo que los participantes potenciales demuestren que han dedicado algún recurso, como dinero o energía, a la cadena de bloques. La principal diferencia entre la PoW y la PoS es cómo eligen quién puede agregar transacciones a la cadena.</p>
      `
      },
      { 
        'titulo': 'PoW vs. PoS',
        'texto':`
        <p align="justify">En general, podemos destacar las siguientes diferencias entre ellos:</p>
        <p align="justify">- Para la creación de bloques, el PoW necesita mineros; el PoS, necesita validadores.</p>
        <p align="justify">- Respecto a los recursos necesarios para conseguir bloques, el PoW necesita energía; el PoS, necesita criptomonedas o tokens.</p>
        <p align="justify">- El coste de participación, para el PoW se necesitan equipos y energía para hacerlos funcionar; para el PoS, el coste de adquirir las criptomonedas o tokens.</p>
        <p align="justify">- Fortalezas, en el Pow el coste de los equipos y la energía proporcionan una sólida seguridad; en el PoS, la eficiencia energética permite una mayor escalabilidad.</p>
        <p align="justify">- Debilidades, para el PoW es el enorme gasto de energía; para el PoS, que se puede comprar el control de la red.</p>
      `
      },
      { 
        'titulo': 'Diferencias entre APR y APY cuando realizamos Staking de un token',
        'texto':`
        <p align="justify">El APR (Porcentaje de interés anual o "Annual Percentage Rate") va a reflejar el coste real de un préstamo u otra deuda.</p>
        <p align="justify">El APY (Porcentaje de rentabilidad anual o "Annual Percentage Yield") va a reflejar el rendimiento real de la inversión.</p>
        <p align="justify">El APY tiene en cuenta el interés compuesto mientras que el APR no lo hace. Como inversores, nos interesa conocer el APY cuando vayamos a hacer Staking de dias, meses o años.</p>
      `
      },
      { 
        'titulo': 'Tipos de Staking',
        'texto':`
        <p align="justify">Entre los diferentes tipos de staking que hay, podemos destacar los siguientes:</p>
        <p align="justify">- Grupos de Staking: Son grupos de inversores que se unen para aumentar sus posibilidades como validadores de bloques y, así, tener mayor poder de Staking. En el momento de recibir recompensas, estas se dividen de forma proporcional al aporte que hizo cada inversor.</p>
        <p align="justify">- Cold Staking: Se llama a la forma de hacer Staking con una wallet sin conexión permanente a Internet y es ideal para los grandes tenedores de tokens.</p>
        <p align="justify">- Proveedores de Staking: Son plataformas que  ofrecen un servicio dedicado para hacer Staking. El retorno de rentabilidad en este tipo de Staking dependerá mucho de las comisiones que estos cobren.</p>
        <p align="justify">Con repecto a la duración, podemos encontrar diferentes periodos (días o meses). Normalmente un mayor número de días puede aportar más APY, pero no siempre es así.</p>
        <p align="justify">Más que el tiempo de duración, debe tenerse en cuenta (y es importante) que nos encontramos con dos tipos de bloqueos al hacer Staking:</p>
        <p align="justify">- Con bloqueo Flexible: Te permite reembolsar tus activos en cualquier momento.</p>
        <p align="justify">- Bloqueado: Tus activos estarán bloqueados por un tiempo determinado. Aún así, normalmente, podrás reembolsar tus activos en cualquier momento antes de que finalice el proceso de Staking, pero no recibirás ningún interés generado durante el tiempo que hayas participado.</p>
        <p align="justify">Ojo, no confundir el staking con el vesting.</p>
      `
      }
    ],
    'url_video': 'https://www.youtube.com/embed/FowzLVDT7XY',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': 'STAKING 3 MESES HBLOCKS',
    'stakingSubtitulo': 'Manten tus monedas al menos 3 meses',
    'stakingPorcentaje': '12 %',
    'stakingTiempo': '3 meses',
  },
  {
    'cartera': 7,
    'nombre': 'Conceptos básicos',
    'tipo': 'blockchain',
    'riesgo': '0',
    'intro': 'Para entender futuras casillas es fundamental que te familiarices con los siguientes conceptos básicos.',
    'estrategia': '',
    'url_video': 'https://www.youtube.com/embed/DjBoKM-P2So',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'temario': 
    [ 
      { 
        'titulo': 'Interés Compuesto',
        'texto':`
        <p align="justify">Comenzaremos con un concepto que quizás ya conozcas: el interés compuesto. Este va a funcionar como lo hace una bola de nieve que, al principio cuando se crea, es pequeña pero que, a medida que va bajando la montaña, se hace cada vez más grande puesto que va recogiendo más nieve que, a su vez, la incorpora a la bola que ya tenía.</p>
        <p align="justify">La gracia de todo esto es que se van a reinvertir los rendimientos que vayamos obteniendo en la misma inversión y de manera continuada. La clave está en ser muy, muy, pero que muy constantes y pacientes. ¿Por qué? Muy fácil porque, como verás en la siguiente gráfica, al principio coges poca nieve, pero a medida que pasan los años, el efecto es exponencial.</p>
        <p align="justify">Matemáticamente el interés compuesto se rige por la siguiente fórmula:</p>
        <p align="justify">Capital x (1 + interés)<sup>años</sup></p>
        <p align="justify">Sólo tenemos 3 variables: el dinero que invertimos al principio, el interés que genera nuestra inversión y los años que esperamos. De las tres, la más importante sin duda son los años porque está elevado; es decir, aparece como exponencial en la fórmula:</p>
        <center><img class="img" width="600px" src="assets/lecciones/L7-1.png"></center>
        <p align="justify">Pongamos un ejemplo para entenderlo. El primer día que haces tu aportación comienzas con una pequeñita bola de nieve, imagínate 1.000€. Si suponemos que se va a revalorizar un 10% al año (interés = 0,1) y vamos a estar 5 años, nuestro valor al final será de 1.611€. A los 10 años esperaríamos duplicar, pero no es así, nos quedamos en 2.594€, ahora bien, a los 20 años que sería duplicar el tiempo anterior, aumenta exponencialmente hasta 6.727€, ¡¡¡guau!!!</p>
        <p align="justify">Por tanto, no tiene sentido que vayamos a largo plazo en este sistema esperando solo 10 años, al menos tenemos que hacerlo 20 años, ya que es donde el efecto exponencial es mayor.</p>
        
        `
      },
      { 
        'titulo': 'Inflación',
        'texto':`
        <p align="justify">Es el aumento de los precios de los bienes y servicios que compramos. Así, por ejemplo, si hubieramos alcanzado una inflación del 4%, eso quiere decir que comprar en el súper, llenar el tanque de gasoil o cualquier otro bien te costará un 4% más que si lo hubieras comprado en ese mismo mes del año pasado.</p>
        <p align="justify">la solución no es que los gobiernos generen más billetes, porque sube la inflación y, además, si no aumentan nuestros ingresos con la inflación cada vez podremos comprar menos cosas con el mismo dinero. Por todo ello, es inevitable que invirtamos nuestro capital en algo que al menos nos ofrezca un porcentaje extra a lo que sube el precio de los bienes. Además, si esa inversión la hacemos en un bien inmobiliario, vamos a combatir muy bien la inflación ya que ese inmueble también va a subir de precio y no perdemos valor a la vez que tenemos ingresos pasivos.</p>
      `
      },
      { 
        'titulo': '¿Cuánto dinero es necesario ahorrar?',
        'texto':`
        <p align="justify">Llamaremos capacidad de ahorro (CA) a la cantidad de dinero mensual planificada del total de tus ingresos. Si obtienes 1.000€/mes de ingresos y tu ahorro es 200€/mes, diremos que tu CA = 20%.</p>
        <p align="justify">Es importante distinguir que la CA no es el dinero que queda una vez pagas tus gastos y deudas. Si lo hiciéramos así muchos meses este valor sería cero. Por tanto, tienes que considerarte un acreedor de tus ingresos y pedir pagarte a ti mismo.</p>
        <p align="justify">Por ejemplo, fíjate en la siguiente situación: Este mes he ganado 1.500€, pago todos mis gastos fijos y deudas y me quedan 700€. Según mi presupuesto tengo disponible 300€ y me gasto 280€. No tengo ningún gasto extra salvo una actividad extraescolar que considero interesante para mi hijo y que cuesta 50€. Mi tasa de ahorro es de 370€, superior a los 200€ del plan de ahorro.</p>
        <p align="justify">Por tanto, solo ten caprichos o extras cuando tu plan se esté cumpliendo. Intenta evitar gastos superfluos y date algunos caprichos cuando todo vaya sobre ruedas. Todo esto no quiere decir que tengamos imprevistos, todos los tenemos, y para ello es necesario que dispongas de un colchón financiero, del que más tarde te hablaremos.</p>
          
      `
      },
      { 
        'titulo': 'Plan de Inversión',
        'texto':`
        <p align="justify">Una vez vistos los factores externos que nos afectan como inversores pasaremos a la etapa de planificación. Es hora de realizar en Excel tu plan de inversión con un horizonte de al menos 10 años vista.</p>
        <p align="justify">Escribe en una hoja de cálculo la rentabilidad objetivo de tus productos financieros. Te pongo un ejemplo:</p>
        <p align="justify">- Ahorro mensual: 400€</p>
        <p align="justify">- Trading bolsa: 250€/mes</p>
        <p align="justify">- Alquiler piso: 150€/mes</p>
        <p align="justify">- Dividendos acciones: 75€/mes</p>
        <p align="justify">En este ejemplo obtenemos 875€/mes, todo va a depender de tu capacidad de ahorro. Recuerda que cada vez que generes nuevos ingresos pasivos deberán ir de nuevo a inversión para llegar al nuevo capital y comprar un nuevo piso. Haz estos pasos hasta que tengas unas rentas mensuales que te permitan una libertad financiera que te sea cómoda y sostenible.</p>
        <p align="justify">Pon el foco en cómo conseguir llegar a esas rentabilidades. Pivota si algo falla o no termina de arrancar, prueba nuevas formas de ingresos, experimenta, el mundo es tuyo y te ofrece infinidad de opciones. Eso sí, evalúa los riesgos y haz siempre operaciones con las que puedas dormir por la noche.</p>
        <p align="justify">Espero que todo esto al menos sirva para que te pongas a planificar en un Excel todos estos conceptos y puedas ver las distintas opciones que se te ofrecen.</p><br>        
      `
      }
    ]
  },
  {
    'cartera': 8,
    'nombre': 'Cartera 4',
    'tipo': 'indexados',
    'riesgo': '5',
    'intro': 'La cartera de fondos indexados es de riesgo medio y se trata de una estrategia pasiva. Para muchos es la mejor forma de invertir puesto que muy pocos consiguen batir al mercado. Un datos estadístico real es que el índice S&P500 ha subido un 8% anual de media en los últimos 50 años, ¿genial no? Como ves en esta estrategia nos indexamos a un ínidce.',
    'estrategia': '',
    'temario': 
    [ 
      { 
        'titulo': 'Estrategia',
        'texto':`
        En los fondos de inversión se replica un índice bursátil de forma automática, no existe un gestor haciendo los movimientos activamente, se hace de forma pasiva.<br><br>
        Los fondos indexados tienen la gran ventajas de ofrecer comisiones muy bajas a los inversores. Además, tienen la gran ventaja de que no es necesario tener muchos conocimientos y por tanto es ideal para los que estáis empezando. Por último, se trata de inversiones muy diversificadas porque dentro del índice tienes empresas de muchos tipos, negocios y ubicaciones.<br><br>
        En la casilla de fundamentales ya hablamos sobre las bolsas. Dijimos que una forma de representar las empresas que componen una bolsa es mediante los índices. El índice más seguido e importante del mundo es el S&P500. Este índice no es más que la agrupación de las 500 mayores y mejores empresas que representan la economía americana (80%) y de otras partes del mundo.<br><br>

      `
      },
      { 
        'titulo': '¿Cómo se puede invertir en fondos indexados?',
        'texto':`
        Te recomiendo que lo hagas a través de MyInvestor, Indexa Capital o similares. Se trata de unas plataformas que te permiten acceder a los distintos fondos de índices del mundo. Tienes dos opciones: elegir tu los fondos o a través de un roboadvisor. En ambos las comisiones de gestión son muy pequeñas.<br><br>
        La otra opción es delegar la inversión en las carteras automatizadas de un roboadvisor. En general estas entidades, según tu perfil de inversión y patrimonio, crean una cartera acorde a tus necesidades.
      `
      },
      { 
        'titulo': 'Aportaciones periódicas',
        'texto':`
        En esta estrategia vamos a hacer aportaciones dinerarias al fondo cada cierto tiempo. Al invertir poco a poco y de forma invariable, puede que inviertas en el peor momento, pero también en el mejor. Según mi experiencia esta es la mejor forma de invertir en fondos indexados.<br><br>
      `
      },
      { 
        'titulo': 'Pasos a seguir',
        'texto':`
        Nota: si eliges un roboadvisor solo es necesario el primer paso :)<br><br>
        1. Define tu perfil de riesgo<br><br>
        Tu cartera deberá tener un porcentaje de Renta Variable (Acciones) y de Renta Fija (Bonos). Siendo lo primero de mayor riesgo, pero también con mayor posibilidad de rentabilidad. Si eres joven y tienes buena adversión al riesgo aumenta el primer porcentaje todo lo posible.<br><br>
        2. Elegir los fondos de la cartera en tu bróker<br><br>
        En renta variable, te recomiendo que el fondo con más peso sea el S&P500 a través de un Amundi, Vanguard, etc...(gestores). También deberías llevar un fondo a nivel mundial como el MSCI World (son 1600 empresas medianas y grandes de todo el mundo)... Esos dos son casi obligatorios, también es recomendable meter un poco (10% máximo) de alguno de entre estos: emergentes (MSCI Emerging Markets), Europa o Japón... Luego por último la parte de renta fija (GBI EMU Investment Grade de JP Morgan por ejemplo para evitar riesdo de divisa) que te haya salido en el anterior apartado.<br><br>
        3. Ingresa fondos en tu cuenta<br><br>
        Ya solo queda hacer una trasnferencia al bróker elegido y añadir los fondos y los porcentajes que antes has obtenido.<br><br>
        4. Rebalancear tus fondos<br><br>
        Es posible que unos vayan mejor que otros y es por ello que deberás ir haciendo que los % se mantengan como la estrategia inicial que definiste. Para ello ve aportando en los más rezagados de modo que se rebalancee tu cartera. 
      `
      }
    ],
    'url_video': 'https://www.youtube.com/embed/B0qinzyjiJE',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': 'COMPRA NFTs',
    'stakingSubtitulo': 'Consigue un NFT plata o bronce por 2$',
    'stakingPorcentaje': 'NFT',
    'stakingTiempo': 'Plata / Bronce',
  },
  {
    'cartera': 9,
    'nombre': 'Cartera 5', 
    'tipo': 'dividendos',
    'riesgo': '3',
    'intro': 'Esta cartera de dividendos es una estrategia pasiva, apta para todos los inversores y ganadora a largo plazo. De hecho, si escoges bien las empresas me aventuraría a decir que es mejor para la mayoría de personas que otras alternativas de inversión sencillas como podrían ser la inversión en fondos indexados o la inversión en fondos gestionados.<br><br>A nivel de rentabilidad dependerá de tu elección de las empresas. Si las que acabas comprando incrementan el dividendo durante muchos años a buen ritmo es muy posible que mejores la rentabilidad de estas otras estrategias, pero lo que marca realmente la diferencia es el aspecto psicológico: el inversor en dividendos no estará sujeto al nerviosismo de los vaivenes de la bolsa o, por lo menos, lo estará menos. No es lo mismo que tu cartera caiga un 50% en una estrategia de fondos de acumulación, donde lo único que tienes es la valoración del fondo, que lo haga en una cartera de dividendos, donde la renta mensual se debería mantener estable e incluso incrementarse.',
    'estrategia': '',
    'temario': 
      [ 
        { 
          'titulo': 'Estrategia',
          'texto':`
          La base de la estrategia son las empresas que reparten dividendos crecientes. Los dividendos no son más que la parte de los beneficios que la empresa decide pagar a sus accionistas. En general, si una empresa incrementa sus dividendos durante muchos años, lo normal es que sus beneficios también sean mayores cada año, pero tendrás que asegurarte de que realmente es así y que no te está pagando un dinero que no tiene, comprometiendo sus resultados futuros. Es decir, tendrás que asegurarte de que cada año te paga más dividendos porque cada año gana más. Tampoco nos sirve que simplemente los dividendos crezcan. Tienen que hacerlo por encima de la inflación porque, si no, aunque cada año te paguen más la inflación hará que puedas comprar menos cosas y tu poder adquisitivo se reducirá.<br><br>
          En esta estrategia es clave elegir empresas de calidad ya que vamos a estar muchos años con ellas, en muchos casos, incluso toda la vida. Vas a tener que poner en práctica todos los conceptos que ya vimos sobre los fundamentales de las empresas. EEUU es la cuna de los dividendos donde la maquinaria de repartición de los benéficos entre los accionistas dista desde hace más de un siglo. Por esta cultura del dividendo existen multitud de empresas que reparten dólares americanos a sus accionistas. Dada la gran cantidad de empresas era necesario crear listas para diferenciar a unas de las otras en cuestión de historial de dividendos.
        `
      },
      { 
        'titulo': 'Los dividendos',
        'texto':`
        Cuando compres acciones de una empresa te conviertes en accionista de esa empresa. Dicho de un modo que te gustará: vas a representar una pequeña porción de la empresa. Pero no te preocupes, no vas a tener que ir a la junta de accionistas ni nada de eso, salvo que tengas un porcentaje tan alto que así lo requiera.<br><br>
        Los dividendos son la parte de los beneficios que una empresa decide pagar a sus accionistas. Para entender bien el concepto vamos a hacer un repaso de lo que ya vimos sobre las cuentas de cualquier empresa que cotiza en bolsa. Hay dos apartados que debemos analizar de una empresa para ver su salud financiera, la cuenta de ganancias y pérdidas y el balance de situación (los veremos en detalle).<br><br>
        EEUU es la cuna de los dividendos donde la maquinaria de repartición de los benéficos entre los accionistas dista desde hace más de un siglo. Por esta cultura del dividendo existen multitud de empresas que reparten dólares americanos a sus accionistas.
        Dada la gran cantidad de empresas era necesario crear listas para diferenciar a unas de las otras en cuestión de historial de dividendos. Distinguimos dos tipos:<br><br>
        • Aristócratas: 25 años en el S$P500 y >25 años de incremento del dividendo cada año.<br><br>
        • Dividend Kings: >50 años, los reyes del mambo :)<br><br>
        Tenemos que buscar empresas que repartan dividendos crecientes. Eso quiere decir que cada año paguen más dinero por cada acción que el año anterior. Y la buena noticia es que tenemos muchas empresas que así lo hacen.<br><br>
        Como esta labor no es sencilla hay páginas webs que ya han hecho el trabajo por tí, aquí te pongo un listado sacado de una de ellas:<br><br>
        <center><img class="img" width="800px" src="assets/lecciones/L9-3.png"></center><br><br>
      `},
      { 
        'titulo': 'Aristócratas del dividendo',
        'texto':`
        Los aristócratas de dividendo es un grupo reducido de empresas cuya distinción principal es el reparto sostenido y continuado de dividendos crecientes durante un largo periodo de tiempo.<br><br>
        Para poder mantener tantos años la distribución creciente del dividendo es necesario que sean empresas con una buena solidez financiera para soportar periodos difíciles y que genere beneficios sostenibles y suficientes para poder distribuir dividendos a sus accionistas.<br><br>
        Por lo general las compañías estables y maduras suelen tomar este tipo de decisiones, por lo tanto son un tipo de empresa muy previsible en el tiempo. Cierto que al ser un negocio maduro su revalorización no sea su punto fuerte (aunque como se verá más adelante si aumentan de valor), pero por contra su estabilidad y teórica previsibilidad permite minimizar riesgos.<br><br>
        <center><img class="img" width="800px" src="assets/lecciones/L9-2.png"></center><br><br>
      `},
      { 
        'titulo': 'Dividendo por acción',
        'texto':`
        Recordemos que ya vimos que hay empresas que dan más dividendo que otras, lo vimos en el capítulo de fundamentales, ¡cuánto tiempo hace! El dividendo por acción o DPA nos dice lo que nos darán al año frente a lo que tenemos que pagar para tener una acción de esa empresa. Por tanto será importante cuando entrar a comprarla, ya que si lo haces muy caro tu rentabilidad será muy baja.<br><br>
        Por ejemplo, ahora mismo Coca Cola (KO) cotiza a unos 55$ y paga al año 1,64$, por tanto tendremos un 2,98%. Sin embargo si conseguiste comprarla en la última bajada que tuvo a los 50$, hubieras conseguido un magnífico 3,3%. La buena noticia es que cada año este porcentaje es posible que vaya subiendo ya que mejorará el dividendo.
      `},
      { 
        'titulo': 'Payout',
        'texto':`
        Para obtener el Payout debemos dividir el dividendo que nos dan por acción (DPA) partido del beneficio por acción (BPA).<br><br> 
        Es un valor muy importante para que el modelo sea sostenible a largo plazo, ya que no nos interesa que del total de beneficio por acción (BPA, barras azules de la gráfica siguiente) no nos den un porcentaje muy alto (barras naranjas).<br><br>
        <center><img class="img" width="800px" src="assets/lecciones/L9-1.png"></center><br><br>
        En esta gráfica te muestro los valores de los últimos años de la empresa 3M. Como puedes ver el DPA supone algo más de la mitad del BPA. Si este valor subiera mucho podría poner en riesgo de ofrecer dividendos crecientes.
      `},
      { 
        'titulo': 'Ventajas e inconvenientes',
        'texto':`
        Uno de los inconvenientes de esta estrategia son los impuestos. Por ejemplo, en España la retención en destino es del 19% si los dividendos no superan los 6.000 euros, el 21% si los dividendos recibidos se encuentran entre 6.000 y 50.000 euros y el 23% a partir de los 50.000 euros.<br><br>
        Una de los principales ventajas de esta estrategia es el efecto del interés compuesto o bola de nieve. Cuando pasan 3-4 años empiezas a ver como la bola se hace más y más grande.
      `}
    ],
    'url_video': 'https://www.youtube.com/embed/zDtIB6xtZAA',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': 'COMPRA NFTs DORADOS',
    'stakingSubtitulo': 'Consigue un NFT Dorado por 5$',
    'stakingPorcentaje': 'NFT',
    'stakingTiempo': 'Dorado',
  },

  {
    'cartera': 11,
    'nombre': 'Cartera 6', 
    'tipo': 'emergentes',
    'riesgo': '7',
    'intro': 'En esta lección trabajaremos con las empresas emergentes o start-ups, son aquellas que presentan grandes posibilidades de desarrollo y crecimiento en su sector. Y, por regla general, salen al mercado para captar recursos financieros y aumentar la escalabilidad de su negocio.',
    'estrategia': '',
    'temario': 
      [ 
        { 
          'titulo': '¿Qué es una Start-up?',
          'texto':`
          Se entiende como una empresa emergente o start-ups, aquella que presenta grandes posibilidades de desarrollo y crecimiento en su sector. Y, por regla general, sale al mercado para captar recursos financieros y aumentar la escalabilidad de la empresa.<br><br>
          Es frecuente que el verdadero desarrollo del proyecto de creación de una empresa emergente comience incluso antes, con la búsqueda de una idea para dar respuesta a una demanda en el mercado. ¿Quién no ha pensado alguna vez: "esto sería una idea genial que nadie tiene"? Pero ojalá todo fuera tan fácil; no es solo necesaria esa idea sino, además, la gestión de un equipo "vehicular" comprometido (y alineado) para llevar a cabo la realidad de esa visión. Y esa es otra pieza clave, el equipo fundador.<br><br>
          <center><img class="img" width="500px" src="assets/lecciones/L11-1.png"></center>
          El objetivo de dicho equipo fundador será el de trabajar con ciertas habilidades para contrastar ese problema dando una solución, y el de ajustar el producto o servicio al mercado. Esto habrá que hacerlo pensando en escalar el negocio en el tiempo y hacerlo autosuficiente. Para llegar a buen puerto con este cometido, tendremos que pasar por un proceso de innovación y un modelo de negocio que sea viable y sostenible en el tiempo.<br><br>
          Tener una buena idea no es tan complicado como pudiera parecer; encontrar el equipo idóneo y llevarla a cabo es lo difícil. Recuerda: "si fuera fácil, todo el mundo lo haría".
          <center><img class="img" width="500px" src="assets/lecciones/L11-2.png"></center>
        `
      },
      { 
        'titulo': '¿Por qué elegir una Startups?',
        'texto':`
        Para analizar una empresa emergente, se deberían tener en cuenta varios fundamentales:<br><br>
          - Comprobar quien es el equipo fundador: sus conocimientos en las funciones determinadas, nivel de formación, experiencia empresarial, etc. Para ello es muy útil utilizar redes sociales como Linkedin.<br><br>
          - Innovación que presentan al mercado: si es algo nuevo y diferente, si puede aportar al mercado mejor optimización o si cubre una demanda hasta el momento insatisfecha. Para ello se debería analizar el plan de negocio o whitepaper (si hay disponible).<br><br>
          - Escalabilidad y valor futuro: Ver si la empresa o proyecto presenta ambición y cumple objetivos a futuro. Para ello se debe ir siguiendo un roadmap (con unos tiempos marcados), normalmente através de las redes sociales, AMAs o presentaciones de la empresa.<br><br>
          - Fase de desarrollo del proyecto: pueden encontrarse en presemilla, semilla, etapa temprana, etapa growth, fase de expansión o fase exit. Cada fase necesita diferentes requerimentos, como rondas de financiación o desarrollo de producto, y dependerá de la estrategia de la empresa.<br><br>
          <center><img class="img" width="500px" src="assets/lecciones/L11-3.png"></center>
      `},
      { 
        'titulo': 'Terminología y diferencias',
        'texto':`
        Con la llegada de nuevos términos, pueden llegar las confusiones. Vamos a tratar de poner algunas diferencias. Así, por ejemplo, un emprendedor es un solo individuo mientras que una startup es un equipo de emprendedores. La palabra equipo es un factor importante para hablar de ser startup y, por lo tanto, un único emprendedor no se consideraría como tal. <br><br>
        Por otro lado, tenemos otras definiciones como PYME, Mid Cap o multinacionales, cuyas clasificaciones vienen determinados por sus recursos (beneficios, volumen de negocio, número de trabajadores, etc). Las empresas emergentes tampoco llegan a cuadrarse aquí, puesto que al crearse se enfocan en otros factores de crecimiento, construcción de valor y escalabilidad en el mercado (posición del mercado, marca, escala, etc). Incluso podrían estar varios años sin obtener ganancias o ingresos para conseguir esa escalabilidad.
      `},
      { 
        'titulo': 'Rondas de financiación para Startup',
        'texto':`
        Una ronda de financiación es la fase donde la empresa emergente recibe una cantidad de dinero (necesaria para el desarrollo de su actividad) procedente de inversores (que normalmente suelen ser de capital privado). De esta forma, los inversores (en el mundo crypto los conoceremos como "holders") se convierten en socios/accionistas de la empresa adquiriendo una parte de su capital. Un punto importante es que los inversores realizan su inversión para apoyar el proyecto, con la idea de que la empresa crezca y así poder recuperar su capital invertido (más unas ganancias) en el futuro. En este ecosistema toman cada vez más fuerza figuras como los business angels o los venture capital.<br><br>
        Algunos de los factores clave para el éxito en una ronda de financiación serán la veracidad, credibilidad y confianza que consiga el equipo fundador.<br><br>
        Por lo tanto, ya vamos viendo varios factores importantes a considerar a la hora de valorar el éxito de una startup: La idea/innovación, el equipo fundador y los recursos. Ya iremos viendo que no son los únicos, aunque si los que más peso suelen tener.
      `},
      { 
        'titulo': 'Financiación específica para criptoactivos',
        'texto':`
        En el mundo crypto se han permitido algunas innovaciones a la hora de conseguir financiación. A continuación vamos a explicar algunas de ellas:<br><br>
          - ICO (Inicial Coin Offering / Oferta inicial de moneda): Dicho así, seguramente no lo tengas tan claro; pero si hablamos de OPV (oferta pública de venta), quizás este término si te suene más. Una OPV es el proceso por el cual las empresas salen a "bolsa", vendiendo sus acciones para captar capital. Pues la ICO es la versión crypto, donde los inversores compran tokens (o criptomonedas) en una fase inicial del proyecto.<br><br>
          - IEO (Inicial Exchange Offering): Serían una especie de ICOs de segunda generación. Aquí, un exchange actúa de plataforma intermediaria para la preventa de tokens (se fijan unos requisitos como precio de salida, cantidad de tokens, duranción de la preventa, etc). Estas plataformas actúan como terceros, y suelen generar una mayor confianza en los inversores. No obstante, se esta estructura centralizada se desvinculada, en parte, del enfoque descentralizado de las cryptos.<br><br>
          - IDO (Initial DEX Offering): Sería como una ICO pero que se ejecuta en un exchange descentralizado. O sea, un venta de tokens o criptomonedas que se hace de forma descentralizada (recordemos que la descentralización es la idea con la que nació Bitcoin). Con esta forma de financiación llegó el crecimiento de las finanzas descentralizadas (DeFi) y, con ello, una nueva forma de financiación. También podemos decir que los DEX tienen mayor riesgo y rentabilidad que los CEX.<br><br>
          Existen otras formas de financiación, aunque hemos destacado estas por ser las más extendidas hasta el momento.
          <center><img class="img" width="500px" src="assets/lecciones/L11-4.png"></center>
       `}
    ],
    'url_video': 'https://www.youtube.com/embed/4_o8M7SnPfU',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': 'Cartera CRYPTOS',
    'stakingSubtitulo': 'Cartera BTC + ETH + USDC + Hblock',
    'stakingPorcentaje': 'crypto',
    'stakingTiempo': 'básica',
  },

  {
    'cartera': 12,
    'nombre': 'Extra', 
    'tipo': 'mineria',
    'riesgo': '5',
    'intro': '',
    'estrategia': '',
    'temario': 
      [ 
        { 
          'titulo': 'Rentabilidad del minado',
          'texto':`
          Debemos recordar que el minado de criptomonedas consiste en crear las monedas a cambio de ganarlas (y para ello necesitamos ordenadores para resolver cálculos computacionales).<br><br>
            A parte de tener equipo idóneo para realizar el minado (como puede ser un ordenador, ASIC, rig, etc), hay otros factores que se deben tener en cuenta para comprobar si es rentable el proceso: el coste del equipo, la competencia, el precio de la energía (kwh), la refrigeración de los equipos o la propia rentabilidad de la criptomoneda deberán ser factores a tener en cuenta.<br><br>
            Además, también debemos tener en cuenta otros factores que, en ocasiones, pasan desapercibidos:<br><br>
            - Hash rate: Indica la cantidad de operaciones computacionales que tu equipo o un minero en concreto puede realizar.<br><br>
            - Comisión del pool: Aunque no es necesario estar en uno, normalmente se obtienen mejores recompensas dentro. Un pool es como una cooperativa donde trabajan varios mineros de forma conjunta para encontrar bloques. A mayor número de personas, más facilidad para hayar un bloque (pero también más gente para repartir las recompensas).<br><br>
            - Comisión del software (programa o scrypt para realizar el proceso): Aparecerá como una "donación" que irá destinada para el creador del mismo, que se restará de tus recompensas.<br><br>
            Antes de minar ninguna criptomoneda deberías calcular la rentabilidad. Para ello existen varias calculadores que, aunque no son 100% precisas, te darán una estimación aproximada. Entre otras, aquí tienes un par de ellas:<br><br>
            - https://www.coinwarz.com/mining/calculators<br><br>
            - https://whattomine.com/<br><br>
            Recuerda, busca y rellena los datos específicos para tu equipo y trata de minar aquellas cryptos que sean más rentables para el mismo.<br><br>
            Caso práctico: Ejemplo con Monero</b>
            Si es cierto que existen diversas criptomonedas, hemos elegido explicarte un proceso con la crypto "Monero" puesto que (a la fecha de hoy) permite realizar el proceso con unidades CPU y GPU (siendo una moneda atractiva para principiantes). NOTA: en ningún caso estás obligado a realizar este ejemplo, la ejecución o no por tu parte es responsabilidad tuya.<br><br>
            Básicamente se van a necesitar tres pasos: configurar un monedero con Monero, unirse a un pool de minería y activar el minado.<br><br>
            Con respecto al hardware, las GPU son más potentes que las CPU (y también más caras). En cuanto a las marcas líderes en GPU de minería, están Nvidia y AMD. ¿Esto significa que no puedes minar con tu equipo? para nada, puedes hacerlo con otras GPU y CPU; pero recuerda que también hay unos mínimos (un i5 o i7 al menos, y no dará cantidades muy altas). Hay que tener en cuenta que las ASIC son hardware más potentes que estos dos, pero Monero tiene en su algoritmo un sistema para que estos aparatos no puedan minar esta crypto.<br><br>
            Con respecto al software, debes buscar uno que sea compatible con tu hardware. Existen alternativas tanto para ordenadores de sobremesa como para teléfonos móviles. Entre los programas para minar, podemos destacar los siguientes: CC Miner, XMR Stak (compatible con hardware CPU y GPU) y Monero Spelunker (uno de los mejores para CPU).<br><br>
            NOTA: prácticamente todos los software van a aparecer con amenaza de virus; incluso, probablemente, sistemas operativos te avisen de peligro al descargarlos. Una vez más, la decisión y responsabilidad será tuya.<br><br>
            Luego tendrás que unirte a un pool de minería. Ten cuidado al elegir donde te metes; a continuación puedes ver algunos sitios:<br><br>
            - https://xmrig.com/<br><br>
            - https://xmr.nanopool.org/<br><br>
            - https://supportxmr.com/<br><br>
            Ya solo quedaría activar el minado y disfrutar de la ganancias (ojo, recuerda contrastar que el minado sale rentable después de quitarle los gastos).</br>
        `
      },
    ],
    'url_video': 'https://www.youtube.com/embed/lUcpSjqC7U8',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': '',
    'stakingSubtitulo': '',
    'stakingPorcentaje': '',
    'stakingTiempo': '',
  },

  {
    'cartera': 13,
    'nombre': 'Cartera X', 
    'tipo': 'emergentes',
    'riesgo': '7',
    'intro': 'Esta cartera de dividendos es una estrategia pasiva, apta para todos los inversores y ganadora a largo plazo. De hecho, si escoges bien las empresas me aventuraría a decir que es mejor para la mayoría de personas que otras alternativas de inversión sencillas como podrían ser la inversión en fondos indexados o la inversión en fondos gestionados.<br><br>A nivel de rentabilidad dependerá de tu elección de las empresas. Si las que acabas comprando incrementan el dividendo durante muchos años a buen ritmo es muy posible que mejores la rentabilidad de estas otras estrategias, pero lo que marca realmente la diferencia es el aspecto psicológico: el inversor en dividendos no estará sujeto al nerviosismo de los vaivenes de la bolsa o, por lo menos, lo estará menos. No es lo mismo que tu cartera caiga un 50% en una estrategia de fondos de acumulación, donde lo único que tienes es la valoración del fondo, que lo haga en una cartera de dividendos, donde la renta mensual se debería mantener estable e incluso incrementarse.',
    'estrategia': '',
    'temario': 
      [ 
        { 
          'titulo': 'Estrategia',
          'texto':`
          
        `
      },
      { 
        'titulo': 'Los dividendos',
        'texto':`
        
      `},
      { 
        'titulo': 'Aristócratas del dividendo',
        'texto':`
       
      `},
      { 
        'titulo': 'Dividendo por acción',
        'texto':`
       
        `},
      { 
        'titulo': 'Payout',
        'texto':`
       `},
      { 
        'titulo': 'Ventajas e inconvenientes',
        'texto':`
        `}
    ],
    'url_video': 'https://www.youtube.com/embed/zDtIB6xtZAA',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'stakingTitulo': '',
    'stakingSubtitulo': '',
    'stakingPorcentaje': '',
    'stakingTiempo': '',
  },


  {
    'cartera': 17,
    'nombre': 'Todo bajo control',
    'tipo': 'blockchain',
    'riesgo': '0',
    'intro': 'Nos iniciamos en el mundo de las finanzas personales y el control del presupuesto, la herramienta para tenerlo todo bajo control.',
    'estrategia': '',
    'url_video': 'https://www.youtube.com/embed/I7064BNec8Q',
    'cuestion': 'Indica la caracteristica principal de esta estrategia: ',
    'temario': 
    [ 
      { 
        'titulo': '1. Algo 1',
        'texto':`

        `
      },
      { 
        'titulo': '2. Tipos de empresas según estrategia',
        'texto':`

      `
    }
  ]
  },

];