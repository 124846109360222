import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { empresas } from 'src/app/interfaces/empresas';

@Component({
  selector: 'app-nft-info',
  templateUrl: './nft-info.component.html',
  styleUrls: ['./nft-info.component.scss']
})
export class NftInfoComponent implements OnInit {

  id = 0;

  @Input() nft;
  @Input() market: boolean = false;
  @Input() esMio: boolean = false;
  @Output() onNftBuying: EventEmitter<any> = new EventEmitter(); 
  @Output() onNftCanceling: EventEmitter<any> = new EventEmitter(); 
  
  contratoNFT;
  direction = "";
  nfts = [];

  constructor(
  ) {
    this.nft = {id: '', idVenta: 0, nombre: '', desc: '', icono: '', pais: 'eeuu', mercado: 'NYSE', color: 'dorado', sector: 'phone_iphone', tipo: 'Consumer Basic', level: 1};
  }

  ngOnInit(): void {
    this.nft.desc = empresas[this.nft.id].desc || "";
    this.direction = localStorage.getItem('myAddress');
  }

  buyNft(event){
    this.onNftBuying.emit(event);
  }

  cancelNft(event){
    this.onNftCanceling.emit(event);
  }


}
