import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { lecciones } from 'src/app/interfaces/cartera';



@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  leccion;

  constructor(
    @Inject(MAT_DIALOG_DATA) public cartera,
    public dialogRef: MatDialogRef<DialogComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log('la cartera seleccionada es: ', this.cartera);
    // Busco su leccion
    this.leccion = lecciones.find(element => element.cartera == this.cartera.id);
  }

  closeDialog() {
    this.dialogRef.close(this.cartera);
  }


}
