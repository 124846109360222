import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  novedades = [
    'Se ha conectado con el contrato HamperBlockGameData de la BlockChain Test de Matic',
    'Traducciones inglés/español al 80%',
    'Se ha añadido un filtro de tipos de carteras',
    'Todas las tarjetas del tablero están funcionando',
    'Mejorado el dialogo al abrir una cartera',
    'Se ha añadido un nuevo menú lateral para Mi cuenta',
    'Ahora funcionan 2 campos de las fichas superiores del Dashboard',
    'Primera propuesta de NFTs de empresas'
  ]

  public activeLang = 'en';

  constructor(
    private translate: TranslateService,
  ) {
    this.translate.setDefaultLang(this.activeLang);
   }

  ngOnInit(): void {
  }


  GiveStar() {
    window.open('https://github.com/ng-es/Angular-Truffle-Dapp');
  }

}
