import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-dialog-mineria',
  templateUrl: './dialog-mineria.component.html',
  styleUrls: ['./dialog-mineria.component.scss']
})
export class DialogMineriaComponent implements OnInit {

  owner: string = "";
  direction = "";
  loaded = false;
  texto1 = "";
  texto2 = "";

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public tarjeta,
    public dialogRef: MatDialogRef<DialogMineriaComponent>,
    private contract: ContractService,
  ) { }

  ngOnInit(): void {
    console.log('la cartera seleccionada es: ', this.tarjeta);
    this.direction = localStorage.getItem('myAddress');
    if (this.tarjeta.class == 'fee income-tax') {
      this.texto1 = "Para registrar tus transacciones en la Blockchain los mineros";
      this.texto2 = "necesitan minar los bloques con lo que te toca pagar.";
    } else {
      this.texto1 = "En esta casilla puede pasar cualquier cosa";
      this.texto2 = "esperemos que te toque recibir HBLOCK :)";
    }
  }

  openMineria() {
    //Calcula random lo que toca pagar...
  }

  openSuerte() {
    //Calcula random lo que toca pagar...
  }

  closeDialog() {
    this.dialogRef.close(this.tarjeta);
  }

}
