
<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Básico">

    <mat-grid-list cols="3" rowHeight="450px">
      
      <mat-grid-tile colspan="1" [rowspan]="1">
        <!-- IZQUIERDA -->
        <app-nft-info [nft]="nft"></app-nft-info>
      </mat-grid-tile>

      <mat-grid-tile colspan="1" [rowspan]="1" *ngIf="fundamentales">
        <!-- CENTRO -->
        <div>

          <mat-card>
      
            <mat-card-header>
              <mat-icon mat-card-avatar>{{ icono }}</mat-icon>
              <mat-card-title>{{ data.nombre }}</mat-card-title>
              <mat-card-subtitle>{{ data.sector }}</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              {{ data.description }}
              
              <p>MARKETCAP: {{ fundamentales.markercap /1000 | number : '1.2-2' }} M$</p>
        
            </mat-card-content>

          </mat-card>


          <div>

            <mat-card>
              <mat-card-header>
                <mat-card-title>Ratios</mat-card-title>
              </mat-card-header>

              <mat-grid-list cols="4" rowHeight="50px" class="small">
    
                <mat-grid-tile colspan="1">
                  DPA: {{ fundamentales.dpa }}
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  BPA: {{ fundamentales.bpa }}
                </mat-grid-tile>
                <mat-grid-tile colspan="1" [ngClass]="fundamentales.per > 1 ? 'green' : orange">
                  Beta: {{ fundamentales.beta }}
                </mat-grid-tile>
                <mat-grid-tile colspan="1" [ngClass]="fundamentales.per > 20 ? 'orange' : green">
                  PER: {{ fundamentales.per }}
                </mat-grid-tile>
  
              </mat-grid-list>
            </mat-card>
          
        </div>

          <div>

            <mat-card>
              <mat-card-header>
                <mat-card-title>Dividendos</mat-card-title>
              </mat-card-header>

              <mat-grid-list cols="3" rowHeight="50px" class="smaller">
    
                <mat-grid-tile colspan="1">
                  <mat-icon>event_available</mat-icon>  {{ fundamentales.fechaDividendo }}
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <mat-icon>paid</mat-icon> {{ fundamentales.fechaDividendoEx }}
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <mat-icon>savings</mat-icon>  {{ fundamentales.dya }} %
                </mat-grid-tile>
  
              </mat-grid-list>
            </mat-card>
          
        </div>

      </div>
          

      </mat-grid-tile>


      <mat-grid-tile colspan="1" [rowspan]="1">
        <!-- DERECHA -->

        <div>

          

          <br>

          <div>

            <mat-card>
              <mat-card-header>
                <mat-card-title>Estadísticas</mat-card-title>
              </mat-card-header>

              <mat-grid-list cols="4" rowHeight="50px">
    
                <mat-grid-tile colspan="1">
                  <mat-icon matBadge="0" matBadgeColor="primary">notifications</mat-icon>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <mat-icon [matBadge]="year" matBadgeColor="primary">event</mat-icon>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <mat-icon [matBadge]="historico.length" matBadgeColor="primary">leaderboard</mat-icon>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <mat-icon matBadge="0" matBadgeColor="primary">donut_small</mat-icon>
                </mat-grid-tile>
  
              </mat-grid-list>
            </mat-card>
          
        </div>

        <br>

          <div>

            <mat-card>
              <mat-card-header>
                <mat-card-title>Estrategia</mat-card-title>
              </mat-card-header>

              <mat-grid-list cols="4" rowHeight="50px">
    
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip [color]="data.estrategia == 'Dividendos' ? 'primary' : 'accent'" selected>Dividendos</mat-chip>
                  <mat-chip [color]="data.estrategia == 'Grow' ? 'primary' : 'accent'" selected>Growth</mat-chip>
                  <mat-chip [color]="data.estrategia == 'Largo' ? 'primary' : 'accent'" selected>Largo</mat-chip>
                </mat-chip-list>
  
              </mat-grid-list>
            </mat-card>
          
        </div>

        <br>

        <div>
            <mat-card>
              <mat-card-header>
                <mat-card-title>Medias</mat-card-title>
                <mat-card-subtitle>
                  MMA200: {{ arraySlider[2] }} MMA50: {{ arraySlider[3] }}
                </mat-card-subtitle>
              </mat-card-header>
              <span class="red smaller">{{ arraySlider[0] }}</span>
              <mat-slider
                thumbLabel
                [displayWith]="formatLabel"
                tickInterval="1000"
                [min]= arraySlider[0]
                [value] = arraySlider[2]
                disabled = true
                [max] = arraySlider[1]
                class="cdk-focused">
              </mat-slider>
              <span class="green smaller">{{ arraySlider[1] }}</span>
            </mat-card>
          </div>

        </div>


      </mat-grid-tile>

      <!-- ABAJO -->
      <mat-grid-tile colspan="3" [rowspan]="1">
      </mat-grid-tile>


    </mat-grid-list>



  </mat-tab>


  <!-- Resultados -->
  <mat-tab label="Resultados">

    <table mat-table [dataSource]="historico" class="mat-elevation-z8">

      <ng-container matColumnDef="fiscalDateEnding">
        <th mat-header-cell *matHeaderCellDef> Año </th>
        <td mat-cell *matCellDef="let element"> {{ element.fiscalDateEnding }} </td>
      </ng-container>
      <ng-container matColumnDef="totalRevenue">
        <th mat-header-cell *matHeaderCellDef> Ingresos </th>
        <td mat-cell *matCellDef="let element"> {{element.totalRevenue / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>
      <ng-container matColumnDef="costOfRevenue">
        <th mat-header-cell *matHeaderCellDef> Costes </th>
        <td mat-cell *matCellDef="let element"> {{element.costOfRevenue/1000000 | number : '1.0-0'}} </td>
      </ng-container>
      <ng-container matColumnDef="grossProfit">
        <th mat-header-cell *matHeaderCellDef>Benef. Bruto (%)</th>
        <td mat-cell *matCellDef="let element"> {{ (element.totalRevenue - element.costOfRevenue)/1000000 | number : '1.0-0' }} ({{ (element.totalRevenue - element.costOfRevenue)*100/element.totalRevenue | number : '1.1-1' }}) </td>
      </ng-container>
      <ng-container matColumnDef="ebitda">
        <th mat-header-cell *matHeaderCellDef>EBITDA</th>
        <td mat-cell *matCellDef="let element"> {{element.ebitda / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>
      <ng-container matColumnDef="dandp">
        <th mat-header-cell *matHeaderCellDef>D & A</th>
        <td mat-cell *matCellDef="let element"> {{element.dandp / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>
      <ng-container matColumnDef="ebit">
        <th mat-header-cell *matHeaderCellDef>EBIT</th>
        <td mat-cell *matCellDef="let element"> {{(element.ebitda - element.dandp)/1000000 | number : '1.0-0' }} </td>
      </ng-container> 
      <ng-container matColumnDef="interests">
        <th mat-header-cell *matHeaderCellDef>Intereses</th>
        <td mat-cell *matCellDef="let element"> {{element.interests / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>
      <ng-container matColumnDef="ebt">
        <th mat-header-cell *matHeaderCellDef>EBT</th>
        <td mat-cell *matCellDef="let element"> {{(element.netIncome + element.tax)/1000000 | number : '1.0-0' }} </td>
      </ng-container> 
      <ng-container matColumnDef="tax">
        <th mat-header-cell *matHeaderCellDef>Impuestos</th>
        <td mat-cell *matCellDef="let element"> {{element.tax / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>

      <ng-container matColumnDef="netIncome">
        <th mat-header-cell *matHeaderCellDef>Benef. Neto (%)</th>
        <td mat-cell *matCellDef="let element"> {{ element.netIncome / 1000000 | number : '1.0-0' }} ({{ (element.netIncome * 100)/element.totalRevenue | number : '1.1-1' }})</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsFundamentales"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsFundamentales;"></tr>

    </table>

    <br>

    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1px" fxLayoutAlign="center">

        <div fxFlex="45" style="padding: .5%">

          <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
            <canvas baseChart
              [datasets]="chartDataFundamentales"
              [labels]="charLabelsFundamentales"
              [colors]="chartColors"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType">
            </canvas>
          </div>

        </div>
        
        <div fxFlex="45" style="padding: .5%">

          <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
            <canvas baseChart
              [datasets]="chartDataFundamentales2"
              [labels]="charLabelsFundamentales2"
              [colors]="chartColors"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType">
            </canvas>
          </div>

        </div>

      </div>

      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1px" fxLayoutAlign="center">

        
        <div fxFlex="45" style="padding: .5%">
          <app-calendar *ngIf="datesToHighlight.length > 0" 
            [datesToHighlight]="datesToHighlight"
            (onResultadoCalendario)="objetoCalendario($event)"
            >
          </app-calendar>
        </div>

        <div fxFlex="45" style="padding: .5%">
          <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
            <canvas baseChart
            [datasets]="barChartDataCalendar"
            [labels]="barChartLabelsCalendar"
            [options]="barChartOptionsCalendar"
            [legend]="barChartLegend"
            [chartType]="barChartType">
          </canvas>
          </div>
          <div>
            <mat-card>
              <mat-card-header>
                <mat-card-title>{{ comentario.strDate }}</mat-card-title>
                <mat-card-subtitle>Año Fiscal {{ comentario.yearFiscal }}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <mat-slide-toggle color="primary" [checked]="comentario.confirmado">Reportado</mat-slide-toggle>
                <mat-grid-list cols="2" rowHeight="50px" class="small">
                  <mat-grid-tile colspan="1" [ngClass]="comentario.eps1 > comentario.eps0 ? 'green' : 'red'">
                    EPS: {{comentario.eps1}}
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                    EPS est: {{comentario.eps0}}
                  </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="2" rowHeight="50px" class="small">
                  <mat-grid-tile colspan="1" [ngClass]="comentario.totalRevenue > comentario.estimatedTotalRevenue ? 'green' : 'red'">
                    Ventas: {{comentario.totalRevenue/1000000}}
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1">
                    Ventas est: {{comentario.estimatedTotalRevenue/1000000}}
                  </mat-grid-tile>
                </mat-grid-list>
                {{ comentario.comentario }}
              </mat-card-content>
            </mat-card>
          </div>
        </div>

      </div>
    

  </mat-tab>

  <!-- Balance -->
  <mat-tab label="Balance">

    <table mat-table [dataSource]="historico" class="mat-elevation-z8">

      <ng-container matColumnDef="fiscalDateEnding">
        <th mat-header-cell *matHeaderCellDef> Año </th>
        <td mat-cell *matCellDef="let element"> {{ element.fiscalDateEnding }} </td>
      </ng-container>
      <ng-container matColumnDef="totalCurrentAssets">
        <th mat-header-cell *matHeaderCellDef> Activos Corto</th>
        <td mat-cell *matCellDef="let element"> {{element.totalCurrentAssets / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>
      <ng-container matColumnDef="totalNonCurrentAssets">
        <th mat-header-cell *matHeaderCellDef> Activos Largo </th>
        <td mat-cell *matCellDef="let element"> {{element.totalNonCurrentAssets/1000000 | number : '1.0-0'}} </td>
      </ng-container>
      <ng-container matColumnDef="totalCurrentLiabilities">
        <th mat-header-cell *matHeaderCellDef> Pasivos Corto</th>
        <td mat-cell *matCellDef="let element"> {{element.totalCurrentLiabilities / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>
      <ng-container matColumnDef="totalNonCurrentLiabilities">
        <th mat-header-cell *matHeaderCellDef> Pasivos Largo </th>
        <td mat-cell *matCellDef="let element"> {{element.totalNonCurrentLiabilities/1000000 | number : '1.0-0'}} </td>
      </ng-container>

      <ng-container matColumnDef="shortTermDebt">
        <th mat-header-cell *matHeaderCellDef>Deuda Corto</th>
        <td mat-cell *matCellDef="let element"> {{element.shortTermDebt / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>
      <ng-container matColumnDef="longTermDebt">
        <th mat-header-cell *matHeaderCellDef>Deuda Largo</th>
        <td mat-cell *matCellDef="let element"> {{element.longTermDebt / 1000000 | number : '1.0-0' }}  </td>
      </ng-container>

      <ng-container matColumnDef="totalBalance">
        <th mat-header-cell *matHeaderCellDef>Balance Total</th>
        <td mat-cell *matCellDef="let element"> {{ (element.totalCurrentAssets + element.totalNonCurrentAssets)/1000000 | number : '1.0-0' }}</td>
      </ng-container>
      <ng-container matColumnDef="equity">
        <th mat-header-cell *matHeaderCellDef>Patrimonio</th>
        <td mat-cell *matCellDef="let element"> {{ (element.totalCurrentAssets + element.totalNonCurrentAssets - element.totalCurrentLiabilities - element.totalNonCurrentLiabilities)/1000000 | number : '1.0-0' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsBalance"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsBalance;"></tr>

    </table>

    <br>

    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1px" fxLayoutAlign="center">

        <div fxFlex="45" style="padding: .5%">

          <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
            <canvas baseChart
              [data]="doughnutChartData"
              [labels]="doughnutChartLabels"
              [chartType]="doughnutChartType">
            </canvas>
          </div>

        </div>
        
        <div fxFlex="45" style="padding: .5%">

          <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
            <canvas baseChart
              [datasets]="chartDataBalance"
              [labels]="charLabelsBalance"
              [colors]="chartColors"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType">
            </canvas>
          </div>

        </div>

      </div>
    

  </mat-tab>

  <!-- Análisis Técnico -->
  <mat-tab label="Análisis Técnico">
    <app-grafica-velas *ngIf="velas.length > 0" [datos]="velas"></app-grafica-velas>
  </mat-tab>


<!-- Dividendo -->
  <mat-tab *ngIf="data.estrategia != 'Grow'" label="Dividendo">

    <div>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Fechas pago</mat-card-title>
        </mat-card-header>

        <mat-grid-list cols="1" rowHeight="50px">

          <mat-chip-list class="center" aria-label="Color selection">
            <mat-chip *ngFor="let chip of mesesDividendoColors" selected [color]="chip.color">
              {{chip.name}}
            </mat-chip>
          </mat-chip-list>

        </mat-grid-list>
      </mat-card>
    
  </div>

  <br>

    <table mat-table [dataSource]="historico" class="mat-elevation-z8">

      <ng-container matColumnDef="fiscalDateEnding">
        <th mat-header-cell *matHeaderCellDef> Año </th>
        <td mat-cell *matCellDef="let element"> {{ element.fiscalDateEnding }} </td>
      </ng-container>
      <ng-container matColumnDef="reportedEPS">
        <th mat-header-cell *matHeaderCellDef> EPS </th>
        <td mat-cell *matCellDef="let element"> {{element.reportedEPS | number : '1.2-2' }}  </td>
      </ng-container>

      <ng-container matColumnDef="commonStockSharesOutstanding">
        <th mat-header-cell *matHeaderCellDef>Acciones (mill)</th>
        <td mat-cell *matCellDef="let element"> {{ element.commonStockSharesOutstanding/1000000 | number : '1.0-0' }} </td>
      </ng-container>
      <ng-container matColumnDef="dpa">
        <th mat-header-cell *matHeaderCellDef>DPA</th>
        <td mat-cell *matCellDef="let element"> {{element.dividendPayout / element.commonStockSharesOutstanding | number : '1.2-2' }}  </td>
      </ng-container>
      <ng-container matColumnDef="bpa">
        <th mat-header-cell *matHeaderCellDef>BPA</th>
        <td mat-cell *matCellDef="let element"> {{element.netIncome / element.commonStockSharesOutstanding | number : '1.2-2' }}  </td>
      </ng-container>
      <ng-container matColumnDef="netIncome">
        <th mat-header-cell *matHeaderCellDef>Benef. Neto</th>
        <td mat-cell *matCellDef="let element"> {{ element.netIncome/1000000 | number : '1.0-0' }} </td>
      </ng-container>
      <ng-container matColumnDef="dividendPayout">
        <th mat-header-cell *matHeaderCellDef> Dividendos </th>
        <td mat-cell *matCellDef="let element"> {{element.dividendPayout/1000000 | number : '1.0-0'}} ({{(element.dividendPayout / element.netIncome)*100 | number : '1.0-0' }} %) </td>
      </ng-container>

      <ng-container matColumnDef="fcf">
        <th mat-header-cell *matHeaderCellDef>FCF</th>
        <td mat-cell *matCellDef="let element"> {{(element.operatingCashflow - element.capitalExpenditures)/1000000 | number : '1.0-0' }} ({{( element.dividendPayout / (element.operatingCashflow - element.capitalExpenditures))*100 | number : '1.0-0' }} %)</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <br>

  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1px" fxLayoutAlign="center">

    <div fxFlex="45" style="padding: .5%">

      <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
        <canvas baseChart
          [datasets]="barChartData"
          [colors]="chartColors"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType">
        </canvas>
      </div>

    </div>
    
    <div fxFlex="45" style="padding: .5%">

      <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
        <canvas baseChart
          [datasets]="barChartData2"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType">
        </canvas>
      </div>

    </div>

  </div>

  <div>
    <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
      <canvas baseChart
        [datasets]="barChartData3"
        [data]="lineChartData"
        [labels]="barChartLabels3"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas>
    </div>
  </div>

  </mat-tab>
</mat-tab-group>
