<div class="body">
    <h2> Movimientos de la Cartera</h2>

    <table mat-table [dataSource]="data" class="mat-elevation-z8">

      <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{ element.fecha | date: 'dd/MM/yyyy' }} </td>
      </ng-container>
      <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef> Empresa </th>
        <td mat-cell *matCellDef="let element"> {{element.empresa.nombre }}  </td>
      </ng-container>
      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef>Acción</th>
        <td [style.color]="element.tipo == 'SELL' ? 'red' : element.tipo == 'BUY' ? 'green' : 'yellow'" mat-cell *matCellDef="let element"> {{ element.tipo }} </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef>Cantidad</th>
        <td mat-cell *matCellDef="let element"> {{element.acciones == 0 ? '' : element.acciones }}  </td>
      </ng-container>
     <ng-container matColumnDef="precio">
        <th mat-header-cell *matHeaderCellDef> Precio </th>
        <td mat-cell *matCellDef="let element"> {{element.precio | number : '1.2-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="moneda">
        <th mat-header-cell *matHeaderCellDef>Moneda</th>
        <td mat-cell *matCellDef="let element"> {{ element.moneda }} </td>
      </ng-container>
      <ng-container matColumnDef="cambio_moneda">
        <th mat-header-cell *matHeaderCellDef>Cambio Moneda</th>
        <td mat-cell *matCellDef="let element"> {{ element.cambio_moneda }} </td>
      </ng-container>
      <ng-container matColumnDef="comision">
        <th mat-header-cell *matHeaderCellDef> Comisión </th>
        <td mat-cell *matCellDef="let element"> {{element.comision | number : '1.2-2'}} </td>
      </ng-container>
      <ng-container matColumnDef="total_coste">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let element"> {{ (element.acciones * element.precio) / element.cambio_moneda | number : '1.2-2' }} </td>
      </ng-container> 
      <ng-container matColumnDef="total_acciones">
        <th mat-header-cell *matHeaderCellDef>Acumuladas</th>
        <td mat-cell *matCellDef="let element"> {{ element.tipo == 'CASH IN' ? '' : element.total_acciones | number : '1.0-0' }}  </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</div>