import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro',
  pure: false
})
export class FiltroPipe implements PipeTransform {

  transform(array: any[], texto: string = '', columna: string = 'tipo'): any[] {

    if (texto === '') {
      return array;
    }

    if (!array) {
      return array;
    }

    return array.filter(( item => {
      return item.empresa[columna].includes(texto);
    })
    );
  }

}
