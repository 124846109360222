import { ContractService } from "./../../services/contract/contract.service";
import { Component, Inject, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Identicon } from "../../services/identicon";
import { Md5 } from "ts-md5/dist/md5";
import { TranslateService } from "@ngx-translate/core";
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';
import { DataService } from "src/app/services/data.service";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import Decimal from '../../../assets/js/decimal.js';

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {

  direction: string;
  balanceOf = 0;
  tokens = 0;
  totalSupply = 0;
  carteras;
  num_tokens = 1;
  staking = 0;
  mystaking = 0;
  stakingDolares = 0;
  totalInteresesPaid = 0;
  balance2 = 0;
  stakes = [];
  displayedColumns: string[] = ['position', 'amount', 'tipo', 'time', 'actions'];
  loading: boolean = false;
  profile;
  url;
  data;
  puede: false;
  ultimaCasilla = 0;
  carteraUser = 0;
  public activeLang = 'en';
  public operacionesDefault = false;

  contratoCrypto;
  porc_retiro = 0;
  totalInvestedUSDC = 0;
  totalValueUSDC = 0;
  balancesTokens = [];
  rent_carteraCrypto = 0;
  amountCrypto=(amount,decimals)=>Decimal.mul(amount,Decimal.pow(10, decimals));

  // Doughnut Data
  doughnutChartLabels = ['BTC', 'ETH', 'USDC', 'Hblock'];
  doughnutChartData = [
    [0, 0, 0, 0],
  ];

  //Progress Bar
  color: ThemePalette = 'warn';
  mode: ProgressBarMode = 'determinate';
  valueProgress = 0;
  bufferValue = 25;

  constructor(
    private contract: ContractService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private dataService: DataService,
    public dialog: MatDialog
  ) {
    this.translate.setDefaultLang(this.activeLang);

    this.contract
      .connectAccount()
      .then((value: any) => {
        this.direction = value[0];
        localStorage.setItem('myAddress', this.direction);
        this.getDetails(this.direction);
        // this.recogeDataContrato();
      /* this.profile = this.threebox.getProfile(this.direction).then((response) => {
            console.log(response);
            this.profile = response;
            this.url = this.profile.image[0].contentUrl["/"];
          }); */
        // this.getImage(this.direction);
      })
      .catch((error: any) => {
        this.contract.failure(
          "Could't get the account data, please check if metamask is running correctly and refresh the page"
        );
      });
  }

  ngOnInit() {

    // Pido el contrato del juego al servicio y despues el del staking
    this.contract
      .getContract()
      .then((contrato: any) => {
        this.totalSupply = this.contract.balanceOf;
        // console.log('contrato Juego: ', contrato);
        // Establecer el token solo la primera vez
        // this.setToken(contrato).then((value: any) => console.log(value));
        // this.getToken(contrato).then((value: any) => console.log('el token utilizado es:', value));

        // Obtengo la ultima cartera y casillta desbloqueada
        this.contract.miUltimaCartera(contrato, this.direction).then((value: any) => {
          console.log('carteraUser', contrato, this.direction)
          this.carteraUser = value;
        });
        this.contract.miUltimaCasilla(contrato, this.direction).then((value: any) => {
          this.ultimaCasilla = value;
          this.valueProgress = (value / 39) * 100;
          localStorage.setItem('ultimaCasilla', value);
        });
        this.balanceContrato(contrato).then((value: any) => {
          this.balanceOf = value/10**18;
        });
        this.contract.tokenUsd(contrato, 1).then( (value:any) => {
          this.tokens = 10**18/value;
        });
            // Contrato de Staking
            this.contract
              .getContractStaking()
              .then((contratoStaking: any) => {
                // console.log('contrato Staking: ', contratoStaking);
                this.myStake(contratoStaking).then((value: any) => this.stakingDolares = value/10**18);
                this.totalStaked(contratoStaking).then((valueStaking: any) => {
                  // console.log('stakinggg', valueStaking);
                  this.staking = valueStaking/10**18;
                });
                this.misStakes(contratoStaking).then((stakes: [any]) => {
                  // console.log(stakes);
                  this.mystaking = stakes.reduce((accum,item) => accum + item.amount/10**18, 0);
                  this.stakes = stakes;
                });
                this.balance(contratoStaking).then((value: any) => {
                  this.balance2 = value/10**18;
              });
                this.totalInterestPaid(contratoStaking).then((value: any) => this.totalInteresesPaid = value/10**18);
              });

              //Portfolio Crypto
      let symbols = [
        { 'address': '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 'symbol': 'USDC'}, 
        { 'address': '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 'symbol': 'ETH'}
     ];
      this.contract.getContractPortfolioCrypto().then((contratoPortfolioCrypto: any) => { 
        let address = localStorage.getItem('myAddress');
        if (address.length > 0){
          console.log('contrato PortfolioCrypto: ', contratoPortfolioCrypto, address);
        this.contratoCrypto = contratoPortfolioCrypto;
        contratoPortfolioCrypto.methods.getTokensBalanceInUSDCOf(address).call().then(balanceTokensCarteraCrypto => {
          console.log('balance actual de esa cartera', balanceTokensCarteraCrypto);
          contratoPortfolioCrypto.methods.getTokensBalanceOf(address).call().then(totalInvertidoCarteraCrypto => {
            this.rent_carteraCrypto = ((balanceTokensCarteraCrypto['totalAssetsInUSDC']/totalInvertidoCarteraCrypto['_totalInvestedUSDC'])-1)*100;
            this.totalInvestedUSDC = totalInvertidoCarteraCrypto['_totalInvestedUSDC']/1000000;
            this.totalValueUSDC = balanceTokensCarteraCrypto['totalAssetsInUSDC']/1000000;
          });
          let arrayNew = balanceTokensCarteraCrypto['balancesTokens'].map( (item)=> {
            // item.currentBalanceNew = item.currentBalance / (10**item.decimals);
            item.tokenSymbol = symbols.map(({ address, symbol }) => {
              if(address == item.token){
                 return symbol;
              } else {
                 return item.token;
              }
           });
            return item;
          });
          arrayNew.forEach(element => {
            console.log(this.doughnutChartData);
            switch(element['token']){
              case "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6":
                this.doughnutChartData[0][0] = Number(element['currentBalance'])/1000000; 
                break;
              case "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619":
                this.doughnutChartData[0][1] = Number(element['currentBalance'])/1000000; 
                break;
              case "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174":
                this.doughnutChartData[0][2] = Number(element['currentBalance'])/1000000; 
                break;
              case "0x1b69d5b431825cd1fc68b8f883104835f3c72c80":
                this.doughnutChartData[0][3] = Number(element['currentBalance'])/1000000; 
                break;
              default:
                console.log('que pasa');
            }
          });
          this.doughnutChartData= [...this.doughnutChartData];
          this.balancesTokens = arrayNew;
          // console.log('eee', arrayNew);
        });
        }
      });

      });
          
    
  }


  async setToken(contrato){
    return await contrato.methods.setToken('0xB79360d687e0a54BA1A445fEe14f057808c5806D').send({from: this.direction});
  }

  async getToken(contrato){
    return await contrato.methods.getToken().call();
  }

  async balanceContrato(contrato){
    return await contrato.methods.balanceOf(this.direction).call();
  }

  async miUltimaCasilla(contrato) {
    return await contrato.methods.myLastCell().call();
  }

  async myStake(contrato) {
    return await contrato.methods.HBLOCKStakedInUSD().call();
  }

  async totalStaked(contrato) {
    return await contrato.methods.totalStaked().call();
  }

  async totalInterestPaid(contrato) {
    return await contrato.methods.totalInterestPaid().call();
  }

  async balance(contrato) {
    return await contrato.methods.balanceOf(this.direction).call();
  }

  async misStakes(contrato) {
    let dir = localStorage.getItem('myAddress');
    return await contrato.methods.stakesOf(dir).call();
  }

  unstaking(posicion) {
    this.contract
      .getContractStaking()
        .then((contrato: any) => { 
          console.log(contrato);
          this.deStaking(contrato, posicion)
            .then((value: any) => {
              alert("El Unstaking seleccionado ha realizado correctamente, espere unos segundos para que se visualice");
              console.log(value);
            });
      });
  }

  async deStaking(contrato, posicion) {
    return await contrato.methods.unstake(posicion).send({from: this.direction});
  }

  withdraw() {
    console.log(this.porc_retiro, this.amountCrypto(this.porc_retiro, 6).toFixed(0));
      if(this.porc_retiro >=1 && this.porc_retiro <=100) {
        this.contratoCrypto.methods.withdraw(this.amountCrypto(this.porc_retiro, 6).toFixed(0)).send({from: this.direction}).then(ok => {
          console.log(ok);
        });
      } else {
         alert("El porcentaje de retiro debe estar entre el 1% y el 100%");
      }
  }

  getHistorico(){
    // alert('Estamos trabajando en esta función');
    this.dataService.getHistoricoCasillas(this.direction).subscribe( (resp:any) => {
      console.log(resp['results']);
      this.dialog.open(DialogHistoricoDialog, {
        data: {
          historico: resp['results'],
        },
      });

    });
  }

  toggle(event: MatSlideToggleChange) {
    console.log('toggle', event.checked);
    this.operacionesDefault = event.checked;
    // Si es true llamo al approve del token con 20 dolares por default
    localStorage.setItem('operacionesDefault', String(event.checked));
  }

  async compraTokens(){
    alert("Aun no disponible");
    // try {
    //   let num_tokens = this.num_tokens;
    //   await this.contrato.methods.CompraTokens(num_tokens).send({from: this.direction[0]}).then((value: any) => console.log(value));
    // } catch(err) {
    //   alert('Hay un problema con la compra',);
    //   console.log('error', err);
    // } finally {
    //   this.loading = true;
    // }
  }

  getImage(account) {
    this.data = this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/svg+xml; utf8," +
      encodeURI(
        new Identicon(Md5.hashStr(account), {
          size: 32,
          format: "svg",
        }).toString(true)
      )
    );
  }

  navigateTo() {
    window.open("https://metamask.io/");
  }

  connectAccount() {
    this.contract
      .connectAccount()
      // .then((value: any) => {
      //   this.direction = value;
      //   this.getDetails(this.direction);
      // })
      .catch((error: any) => {
        this.contract.failure(
          "Could't get the account data, please check if metamask is running correctly and refresh the page"
        );
      });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  getDetails(account) {
    this.contract
      .accountInfo(account)
      .then((value: any) => {
        this.balanceOf = value;
      })
      .catch((error: any) => {
        this.contract.failure(
          "Could't get the account data, please check if metamask is running correctly and refresh the page"
        );
      });
  }


}

@Component({
  selector: 'dialog-historico-dialog',
  templateUrl: 'dialog-historico-dialog.html',
  styleUrls: ["./account.component.scss"],
})
export class DialogHistoricoDialog implements OnInit {

  displayedColumnsHistorico: string[] = ['date', 'casilla', 'hblocks'];
  arrayHistorico = [];
  totalHblocks = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.arrayHistorico = this.data.historico;
    this.totalHblocks = this.arrayHistorico.reduce((accum,item) => accum + item.hblocks, 0);
  }
}