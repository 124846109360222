import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OnlineTestService } from '../../services/online-test.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { stringify } from 'querystring';

@Component({
  selector: 'app-online-test',
  templateUrl: './online-test.component.html',
  styleUrls: ['./online-test.component.css']
})
export class OnlineTestComponent implements OnInit {

  @Input() leccion = 0;
  questionList = [];
  selectedEntry;
  r1: string;

  selectedAnswer: any = [];
  resultList: any = [];

  constructor(
    private router: Router, 
    private onlineTestService: OnlineTestService,
  ) {
    }

  ngOnInit() {
    this.onlineTestService.getQuestions().subscribe((data) => {
      console.log(data);
      // this.questionList = data[this.leccion].questions
      this.questionList = data.find(element => element.leccion == this.leccion).questions;
      console.log(this.questionList);
      this.onlineTestService.setQuestionList(this.questionList);
    });
  }
  
  getQuestion(quiz) {
      return quiz.questionKey + '. ' + quiz.question;
  }
  
  onSubmit(form: NgForm) {
    console.log(form.form.value);
    this.onlineTestService.setSelectedAnswer(form.value);
    this.evaluaRespuestas(form.value);
    // this.router.navigate(['/result']);
  }
  
  onSelectionAnswer(questionKey, answer, option) {
      // console.log(answer);
      // this.selectedEntry = Object.assign({}, this.selectedEntry, answer);
  }

  evaluaRespuestas(respuestas) {
    this.questionList.forEach(s => {
      if(respuestas[s.questionKey] === s.answer){
          this.resultList.push('ok');
      } else {
        this.resultList.push('mal');
      }
    });
    console.log(this.resultList);
    alert(this.resultList); 
  }

}