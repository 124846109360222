<div class="body">

<mat-horizontal-stepper linear #stepper *ngIf="leccion">

    <mat-step [stepControl] = "firstFormGroup" label="Necesario responder para continuar">

      <!-- Teoria de esta cartera -->

      <mat-card>
         <mat-card-header>
            <mat-card-title>
               <h2>{{ leccion.nombre }}</h2>
            </mat-card-title>
            <mat-card-subtitle>
               <h3>Cartera de {{ leccion.tipo }} - Nivel de riesgo {{ leccion.riesgo }}/10 </h3>
            </mat-card-subtitle>
         </mat-card-header>
         <mat-card-content>
            <p innerHTML={{leccion.intro}}>{{ leccion.intro }}</p>
            <br><br>
            <mat-card-footer>
               Despliega cada sección para estudiar a fondo...
            </mat-card-footer>
         </mat-card-content>
      </mat-card>

  <!-- <mat-accordion>
   <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
     <mat-expansion-panel-header>
       <mat-panel-title>
         La Estrategia
       </mat-panel-title>
       <mat-panel-description>
         {{panelOpenState ? 'open' : 'closed'}} Clic para desplegar...
       </mat-panel-description>
     </mat-expansion-panel-header>
     <p innerHTML={{leccion.estrategia}}>{{ leccion.estrategia }}</p>
   </mat-expansion-panel>
 </mat-accordion> -->

 <mat-accordion *ngFor="let i of temario">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ i.titulo }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p innerHTML={{i.texto}}>{{ i.texto }}</p>
    </mat-expansion-panel>
  </mat-accordion>
  
   <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Video resumen
        </mat-panel-title>
        <mat-panel-description>
          {{panelOpenState ? 'open' : 'closed'}} Clic para desplegar...
        </mat-panel-description>
      </mat-expansion-panel-header>
      <iframe width="860" height="575" [src]="leccion.url_video | safe:'resourceUrl'" frameborder="0" allowfullscreen></iframe>
    </mat-expansion-panel>
  </mat-accordion>


  <hr>
<!-- Cuestionario breve -->

   <!-- <h2>Cuestionario breve para revisar lo aprendido</h2>

      <form [formGroup] = "firstFormGroup">
          <ng-template matStepLabel>Aprende</ng-template>

          <label>Indica la caracteristica principal de esta estrategia</label>
          <p></p>
            <mat-radio-group formControlName = "firstCtrl" (change)="changeOption($event)">
               <mat-radio-button value="1">volatilidad </mat-radio-button>
               <mat-radio-button value="2">seguridad </mat-radio-button>
               <mat-radio-button value="3">creciente </mat-radio-button>
            </mat-radio-group>
          <div>
            <button color="primary" [disabled]="firstFormGroup.valid" mat-button matStepperNext>Siguiente</button>
          </div>
       </form> -->

    </mat-step>

    <!-- TEST -->

    <mat-step [stepControl] = "secondFormGroup">
       <form [formGroup] = "secondFormGroup">
          <ng-template matStepLabel>Test</ng-template>

          <app-online-test [leccion]="id"></app-online-test>

          <!-- <div class="form-field-group">
            <span class="form-field-label">1. Selecciona la opción correcta:</span>
            <mat-form-field>
               <mat-label>Selecciona tu respuesta en el desplegable</mat-label>
               <mat-select name="q1Ctrl" [(value)]="selectedCountry" formControlName = "q1Ctrl" required>
                   <mat-option [value]="'GB'">Great Britain</mat-option>
                   <mat-option [value]="'US'">United States</mat-option>
                   <mat-option [value]="'CA'">Canada</mat-option>
               </mat-select>
            </mat-form-field>
         </div>

         <div class="form-field-group">
            <span class="form-field-label">2. Selecciona las opciones correctas:</span>
            <mat-form-field appearance="fill">
               <mat-select [formControl]="toppings" multiple>
                 <mat-select-trigger>
                   {{toppings.value ? toppings.value[0] : ''}}
                   <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                     (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                   </span>
                 </mat-select-trigger>
                 <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
               </mat-select>
             </mat-form-field>
         </div> -->

          
          


          

          <div>
            <button mat-button matStepperPrevious>Volver</button>
            <button mat-button matStepperNext>Siguiente</button>
          </div>

       </form>
    </mat-step>

    <!-- INVIERTE -->

    <mat-step>
       <ng-template matStepLabel>Invierte</ng-template>

       <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="5px" fxLayoutAlign="center">
        <div style="display:flex" style="padding: .5%">
          <mat-card *ngIf="leccion.inversionTitulo != 0" class="example-card mat-elevation-z4">
  
            <mat-card-header [ngClass]="leccion.color">
              <mat-card-title>{{ leccion.inversionTitulo }}</mat-card-title>
              <mat-card-subtitle>{{ leccion.inversionSubtitulo }}</mat-card-subtitle>
            </mat-card-header>

            <mat-chip-list aria-label="Dog selection">
              <mat-chip color="primary" selected>
                <mat-chip-avatar>
                    <img src="https://material.angular.io/assets/img/examples/shiba1.jpg"/>
                </mat-chip-avatar>
                {{ leccion.inversionPorcentaje }}
              </mat-chip>
              <mat-chip color="primary" selected>
                <mat-chip-avatar>
                    <img src="https://material.angular.io/assets/img/examples/shiba1.jpg"/>
                </mat-chip-avatar>
                {{ leccion.inversionTiempo }}
              </mat-chip>
            </mat-chip-list>

            <ng-container *ngIf="leccion.inversionPorcentaje == '12 %' || leccion.inversionPorcentaje == '8 %' || leccion.inversionPorcentaje == '5 %'">
              <img class="img" style="width: 250px;" src="assets/staking.png" alt="{{ leccion.tipo }}">
              <mat-card-content>
                <mat-form-field appearance="fill">
                  <mat-label>Tokens a Stakear</mat-label>
                  <input matInput type="number" [(ngModel)]="num_tokens">
                </mat-form-field>
              </mat-card-content>
              <mat-card-actions align="end" *ngIf="leccion.inversionPorcentaje != '?? %'">
                <button mat-raised-button color="primary"(click)="staking()">STAKING NOW</button>
              </mat-card-actions>
            </ng-container>

            <ng-container *ngIf="leccion.inversionPorcentaje == 'NFT'">
              <img class="img" style="width: 250px;" src="assets/staking.png">
              <mat-card-actions align="end">
                <button mat-raised-button color="primary"(click)="buyNFT(leccion.cartera)">COMPRAR 1 NFT</button>
              </mat-card-actions>
              <mat-spinner *ngIf="loaded"></mat-spinner>
            </ng-container>

            <ng-container *ngIf="leccion.inversionPorcentaje == 'crypto'">
              <img class="img" style="width: 250px;" src="assets/criptomonedas.png">
              <mat-card-content>
                <mat-form-field appearance="fill">
                  <mat-label>USDC a invertir</mat-label>
                  <input matInput type="number" [(ngModel)]="num_usdc">
                </mat-form-field>
              </mat-card-content>
              <mat-card-actions align="end">
                <button mat-raised-button color="primary"(click)="invest()">INVEST</button>
              </mat-card-actions>
              <mat-spinner *ngIf="loaded"></mat-spinner>
            </ng-container>
        </mat-card>

        <br>

        <mat-card *ngIf="leccion.inversionPorcentaje == 'crypto'" class="example-card mat-elevation-z4">
          <mat-card-header [ngClass]="leccion.color">
            <mat-card-title>Resultados Cartera</mat-card-title>
            <mat-card-subtitle>Números globales de esta cartera</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              <h2>Cartera: {{ totalInvestedUSDC/1000000 | number : '1.2-2' }} USDC invertidos.</h2>
              <!-- Desde su inicio se han invertido {{ totalInvestedUSDC/1000000 }} USDC invertidos.
              <br> -->
              <!-- Precio OffChain ETH: {{ priceOffChainETH_USD/1000000 }} -->
              <h5>Valores en % de la gráfica</h5>
              <app-donut [doughnutChartLabels]="doughnutChartLabels" [doughnutChartData]="doughnutChartData" ></app-donut>
              <h4>Composición actual:<br>
                {{ distributionTokens[0] | number : '1.8-8' }} BTC<br>
                {{ distributionTokens[1] | number : '1.8-8' }} ETH<br>
                {{ distributionTokens[2] | number : '1.8-8' }} USDC<br>
                {{ distributionTokens[3] | number : '1.8-8' }} Hblock<br></h4>
          </mat-card-content>
          </mat-card>
      </div> 
    </div>

     <div class="body" *ngIf="leccion.inversionPorcentaje == 'crypto'">
     
      <h2> Movimientos de la Cartera</h2>
  
      <table mat-table [dataSource]="data" class="mat-elevation-z8">
  
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef> Fecha </th>
          <td mat-cell *matCellDef="let element"> {{ element.time | date: 'dd/MM/yyyy hh:mm:ss' }} </td>
        </ng-container>
        <ng-container matColumnDef="tokenA">
          <th mat-header-cell *matHeaderCellDef> Token A </th>
          <td mat-cell *matCellDef="let element"> {{element.tokenA }}  </td>
        </ng-container>
        <ng-container matColumnDef="tokenB">
          <th mat-header-cell *matHeaderCellDef> Token B </th>
          <td mat-cell *matCellDef="let element"> {{element.tokenB }}  </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Precio</th>
          <td mat-cell *matCellDef="let element"> {{element.price / 10000000000000000000 | number : '1.8-8'}} / {{element.tokenA }} </td>
        </ng-container>
       <ng-container matColumnDef="distribution">
          <th mat-header-cell *matHeaderCellDef> Distribución </th>
          <td mat-cell *matCellDef="let element"> {{element.distribution | number : '1.2-2'}} %</td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>
  
  </div>
<br>
       <div>
          <button mat-button matStepperPrevious>Anterior</button>
          <button mat-button (click) = "stepper.reset()">Empezar de nuevo</button>
          <button mat-raised-button color="primary" [routerLink]="['/pages/board']">Volver al Tablero</button>
       </div>
    </mat-step>
 </mat-horizontal-stepper>
</div>