import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  faLongArrowAltLeft,
  faQuestion,
  faSubway,
  faCube,
  faFrownOpen,
  faLightbulb,
  faCar,
  faTint,
  faGavel,
  faGem,
} from '@fortawesome/free-solid-svg-icons';
import { ContractService } from 'src/app/services/contract/contract.service';
import { DataService } from 'src/app/services/data.service';
import { DialogBlockchainComponent } from '../dialog-blockchain/dialog-blockchain.component';
import { DialogBonusComponent } from '../dialog-bonus/dialog-bonus.component';
import { DialogMineriaComponent } from '../dialog-mineria/dialog-mineria.component';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent {

  tarjetas = [];
  tarjetasIzq = [];
  tarjetasArriba = [];
  tarjetasDrcha = [];
  allCasillas = [];
  nfts = [];

  contrato;
  tokens = 0;
  direction = "";
  ultimaCasillaJugador = "";
  loaded = false;
  visitedColor: string = 'white';

  // Diseno de icons y flechas
  faLongArrowAltLeft = faLongArrowAltLeft;
  faQuestion = faQuestion;
  faSubway = faSubway;
  faCube = faCube;
  faFrownOpen = faFrownOpen;
  faLightbulb = faLightbulb;
  faCar = faCar;
  faTint = faTint;
  faGavel = faGavel;
  faGem = faGem;

  constructor(
    public dialog: MatDialog,
    private contract: ContractService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dataService: DataService
    ){
  
    this.contract
    .getContract()
    .then((contrato: any) => {
      // console.log('board',contrato);
      this.contract.getCasillas(contrato).then((value: any) => {
        // console.log(value);
        this.tarjetas = value.slice(30,39);
        this.tarjetasIzq = value.slice(20,29);
        console.log(this.tarjetasIzq);
        this.tarjetasArriba = value.slice(10,19).reverse();
        this.tarjetasDrcha = value.slice(0,9).reverse();
        // this.allCasillas = value;
        // Ahora tengo que desbloquear las del usuario que haya entrado
        this.contract.miUltimaCasilla(contrato, this.direction).then((value: any) => {
          // console.log(value);
          this.ultimaCasillaJugador = String(value);
          this.setCasillasUnlocked(value);
        });
        // this.contract.unlocked_carteras(contrato).then((value: any) => {
        //   console.log('unlocked_carteras', value);
        // });

      });
      this.contrato = contrato;
      this.contract.tokenUsd(contrato, 1).then( (value:any) => {
        this.tokens = Number((value / 10**18).toFixed());
        // console.log(this.tokens);
      });
    });
    }

    ngOnInit() {
      this.direction = localStorage.getItem('myAddress');
    }

    async avanzarCasilla(){
      if (Number(this.ultimaCasillaJugador) >= 14) {
        alert("Aún no está disponible esta casilla");
        return;
      }
      this.loaded = true;
        
      // Balances antes
        let balanceAntes = 0;
        let balanceDespues = 0;
        let nfts1;
        this.contract.balanceContrato(this.contrato, this.direction).then((value: any) => {
          console.log('balanceAntes:', value/10**18);
          balanceAntes = value/10**18;
        });
        this.contract.getContractNFT().then(async (contratoNFT: any) => {
          this.getMisNtfs(contratoNFT).then( (nfts: any) => {
            console.log('Mis NFTs antes son:', nfts, nfts.length);
            nfts1 = nfts;
          });
        });

        // Nuevo codigo manager (test)
        let infinity = Boolean(localStorage.getItem('operacionesDefault'));
        this.contract.miUltimaCasilla(this.contrato, this.direction).then((ultimaCasilla: any) => {
          this.contract.getContract().then((contratoManager: any) => {
            contratoManager.methods.amountTokensForUSD(2).call().then((hblock: number) => {
              this.contract.getContractToken().then((contratoToken: any) => {
                this.contract.allowance_approve(contratoToken, this.direction, '0x41FAeF5B0dFA65D9a9AA4Caa1350D51B00b0bDCB', String(hblock), infinity, ()=>{
                  contratoManager.methods.unlockNextCell().send({from: this.direction}).then(ok => {
                    console.log(ok);
                    if(!ok.status){
                      alert("No se pudo desbloquear la casilla");
                      return;
                    }
                    this.loaded = false;
                    // localStorage.setItem('ultimaCasilla', String(parseInt(ultimaCasilla)+1));
                    this.setCasillasUnlocked(parseInt(ultimaCasilla)+1);
                    this.contract.balanceContrato(this.contrato, this.direction).then((despues: any) => {
                      balanceDespues = despues/10**18;
                      // Guarda el historico con el coste de la casilla y fecha
                      let hblocks = (ok.events['UnlockCell'].returnValues[1]/10**18);
                      this.dataService.addHistoricoCasilla(this.direction, parseInt(ultimaCasilla)+1, hblocks).subscribe( (resp:any) => {
                        console.log(resp);
                      });
                      console.log('ultimaCasilla EEEEEEE', ultimaCasilla);
                      if([4, 9, 14, 19, 24, 29, 34].includes(parseInt(ultimaCasilla))){
                        this.nftCasilla(nfts1);
                        return;
                      }
                      console.log(ok.events);
                      if(hblocks < 0){
                        this.openSnackBar('HBLOCK ganados ', Math.abs(hblocks).toFixed(2));
                        return;
                      }
                      this.openSnackBar('Coste avance HBLOCK ', hblocks.toFixed(2));
                    });
                  });
                });
              });
            });
          });
        });
    }

    compareNtfs(nft1,nft2){
      return nft1.filter(x=>x.level!=nft2.filter(y=>y.id==x.id)[0].level)[0]
    }

    nftCasilla(nfts1: any){
      this.contract.getContractNFT().then(async (contratoNFT: any) => {
        this.getMisNtfs(contratoNFT).then( (nfts2: any) => {
          this.openSnackBar('¡Nuevo NFT!', nfts2);

          if(nfts2.length > nfts1.length) {
            console.log('Mi new NFT es:', nfts1[nfts1.length - 1]);
            this.openSnackBar('¡Nuevo NFT! ', nfts1[nfts1.length - 1].nombre);
         } else if (nfts2.length == nfts1.length) {
            let newLevel = this.compareNtfs(nfts2, nfts1);
            console.log('Mi NFT que ha subido nivel es:', this.compareNtfs(nfts2, nfts1), nfts2, nfts1);
            this.openSnackBar('Sube de nivel tu NFT: ', newLevel.nombre);
         } else {
            console.log('Hay un problema para saber el NFT que ha tocado' );
         }
        });
      });
    }

    setCasillasUnlocked(id: number) {

      const idSuperior = (x)=> {
        if(parseInt(x.id) <= id){
          x.bloqueada = true;
        }
        return x;
      }
      this.tarjetas = this.tarjetas.map(idSuperior);
      this.tarjetasIzq = this.tarjetasIzq.map(idSuperior);
      this.tarjetasArriba = this.tarjetasArriba.map(idSuperior);
      this.tarjetasDrcha = this.tarjetasDrcha.map(idSuperior);
          
    }

    openDialog(data: any) {
      this.visitedColor = 'black';
      console.log(data);
      const dialogRef = this.dialog.open(DialogComponent, {
        data: data
      });

      dialogRef.afterClosed()
        .subscribe(result => {
          //this.tarjeta.bloqueada = true;
          console.log(`Dialog result: ${result}`);
        });
    }

    openDialogMineria(data: any) {
      console.log(data);
      const dialogRef = this.dialog.open(DialogMineriaComponent, {
        data: data
      });

      // dialogRef.afterClosed()
      //   .subscribe(result => {
      //     //this.tarjeta.bloqueada = true;
      //     console.log(`DialogMineria result: ${result}`);
      //   });
    }

    openDialogBonus(data: any) {
      console.log(data);
      const dialogRef = this.dialog.open(DialogBonusComponent, {
        data: data
      });
      dialogRef.afterClosed()
        .subscribe(result => {
          console.log(`openDialogBonus result: ${result}`);
        });
    }

    openDialogBlockChain(data: any) {
      console.log(data);
      const dialogRef = this.dialog.open(DialogBlockchainComponent, {
        data: data
      });
      dialogRef.afterClosed()
        .subscribe(result => {
          console.log(`openDialogBlockChain result: ${result}`);
        });
    }

    async getMisNtfs(contrato) {
      return await contrato.methods.getOwnerNfts(this.direction).call();
    } 

    openSnackBar(msg: string, value: any) {

      const snackBarRef = this.snackBar.open(msg + String(value), 'Desbloqueada! Haz clic', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      snackBarRef.afterDismissed().subscribe(() => {
        console.log('The snackbar was dismissed');
        // window.location.reload();
        this.router.navigate(['/pages/board']);
      });
    }

}