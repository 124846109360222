import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-vender',
  templateUrl: './vender.component.html',
  styleUrls: ['./vender.component.scss']
})
export class VenderComponent implements OnInit {

  misNfts = [];
  nftChoose;
  num_tokens: number;
  token = {token: "0x1b69d5b431825cd1fc68b8f883104835f3c72c80"};
  contrato;
  loaded = false;
  direction = "";

  constructor(
    private contract: ContractService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.direction = localStorage.getItem('myAddress');

    this.contract.getContractNFT().then(async (contratoNFT: any) => {
      this.contract.getMisNtfs(contratoNFT, this.direction).then( (misNfts: any) => {
        console.log(misNfts);
          misNfts.forEach(element => {
            this.isOwnerOfNFT(contratoNFT, element.id).then( (idMint: any) => {
              // console.log(idMint);
              element['idMint'] = idMint;
              this.misNfts.push(element);
            });
          });
      });
    });

    this.contract.getContractMarket().then((contrato: any) => {
      this.contrato = contrato;
    });

  }

  nftSelected(evento) {
    console.log(evento);
    this.token.token = evento.token;
    // this.contract.getContractAnyToken(this.tokenB.token).then((contratoToken: any) => {
    //   contratoToken.methods.decimals().call().then((decimales: any) => {
    //     this.decimalsB = decimales;
    //   });
    // });
  }

  cleanNft(evento) {
    this.token.token = '';
  }

  async isOwnerOfNFT(contrato, idNft: number) {
    return await contrato.methods.isOwnerOfNFT(this.direction, idNft).call();
  }

  venderNft(idNft: number, priceNft: number){
    console.log(idNft, priceNft, this.token.token);
    this.loaded = true;
    this.contract.getContractNFT().then((contNFT: any) => {
      this.contract.allowance_approve721(contNFT, this.direction, '0x3407047999b880d7a72c41490b8df9b695443cf2', idNft, ()=>{
        this.sale(idNft, Number(priceNft)).then((ok: any) => {
          this.loaded = false;
          console.log(ok);
          this.openSnackBar('Has puesto a la venta el NFT: #', idNft);
        });
      });
    });
  }

  async sale(idNft: number, priceNft: number) {
    console.log(idNft, priceNft, this.token.token);
    return await this.contrato.methods.openSale(idNft, String(priceNft*10**18), this.token.token).send({from: this.direction})
  } 

  openSnackBar(msg: string, value: any) {
    const snackBarRef = this.snackBar.open(msg + String(value), '¡Enhorabuena!, clic para seguir...', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    snackBarRef.afterDismissed().subscribe(() => {
      console.log('The snackbar was dismissed');
      this.router.navigate(['/pages/market']);
    });
  }

}
