import { Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { lecciones } from '../../interfaces/cartera';

import { DomSanitizer } from '@angular/platform-browser'
import { MatStepper } from '@angular/material/stepper';
import { DataService } from 'src/app/services/data.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-learningbc',
  templateUrl: './learningbc.component.html',
  styleUrls: ['./learningbc.component.scss']
})
export class LearningBcComponent implements OnInit {

   id: number = 0;
   leccion: any;
   temario = [];
   panelOpenState = false;

   constructor(
      private route: ActivatedRoute,
      private dataService: DataService,
      ) {}


   ngOnInit() {
      this.route.params.subscribe(params => {
         this.id = +params['id'];
         // this.leccion = lecciones.find(element => element.cartera == this.id);
         this.dataService.getLeccionesCartera(this.id)
               .subscribe((leccion: any) => { 
                  console.log(leccion);
                  this.leccion = leccion[0]['leccion'];
                  this.temario = leccion;
               });
      });
   }

}
