import { Component, Input, OnInit } from '@angular/core';
import { MultiDataSet, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss']
})
export class DonutComponent implements OnInit {

  @Input() doughnutChartLabels: Label[] = [];
  @Input() doughnutChartData: MultiDataSet = [];

  public doughnutChartType: ChartType = 'doughnut';
  doughnutChartOptions = {
    cutoutPercentage: 80
   };

  constructor() { }

  ngOnInit(): void {
  }

}
