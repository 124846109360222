<div class="body">
    <h2>Rádar de empresas</h2>

    <mat-form-field appearance="fill">
        <mat-label>Tipo de estrategia</mat-label>
        <mat-select [formControl]="toppings" (selectionChange)="onFilterChange($event)">
          <mat-option *ngFor="let estrategia of estrategias" [value]="estrategia.value">
            {{estrategia.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <div fxLayout="row wrap" fxLayoutGap="16px">
        <div fxFlex="22%" fxFlex.xs="100%" fxFlex.sm="45%" *ngFor="let radar of data | filtro:textoBuscar:estrategia">
              <mat-list>
                <mat-list-item>
                  <mat-card>
                    <mat-card-header>{{ radar.empresa.symbol }}</mat-card-header>
                    <img src="https://logo.clearbit.com/{{ radar.empresa.logo }}.com" matListAvatar alt="">
                    <div matLine><h4>{{ radar.empresa.nombre }}</h4></div>
                    <div matLine>Sector : {{ radar.empresa.sector }}</div>
                    <div matLine>{{ radar.empresa.estrategia }}</div>
                    <div matLine>Entró el: {{ radar.fecha }}</div>

                    <div matLine><h3>Objetivo: {{ radar.objetivo }} {{ radar.empresa.currency }}</h3></div>
                    <div matLine>Último: {{ radar.precio }} {{ radar.empresa.currency }}</div>
                    <!-- <div>
                      <button mat-raised-button
                            (click)="getPrecioEmpresa(radar.empresa)"
                            aria-label="Show/Hide tooltip on the button at the end of this section"
                            class="example-button">
                      Dime Precio
                    </button> -->
                      <!-- (click)="getPrecioEmpresa(radar.empresa.symbol)" -->
                      <!-- <button mat-button #tooltip="matTooltip"
                            [matTooltip]="radar.precio"
                            matTooltipClass="tooltip-red"
                            aria-label="Button that shows a red tooltip"
                            class="example-button">
                      Precio actual
                    </button>
                    </div> -->
                  </mat-card>
                </mat-list-item>
                </mat-list>
        </div>
    </div>

  </div>
