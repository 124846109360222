<mat-form-field>
    <mat-label>Select a token</mat-label>
    <input matInput
           aria-label="Token"
           [matAutocomplete]="auto"
           [formControl]="tokenCtrl">
    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="150" (optionSelected)="onChange($event.option)" [displayWith]="displayFn">
      <mat-option *ngFor="let token of filteredTokens | async" [value]="token">
        <img with="25" height="25" [src]="token.logo"> {{ token.symbol}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button mat-button matSuffix mat-icon-button *ngIf="token" (click)="clean()">
    <mat-icon>close</mat-icon>
</button>