import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './pipes/filtro.pipe';
import { ImagenPipe } from './pipes/imagen.pipe';
import { DonutComponent } from './charts/donut/donut.component';
import { LineComponent } from './charts/line/line.component';
import { ChartsModule } from 'ng2-charts';
import { VelasComponent } from './charts/velas/velas.component';
import { CalendarComponent, MyDateAdapter } from './calendar/calendar.component';
import { AppMaterialModule } from '../app-material.module';
import { DateAdapter } from '@angular/material/core';
import { DuplicatePipe } from './pipes/duplicate.pipe';



@NgModule({
  declarations: [
    FiltroPipe,
    DuplicatePipe,
    ImagenPipe,
    DonutComponent,
    LineComponent,
    VelasComponent,
    CalendarComponent
  ],
  imports: [
    CommonModule,
    ChartsModule,
    AppMaterialModule
  ],
  exports:[
    FiltroPipe,
    DuplicatePipe,
    ImagenPipe,
    DonutComponent,
    LineComponent,
    VelasComponent,
    CalendarComponent
  ], providers: [
    {provide: DateAdapter, useClass: MyDateAdapter},
  ]
})
export class SharedModule { }
