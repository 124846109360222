<div class="body">
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="18px" fxLayoutAlign="center">
    <div fxFlex style="padding: .5%">
    <!-- 1 -->
    <mat-card *ngIf="direction !== undefined">
      <mat-card-header>
        <div mat-card-avatar class = "tp-header-image"></div>
        <mat-card-title>{{ 'account.myaccount' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'account.submyaccount' | translate }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        
      <div style="text-align:center">
        <section class="header">
          <!-- <img *ngIf="!url" [src]="data" class="profile" />
          <img  *ngIf="url"src='https://ipfs.infura.io/ipfs/{{ url }}'class="profileInfura" />
         <h4 *ngIf="profile"> Welcome {{ profile.name }}   </h4>
         <h6 *ngIf="profile">{{ profile.emoji }}</h6>  -->

         <h2>Tu cuenta</h2>
         <h3>{{ balanceOf | number: '1.0-0' }} HBLOCK --> {{ balanceOf * tokens | number: '1.2-2' }} $</h3>
         <h4>{{ tokens | number: '1.5-5' }} USD/HBLOCK</h4>
         
          <h2>{{ 'account.youraddress' | translate }}</h2>
          <h4>{{ direction }} </h4>
        </section>

        <!-- <button mat-raised-button color="primary" class="big-round-shape-button" (click)="desbloquea_tarjeta()">Desbloquear Cartera(Test)</button> -->


        <!-- <button mat-raised-button color="primary" class="big-round-shape-button" (click)="miUltimaCasilla()">Carteras desbloqueadas:</button> -->

        <!-- - {{ carteras.precio_tarjeta }} - {{ carteras.desbloqueada_tarjeta }} -->
        

      </div>
      
    </mat-card-content>

    </mat-card>
  </div>
  
  <div fxFlex style="padding: .5%">
    <!-- 2 -->
    <mat-card>

      <mat-card-header>
        <div mat-card-avatar class = "tp-logo-image"></div>
        <mat-card-title>EL PROYECTO</mat-card-title>
        <mat-card-subtitle>Sus números</mat-card-subtitle>
      </mat-card-header>
      
      <mat-card-content>
        <div style="text-align:center">
          <section class="header">
            <h3>{{ 'account.total_supply' | translate }}: 100,000,000 HBLOCK</h3>
            <h3>MarketCap: {{ 100000000 * tokens | number: '1.0-0' }} USD</h3>
            <h2 class="green" color="success">YTD: {{ ((tokens/0.0027)-1)*100 | number: '1.2-2' }} %</h2>
            <br>
            <h3>TOTAL STAKING HAMPERBLOCK: </h3>
            <h3>{{ staking | number: '1.0-0' }} HBLOCK --> {{ stakingDolares | number: '1.2-2' }} $</h3>
            <h5>Total HBLOCK generados ya retirados: {{ totalInteresesPaid | number: '1.0-0' }} </h5>
          </section>
          <section>
          </section>
        </div>
      </mat-card-content>
    </mat-card>
    
  </div>
  </div>
</div>


<div class="body">
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="18px" fxLayoutAlign="center">
    <div fxFlex style="padding: .5%">
    <!-- 3 -->
    <mat-card *ngIf="direction !== undefined">
      <mat-card-header>
        <mat-card-title>THE GAME</mat-card-title>
        <mat-card-subtitle>Mis avances</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div style="text-align:center">
          <mat-icon color="primary">casino</mat-icon>
          <h2>Carteras desbloqueadas: {{ carteras }} </h2>
          <h1>{{ carteraUser }}</h1>
          <h2 class="example-h2">{{ 'account.myprogress' | translate }} - Casilla Nº {{ ultimaCasilla }} ({{ valueProgress | number: '1.0-0'}}%) </h2>
          <section class="example-section">
            <mat-progress-bar
                class="example-margin"
                [color]="color"
                [mode]="mode"
                [value]="valueProgress"
                [bufferValue]="bufferValue">
            </mat-progress-bar>
          </section>
        </div>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button (click)="getHistorico()">{{ 'account.historic' | translate }}</button>
    </mat-card-actions>

    </mat-card>
  </div>
  
  <div fxFlex style="padding: .5%">
    <!-- 4 -->
    <mat-card>

      <mat-card-header>
        <mat-card-title>STAKING</mat-card-title>
        <mat-card-subtitle>My staking</mat-card-subtitle>
      </mat-card-header>
      
      <mat-card-content>
        <div style="text-align:center">
          <section class="header">
            <h3>TOTAL: {{ mystaking | number: '1.0-0' }} HBLOCK --> {{ mystaking * tokens | number: '1.2-2' }} $</h3> 
            <h2>Registro de mis Stakes</h2>

            <table mat-table [dataSource]="stakes" class="mat-elevation-z8" matSort>

              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. </th>
                <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Tokens </th>
                <td mat-cell *matCellDef="let element"> {{element.amount/1000000000000000000 | number : '1.0-0' }}  </td>
              </ng-container>

              <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.tipo == 0 ? 'Flexible' : element.tipo}} </td>
              </ng-container>

              <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef> Desde </th>
                <td mat-cell *matCellDef="let element"> {{element.time*1000 | date: 'longDate'}} </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell   *matHeaderCellDef > Activo </th>
                <td mat-cell *matCellDef="let row; let i = index" >
                     <button mat-button color="primary" (click)="unstaking(i)">Unstaking</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <br>
            
            <mat-slide-toggle disabled="true" color="primary">{{ 'account.stop_staking' | translate }}</mat-slide-toggle>
            <mat-slider
              color="primary"
              thumbLabel
              [displayWith]="formatLabel"
              tickInterval="10"
              step="10"
              min="0"
              [max]="staking"
              [value]="staking"
              aria-label="units"></mat-slider>
            <h2>Mi rentabilidad en staking</h2>
            <h2 class="green" color="success">My YTD: {{ ((balance2 - mystaking) / mystaking)*100 | number: '1.3-3' }}% {{ balance2 - mystaking | number: '1.0-0' }} HBLOCK </h2>
            
          </section>
          <section>
          </section>
        </div>
      </mat-card-content>
    </mat-card>
    
  </div>
  </div>
</div>


<div class="body">
  <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="18px" fxLayoutAlign="center">
  <div fxFlex style="padding: .5%">
    <!-- 5 -->
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ 'account.options' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'account.language_and_others' | translate }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <section>
          <div>
            <h2>Operaciones</h2>
            <mat-slide-toggle [checked]="operacionesDefault" (change)="toggle($event)" color="primary">Aprobar por defecto</mat-slide-toggle>
          </div>
          <br>
          <div>
            <h2>{{ 'account.change_language' | translate }}</h2>
            <div>
              <button mat-button color="primary" [ngClass]="{'active': activeLang == 'es'}" (click)="cambiarLenguaje('es')">{{ 'ESPANOL' | translate }}</button>
              <button mat-button color="primary" [ngClass]="{'active': activeLang == 'en'}" (click)="cambiarLenguaje('en')">{{ 'ENGLISH' | translate }}</button>
            </div>
          </div>
        </section>

      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex style="padding: .5%">
    <!-- 6 -->
    <mat-card>
      <mat-card-header>
        <mat-card-title>Cartera Crypto</mat-card-title>
        <mat-card-subtitle>Inversión & Distribución</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-donut [doughnutChartLabels]="doughnutChartLabels" [doughnutChartData]="doughnutChartData" ></app-donut>
        <h2 class="green">Rentabilidad: {{ rent_carteraCrypto | number: '1.2-2' }}%</h2>
        <h3>Valor total: {{ totalValueUSDC }} USD</h3>
        <h3>Total Invertido: {{ totalInvestedUSDC }} USD</h3>
        <!-- <h4>Tu balance de tokens es:</h4>
        <mat-list>
          <mat-list-item *ngFor="let balance of balancesTokens">
            {{ balance.currentBalance/1000000 }} de {{ balance.tokenSymbol[0] }}
          </mat-list-item>
        </mat-list> -->
        <h4>Retiro </h4>
        <mat-form-field appearance="fill">
          <mat-label>% Retiro</mat-label>
          <input matInput type="number" max="100" min="1" [(ngModel)]="porc_retiro">
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button color="primary"(click)="withdraw()">WITHDRAW NOW!</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>



<div class="body" *ngIf="direction == undefined">

  <mat-card class="account">

    <div style="text-align:center">
      <section class="header">
        <h3> {{ 'account.click_connect' | translate }} </h3>
        <button mat-raised-button color="accent" class="big-round-shape-button" (click)="connectAccount()">{{ 'account.connect_account' | translate }}</button>
        &nbsp;
      </section>
    </div>

  </mat-card>
</div>
</div>