import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  url = 'https://hamperblock.com/django';

  constructor(private http: HttpClient) { }

  getCarteras() {
    return this.http.get('../assets/data.json'); 
  }

  // Info Empresas

  getEmpresas() {
    return this.http.get<any[]>(this.url + '/empresas/');
  }

  getFundamentalesEmpresa(symbol: string) {
    return this.http.get<any>(this.url + '/fundamentales/?symbol='+ symbol);
  }

  gethistoricoEmpresa(symbol: string) {
    return this.http.get<any>(this.url + '/historico/?symbol='+ symbol);
  }

  getQhistoricoEmpresa(id: number) {
    return this.http.get<any>(this.url + '/qhistorico/?id='+ id);
  }

  getQhistoricoEmpresas() {
    return this.http.get<any>(this.url + '/qhistorico/');
  }

  getDividendosEmpresa() {
    return this.http.get<any>(this.url + '/dividendos/');
  }

  getEmpresasRadar() {
    return this.http.get<any>(this.url + '/radar/');
  }

  // Movimientos y Profit

  getMovimientosCartera() {
    return this.http.get<any>(this.url + '/movimientos/');
  }

  getProfitCartera() {
    return this.http.get<any>(this.url + '/profit/');
  }

  // Info Cryptos

  getCryptos() {
    return this.http.get<any[]>(this.url + '/criptos/');
  }

  getFundamentalCrypto(id: number) {
    return this.http.get<any>(this.url + '/fund_criptos/?id='+ id);
  }

  getAnalisisCrypto(id: number) {
    return this.http.get<any>(this.url + '/analisis_criptos/?id='+ id);
  }


  getHistoricoCasillas(address: string) {
    return this.http.get<any>(this.url + '/hist_casilla/?address='+ address);
  }

  addHistoricoCasilla(address: string, casilla: Number, hblocks: Number) {
    let data = {
      "address": address,
      "casilla": casilla,
      "hblocks": hblocks
    };
    return this.http.post<any>(this.url + '/hist_casilla/', data);
  }

  getLeccionesCartera(cartera: number) {
    return this.http.get<any>(this.url + '/leccion/?cartera='+ cartera);
  }

  // Directas a AlphaVantage
  getVelasEmpresa(symbol: string) {
    return this.http.get<any>('https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=' + symbol + '&apikey=RW3ZTFLNEG0J1XWE');
  }

  getSMADiario(symbol: string, period: string = '200', apiKey2: string = 'RW3ZTFLNEG0J1XWE') {
    const url = `https://www.alphavantage.co/query?function=SMA&symbol=${symbol}&interval=daily&time_period=${period}&series_type=close&apikey=${apiKey2}`; 
    return this.http.get(url);
  }

  getVelasCripto(symbol: string) {
    return this.http.get<any>('https://www.alphavantage.co/query?function=DIGITAL_CURRENCY_DAILY&symbol=' + symbol + '&market=CNY&apikey=RW3ZTFLNEG0J1XWE');
  }

  getPrecioEmpresa(symbols: string[]) {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
      })
    };
    return this.http.get<any>('https://thingproxy.freeboard.io/fetch/https://query1.finance.yahoo.com/v7/finance/quote?lang=en-US&region=US&count=5&corsDomain=finance.yahoo.com&symbols=' + symbols, httpOptions);
  }

}
