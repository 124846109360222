<div class="body">

    <mat-card class="account">
        <form [formGroup]="transactionForm" (ngSubmit)="sendEth($event)">
            <mat-form-field appearance="outline">
                <mat-label>Destination Account</mat-label>
                <input matInput placeholder="Placeholder" formControlName="sendaddress">
                <mat-icon matSuffix>account_balance_wallet</mat-icon>
                <mat-hint></mat-hint>
              </mat-form-field>
              &nbsp;
              <mat-form-field appearance="outline">
                <mat-label>Amount</mat-label>
                <input matInput type="number" min=0 placeholder="Placeholder" formControlName="amount">
                <mat-icon matSuffix>attach_money</mat-icon>
                <mat-hint></mat-hint>
              </mat-form-field>
        </form>
        &nbsp;
        <div class="button-container">
          <button mat-raised-button color="accent" [disabled]="!transactionForm.valid" (click)="sendEth($event)"><mat-icon *ngIf="!transactionForm.valid">lock</mat-icon><mat-icon *ngIf="transactionForm.valid">input</mat-icon>&nbsp;Transfer Ether</button>
          &nbsp;
        </div>
        &nbsp;
    </mat-card>
  </div>
