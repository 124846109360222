import { Routes } from '@angular/router';
import { CalendarComponent } from '../shared/calendar/calendar.component';

// Components
import {AccountComponent} from "./account/account.component";
import { BoardComponent } from './board/board.component';
import { CalendarioComponent } from './calendar/calendario.component';
import { CarterasComponent } from './carteras/carteras.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {ErrorComponent} from "./error/error.component";
import {HomeComponent} from "./home/home.component";
import { LearningComponent } from './learning/learning.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { OnlineTestComponent } from './online-test/online-test.component';
import { FooterComponent } from './shared/footer/footer.component';
import {TransactionComponent} from "./transaction/transaction.component";

// Esto lo tengo en las rutas principales por ahora hasta nueva orden

export const UiRoute: Routes = [
  { path: '', redirectTo: 'money', pathMatch: 'full'},
  { path: 'money', component: TransactionComponent },
  { path: 'home', component: HomeComponent},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'board', component: BoardComponent},
  { path: 'carteras', component: CarterasComponent},
  { path: 'calendar', component: CalendarioComponent},
  { path: 'market', component: MarketplaceComponent},
  { path: 'footer', component: FooterComponent},
  { path: 'learning/:id', component: LearningComponent},
  { path: 'account', component: AccountComponent},
  { path: '404', component: ErrorComponent },
  { path: 'test', component: OnlineTestComponent},
  { path: '**', redirectTo: '/404' },
];
