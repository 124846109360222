<div>

    <div class="body">
        <h2> MarketPlace de NFTs</h2>
        <button mat-raised-button color="warn" (click)="openDialogVenta()">VENDER NFT</button>
    </div>

    <mat-spinner *ngIf="loaded"></mat-spinner>

    <div *ngIf="nftsVenta.length > 0" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="25px" fxLayoutAlign="center">
      
      <div fxFlex="25" fxFlex.lg="20" fxFlex.sm="50"
        *ngFor="let nft of nftsVenta | duplicate">
        <app-nft-info [nft]="nft" market="true" [esMio]="nft.esMio" (onNftBuying)="nftAcomprar($event)" (onNftCanceling)="nftAcancelar($event)"></app-nft-info>
        <div class="smaller">
            {{ nft.owner }} - Mint#: {{nft.idVenta}} ({{nft.status}})
            <!-- Subido el 13/04/22 -->
        </div>
        <br>
      </div>
  </div>

  <div class="body">
    <mat-card>

      <mat-card-header>
        <mat-card-title>Histórico</mat-card-title>
        <mat-card-subtitle>Registro de Compra-Ventas</mat-card-subtitle>
      </mat-card-header>
      
      <mat-card-content>
        <div style="text-align:center">
          <section class="header">
            
            <table mat-table [dataSource]="historicos" class="mat-elevation-z8" matSort #sorter1="matSort">

              <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let element"> {{element.time*1000 | date: 'short' }}  </td>
              </ng-container>

              <ng-container matColumnDef="idVenta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID NFT</th>
                <td mat-cell *matCellDef="let element"> {{element.idVenta}} </td>
              </ng-container>

              <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dueño </th>
                <td mat-cell *matCellDef="let element"> {{element.owner}} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status == 0 ? 'Venta' : element.status == 2 ? 'Compra' : element.status}}</td>
              </ng-container>

              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                <td mat-cell *matCellDef="let element"> {{element['price']}} </td>
              </ng-container>

              <ng-container matColumnDef="symbol">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Symbol </th>
                <td mat-cell *matCellDef="let element"> {{element['symbol']}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <!-- <mat-paginator [length]="100"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page">
            </mat-paginator> -->
            
          </section>
        </div>
      </mat-card-content>
    </mat-card>
  </div>


</div>