export const empresasPrueba0 = [
    {   
        id: 0,
        index: 1,
        nombre: "Apple",
        desc: "Apple Inc. is an American multinational technology company that specializes in consumer electronics, computer software and online services",
        icono: 'AAPL',
        color: 'dorado',
        tipo: 'Consumer Electronic',
        pais: 'eeuu',
        mercado: 'NASDAQ',
        level: 1,
        sector: 'phone_iphone'
    }, {   
        id: 1,
        index: 1,
        nombre: 'COCA COLA',
        desc: 'Coke is a carbonated soft drink manufactured by The Coca-Cola Company. Originally marketed as a temperance drink and intended as a patent medicine.',
        icono: 'KO',
        color: 'dorado',
        tipo: 'Consumer Basic',
        pais: 'eeuu',
        mercado: 'NYSE',
        level: 1,
        sector: 'free_breakfast'
    },
    {      
        id: 2,
        index: 0,
        nombre: "P&G",
        desc: "P&G is an American multinational consumer goods corporation specializes in a wide range of personal health, and personal care and hygiene products",
        icono: 'PG',
        color: 'plata',
        tipo: 'Consumer Basic',
        pais: 'eeuu',
        mercado: 'NYSE',
        level: 1,
        sector: 'free_breakfast'
    },
];
export const empresasPrueba1 = [
    {   
        id: 0,
        index: 1,
        nombre: "Apple",
        desc: "Apple Inc. is an American multinational technology company that specializes in consumer electronics, computer software and online services",
        icono: 'AAPL',
        color: 'dorado',
        tipo: 'Consumer Electronic',
        pais: 'eeuu',
        mercado: 'NASDAQ',
        level: 1,
        sector: 'phone_iphone'
    }, {   
        id: 1,
        index: 1,
        nombre: 'COCA COLA',
        desc: 'Coke is a carbonated soft drink manufactured by The Coca-Cola Company. Originally marketed as a temperance drink and intended as a patent medicine.',
        icono: 'KO',
        color: 'dorado',
        tipo: 'Consumer Basic',
        pais: 'eeuu',
        mercado: 'NYSE',
        level: 2,
        sector: 'free_breakfast'
    },{      
        id: 2,
        index: 0,
        nombre: "P&G",
        desc: "P&G is an American multinational consumer goods corporation specializes in a wide range of personal health, and personal care and hygiene products",
        icono: 'PG',
        color: 'plata',
        tipo: 'Consumer Basic',
        pais: 'eeuu',
        mercado: 'NYSE',
        level: 1,
        sector: 'free_breakfast'
    }
];
export const empresas = [
{   
    id: 0,
    index: 1,
    nombre: "Apple",
    desc: "Apple Inc. is an American multinational technology company that specializes in consumer electronics, computer software and online services",
    icono: 'AAPL',
    color: 'dorado',
    tipo: 'Consumer Electronic',
    pais: 'eeuu',
    mercado: 'NASDAQ',
    level: 1,
    sector: 'phone_iphone'
}, {   
    id: 1,
    index: 1,
    nombre: 'COCA COLA',
    desc: 'Coke is a carbonated soft drink manufactured by The Coca-Cola Company. Originally marketed as a temperance drink and intended as a patent medicine.',
    icono: 'KO',
    color: 'dorado',
    tipo: 'Consumer Basic',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'free_breakfast'
}, {      
    id: 2,
    index: 0,
    nombre: "P&G",
    desc: "P&G is an American multinational consumer goods corporation specializes in a wide range of personal health, and personal care and hygiene products",
    icono: 'PG',
    color: 'plata',
    tipo: 'Consumer Basic',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'free_breakfast'
}, {   
    id: 3,
    index: 1,
    nombre: "Novo Nordisk",
    desc: "Novo Nordisk A/S is a Danish multinational pharmaceutical company with products and services specifically diabetes care medications and devices",
    icono: 'NOVO',
    color: 'bronce',
    tipo: 'Pharma',
    pais: 'suecia',
    mercado: 'NYSE',
    level: 1,
    sector: 'monitor_heart'
}, {   
    id: 4,
    index: 1,
    nombre: "Tesla",
    desc:  "Tesla is an American electric vehicle and clean energy company. Designs and manufactures electric cars, battery energy storage from home",
    icono: 'TSLA',
    color: 'plata',
    tipo: 'Consumer Basic',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'directions_car'
}, {   
    id: 5,
    index: 1,
    nombre: "3M",
    desc: "The 3M Company is an American multinational conglomerate corporation operating in the fields of industry, worker safety, US health care, and consumer goods",
    icono: 'MMM',
    color: 'dorado',
    tipo: 'Consumer Basic',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'free_breakfast'
}, {   
    id: 6,
    index: 1,
    nombre: "McDonald's",
    desc: "McDonald's is an American fast food company. They rechristened their business as a hamburger stand and is the world's largest restaurant chain by revenue",
    icono: 'MCD',
    color: 'bronce',
    tipo: 'Consumer Basic',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'free_breakfast'
}, {   
    id: 7,
    index: 1,
    nombre: "J&J",
    desc: "J&J is an American multinational corporation founded in 1886 that develops medical devices, pharmaceuticals, and consumer packaged goods",
    icono: 'JNJ',
    color: 'dorado',
    tipo: 'Consumer Basic',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'free_breakfast'
}, {   
    id: 8,
    index: 1,
    nombre: "Unilever",
    desc: "Unilever is a British multinational consumer goods company. Unilever products include food, condiments, ice cream, wellbeing vitamins, minerals and supplements",
    icono: 'UL',
    color: 'dorado',
    tipo: 'Consumer Basic',
    pais: 'uk',
    mercado: 'NYSE',
    level: 1,
    sector: 'free_breakfast'
}, {   
    id: 9,
    index: 1,
    nombre: "Nvidia",
    desc: "Nvidia is an American multinational technology company what designs graphics processing units (GPUs) for the gaming and professional markets",
    icono: 'NVDA',
    color: 'bronce',
    tipo: 'Consumer Electronic',
    pais: 'eeuu',
    mercado: 'NASDAQ',
    level: 1,
    sector: 'phone_iphone'
}, 
{   
    id: 10,
    index: 1,
    nombre: 'Bitcoin',
    desc: "Bitcoin (₿) is a decentralized digital currency, without a central bank or single administrator, that can be sent from user to user on the peer-to-peer bitcoin network without the need for intermediaries",
    icono: 'BTC',
    color: 'dorado',
    tipo: 'Cryptocurrency',
    pais: 'mundo',
    mercado: 'Blockchain',
    level: 1,
    sector: 'credit_card'
}, {
    id: 11,
    index: 10,
    nombre: 'Ethereum',
    desc: 'Ethereum is a decentralized, open-source blockchain with smart contract functionality. Ether (ETH) is the native cryptocurrency of the platform.',
    icono: 'ETH',
    color: 'dorado',
    tipo: 'Cryptocurrency',
    pais: 'mundo',
    mercado: 'Blockchain',
    level: 1,
    sector: 'credit_card'
},
{   
    id: 12,
    index: 1,
    nombre: "Colgate",
    desc: "Colgate-Palmolive is an American multinational consumer products company. It specializes in the production of household, health care and personal care.",
    icono: 'CL',
    color: 'plata',
    tipo: 'Consumer BASIC',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'phone_iphone'
}, {   
    id: 13,
    index: 1,
    nombre: 'Imperial Brands',
    desc: ' Imperial Tobacco is a British multinational tobacco company headquartered in England. It is the world fourth-largest international cigarette company measured by market.',
    icono: 'IMB',
    color: 'plata',
    tipo: 'Consumer Basic',
    pais: 'uk',
    mercado: 'NYSE',
    level: 1,
    sector: 'free_breakfast'
}, {      
    id: 14,
    index: 0,
    nombre: "Inditex",
    desc: "Industria de Diseño Textil, S.A. is a Spanish multinational clothing company headquartered in Galicia. Inditex, the biggest fast fashion group in the world.",
    icono: 'ITX',
    color: 'bronce',
    tipo: 'Consumer Basic',
    pais: 'spain',
    mercado: 'IBEX',
    level: 1,
    sector: 'free_breakfast'
}, {   
    id: 15,
    index: 1,
    nombre: "T.Rowe Price",
    desc: "T. Rowe Price is an American publicly owned global investment management firm that offers funds, advisory services, account management, and retirement plans and services.",
    icono: 'TROW',
    color: 'bronce',
    tipo: 'Finantial',
    pais: 'eeuu',
    mercado: 'NYSE',
    level: 1,
    sector: 'monitor_heart'
},{
    id: 16,
    index: 10,
    nombre: 'BASF',
    desc: 'BASF is a German multinational chemical company and the largest chemical producer in the world: chemicals, , plastics, performance chemicals, catalysts, coatings...',
    icono: 'BAS',
    color: 'bronce',
    tipo: 'Industrial',
    pais: 'german',
    mercado: 'XETRA',
    level: 1,
    sector: 'credit_card'
},
{   
    id: 17,
    index: 1,
    nombre: "Polygon",
    desc: "Polygon is a network of secure Layer 2 (L2) solutions and autonomous sidechains. It aims to increase Ethereum scalability and reduce transaction costs.",
    icono: 'MATIC',
    color: 'plata',
    tipo: 'Cryptocurrency',
    pais: 'mundo',
    mercado: 'Blockchain',
    level: 1,
    sector: 'credit_card'
}, {   
    id: 18,
    index: 1,
    nombre: 'Binance SC',
    desc: 'Binance Smart Chain (BSC) is a smart contract-enabled parallel blockchain to Binance Chain that provides a permissionless platform for decentralized application (DApps).',
    icono: 'BNB',
    color: 'plata',
    tipo: 'Cryptocurrency',
    pais: 'mundo',
    mercado: 'Blockchain',
    level: 1,
    sector: 'credit_card'
}, {      
    id: 19,
    index: 0,
    nombre: "Solana",
    desc: "Solana is a public blockchain platform with smart contract functionality. Solana claims to offer faster transaction times and lower costs than its main competitor, Ethereum.",
    icono: 'SOL',
    color: 'plata',
    tipo: 'Cryptocurrency',
    pais: 'mundo',
    mercado: 'Blockchain',
    level: 1,
    sector: 'credit_card'
}

];