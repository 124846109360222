import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { empresas } from 'src/app/interfaces/empresas';
import { ContractService } from 'src/app/services/contract/contract.service';
import { VenderComponent } from './vender/vender.component';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  nftsVenta = [];
  contrato;
  contratoNft;
  contratoToken;
  loaded = false;
  direction = "";

  historicos : MatTableDataSource < any[] > = new MatTableDataSource<Historico[]>([]);
  displayedColumns: string[] = ['time', 'idVenta', 'status', 'price', 'symbol', 'owner'];

  constructor(
    private contract: ContractService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router
  ) { }

  @ViewChild('sorter1') sorter1: MatSort;

  ngOnInit(): void {
    this.direction = localStorage.getItem('myAddress');

    this.contract.getContractMarket().then((contratoMarket: any) => {
      this.contrato = contratoMarket;      
      
      // getHistorical
      contratoMarket.methods.getAllNftIDs().call().then( (nfts) => {
        let array = [];
        nfts.map( idVenta => {
          contratoMarket.methods.getHistorical(idVenta).call().then( (sales) => {
            sales.map(h => {
              // console.log(h);
              let element = { idVenta: idVenta, owner: h['owner'], status: h[1], price: h['price']/(10**h['decimals']), time: h['time'], token: h['token'], symbol: h['symbol'], decimals: h['decimals'] } as any;
              array.push(element);
            });
            this.historicos = new MatTableDataSource(array);
            this.historicos.sort = this.sorter1;    
          });
        });
      });

      this.getVerifiedOpenSales().then( (idVentas) => {
        console.log(idVentas);
        for (let idVenta of idVentas) {
          this.getSale(idVenta).then( (venta) => {
            if(venta.status == '0'){
              // console.log(idVenta, venta);
              // Busco el NFT de nuestro ID interno que es cada venta
              this.contract.getContractNFT().then(async (contratoNFT: any) => {
                this.nftsMinted(contratoNFT, idVenta).then( (nft: any) => {
                  this.contratoNft = contratoNFT;
                  let element = empresas[nft.id];
                  element['price'] = venta.price/(10**venta.decimals);
                  element['status'] = venta.status;
                  element['owner'] = venta.owner;
                  element['time'] = venta.time;
                  element['token'] = venta.token;
                  element['symbol'] = venta.symbol;
                  element['idVenta'] = idVenta;
                  if( this.direction == venta.owner) {
                    element['esMio'] = true;
                  }else{
                    element['esMio'] = false;
                  }
                  this.nftsVenta.push(element);
                });
              });
            }
          });
        }
      });
    });
  }


  openDialogVenta(){
    this.dialog.open(VenderComponent, {
      height: '50%',
      width: '50%',
      // todo pasar el contratoNFT
    });
  }

  nftAcomprar(evento: any){
    console.log(evento);
    this.comprarNft(evento.idVenta);
  }

  nftAcancelar(evento: any){
    console.log(evento);
    this.cancelVentaNft(evento.idVenta);
  }
  
  async getVerifiedOpenSales() {
    return await this.contrato.methods.getVerifiedOpenSales().call();
  } 

  async getSale(idVenta: number) {
    return await this.contrato.methods.getSale(idVenta).call();
  } 

  async getHistorical(idVenta: number) {
    return await this.contrato.methods.getHistorical(idVenta).call();
  } 

  async nftsMinted(contratoNft, idVenta: number) {
    return await contratoNft.methods.nftsMinted(idVenta).call();
  } 

  async getNftsMinted(contrato) {
    return await contrato.methods.getNftsMinted().call();
  }

  async isOwnerOfNFT(contrato, idNft: number) {
    return await contrato.methods.isOwnerOfNFT(this.direction, idNft).call();
  }

  comprarNft(idNft: number){
    this.loaded = true;
    let infinity = Boolean(localStorage.getItem('operacionesDefault'));

    this.contrato.methods.getPriceWithFee(idNft).call().then(async (amount: any) => {
      this.contrato.methods.getSale(idNft).call().then(async (sale: any) => {
        let tokenAddress = sale[4];
        console.log(tokenAddress);
        this.contract.getContractAnyToken(tokenAddress).then(async (contratoToken: any) => {
            this.contract.allowance_approve(contratoToken, this.direction, '0x3407047999b880d7a72c41490b8df9b695443cf2', String(amount), infinity, ()=>{
              this.contrato.methods.buy(idNft).send({from: this.direction}).then((ok: any) => {
                  this.loaded = false;
                  console.log(ok);
                  this.openSnackBar('Has comprado el NFT: #', idNft, amount);
              });
          });
        }); 

      });
    });


  }

  cancelVentaNft(idNft: number){
    console.log(idNft);
    this.loaded = true;
    this.cancelSale(idNft).then((ok: any) => {
      this.loaded = false;
      console.log(ok);
      this.openSnackBar('Ya no está a la venta el NFT: #', idNft, 0);
    }); 
  }

  async cancelSale(idNft: number) {
    return await this.contrato.methods.cancelSale(idNft).send({from: this.direction})
  } 

  openSnackBar(msg: string, value: any, amount: any) {
    const snackBarRef = this.snackBar.open(msg + String(value) + ' por ' + String(amount), '¡Enhorabuena!, clic para seguir...', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    snackBarRef.afterDismissed().subscribe(() => {
      console.log('The snackbar was dismissed');
      this.router.navigate(['/pages/market']);
    });
  }


}

export interface Historico {
  idVenta: number,
  status: string;
  owner: string;
  time: number;
  token: string;
  price: number;
}