import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpresasComponent } from './empresas/empresas.component';
import { InfoRoutingModule } from './info-routing.module';
import { AppMaterialModule } from '../app-material.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmpresaComponent } from './empresa/empresa.component';
import { ChartsModule } from 'ng2-charts';
import { ComponentsModule } from '../components/components.module';
import { CryptoComponent } from './crypto/crypto.component';
import { MovimientosComponent } from './movimientos/movimientos.component';
import { ProfitComponent } from './profit/profit.component';
import { SwapComponent } from './swap/swap.component';


@NgModule({
  declarations: [
    EmpresasComponent,
    EmpresaComponent,
    CryptoComponent,
    MovimientosComponent,
    ProfitComponent,
    SwapComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    InfoRoutingModule,
    AppMaterialModule,
    SharedModule,
    ChartsModule,
    ComponentsModule
  ]
})
export class InfoModule { }
