import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

  datesToHighlight = [];
  items: any[] = [];
  itemSelected = {
    esDividendo: false
  }
  dividendos = [];

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.getDividendosEmpresa()
      .subscribe( (divs: any) => {
        let div22 = divs.filter( a => a.date == "2022");
        console.log(div22);
        this.dividendos = div22.sort((a, b) => {
          let a2 = new Date(a.payable_date).getTime();
          let b2 = new Date(b.payable_date).getTime();
          return a2 - b2
        });
        div22.forEach(d => {
          this.datesToHighlight.push({
              strDate: d.payable_date,
              yearFiscal: d.date,
              confirmado: true, 
              title: d.empresa.nombre, 
              dividendo: d.dividendo, 
              comentario: 'Fecha Ex-div:' + d.ex_dividend,
              esDividendo: true
            })
        });
      });
    this.dataService.getQhistoricoEmpresas()
    .subscribe( (trim: any) => {
      // console.log(trim);
      trim
        .forEach(t => {
          this.datesToHighlight.push({
              strDate: t.reportedDate,
              yearFiscal: t.historico.fiscalDateEnding,
              confirmado: t.confirmado, 
              title: t.historico.empresa.nombre, 
              eps0: t.estimatedEPS, 
              eps1: t.reportedEPS, 
              netIncome: t.netIncome,
              totalRevenue: t.totalRevenue,
              comentario: t.TextField,
              esDividendo: false
            })
        });
      });
  }

  objetoCalendario( evento: any ){
    // console.log(evento);
    if (evento.esDividendo){
      this.items.push(evento);
    }
  }

  delete(index: number) {
    this.items.splice(index, 1);
    this.items = [...this.items];
  }

}
