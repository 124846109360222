import { AfterViewInit, Component, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-grafica-velas',
  templateUrl: './velas.component.html',
  styleUrls: ['./velas.component.scss']
})
export class VelasComponent implements OnInit, AfterViewInit, OnDestroy {

  
  @Input() datos: any[];
  // @Input() sma: any[];
  @ViewChild('chartElement') chartElement: ElementRef<HTMLElement>;

  private chart: am4charts.XYChart;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId,
    private zone: NgZone
  ) { }

  ngOnInit() {
    console.log(this.datos);
    // console.log(this.sma);
  }


  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {

    this.browserOnly(() => {

      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
      // const chart = am4core.create('chartdivVelas', am4charts.XYChart);
      
      chart.data = this.datos;
      // chart.background.fill = '#0f0'
      chart.background.opacity = 0.7

      chart.paddingRight = 20;
      //chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;

      let series = chart.series.push(new am4charts.CandlestickSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "close";
      series.dataFields.openValueY = "open";
      series.dataFields.lowValueY = "low";
      series.dataFields.highValueY = "high";
      series.simplifiedProcessing = true;
      series.tooltipText = "Abre:${openValueY.value}\nMín:${lowValueY.value}\nMáx:${highValueY.value}\nCierra:${valueY.value}";

      series.dropFromOpenState.properties.fill = am4core.color("#e61010");
      series.dropFromOpenState.properties.stroke = am4core.color("#e61010");
      series.riseFromOpenState.properties.fill = am4core.color("#2fbe07");
      series.riseFromOpenState.properties.stroke = am4core.color("#2fbe07");

      chart.cursor = new am4charts.XYCursor();

      // a separate series for scrollbar
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.dateX = "date";
      lineSeries.dataFields.valueY = "close";
      // need to set on default state, as initially series is "show"
      lineSeries.defaultState.properties.visible = false;

      // hide from legend too (in case there is one)
      lineSeries.hiddenInLegend = true;
      lineSeries.fillOpacity = 1;
      lineSeries.strokeOpacity = 1;

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(lineSeries);
      chart.scrollbarX = scrollbarX;

 
      //SMA
      const sma = chart.series.push(new am4charts.LineSeries());
      sma.dataFields.valueY = 'sma';
      sma.dataFields.dateX = 'date';
      sma.name = 'SMA200';
      sma.stroke = am4core.color('#008F39');
      sma.strokeWidth = 4;

      this.chart = chart;
    });

  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }


}
