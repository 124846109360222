import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreSummaryService {

  constructor() { }

  getStoreSummary() {
    return [
      {
      icon: 'calendar_today',
      title: 'Días activos',
      value: 5,
      color: 'primary',
      isIncrease: true,
      isCurrency: false,
      duration: 'desde el comienzo',
      percentValue: 0
    },
    {
      icon: 'supervised_user_circle',
      title: 'Holders activos',
      value: 500,
      color: 'accent',
      isIncrease: true,
      isCurrency: false,
      duration: 'desde hace 3 días',
      percentValue: 4
    },
    {
      icon: 'shopping_cart',
      title: 'Total Supply',
      value: 10000,
      color: 'success',
      isIncrease: false,
      isCurrency: false,
      duration: 'desde hace 23 días',
      percentValue: 4
    },{
      icon: 'attach_money',
      title: 'MarketCap',
      value: 100,
      color: 'warn',
      isIncrease: true,
      isCurrency: true,
      duration: 'desde hace 3 días',
      percentValue: 4
    }

  ]
  }
}
