<div class="body">

    <mat-card class="account">

      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>PRESALE HAMPERBLOCK</mat-card-title>
        <mat-card-subtitle>HAMPER BLOCK is a business model based on the investment of assets in decentralised portfolios.</mat-card-subtitle>  
      </mat-card-header>

      <mat-card-content>

        <mat-form-field appearance="fill">
            <!-- <mat-label>Núm. Tokens</mat-label> -->
            <input placeholder="1 MATIC = 600 Hblock" matInput type="number" [(ngModel)]="num_tokens">
            <mat-hint>Min: 4 | Max: 5000</mat-hint>
          </mat-form-field>
          <br><br>
        <div class="button-container">
          <button mat-raised-button color="primary" [disabled]="true" (click)="send()"><mat-icon *ngIf="!num_tokens">lock</mat-icon><mat-icon *ngIf="!num_tokens">input</mat-icon>&nbsp;Contribute</button>
          &nbsp;
        </div>
        &nbsp;

        <h3>{{ tokensSold }} / 10,000,000 HBLOCK Raised</h3>
        <mat-progress-bar
          class="example-margin"
          color="accent"
          mode="determinate"
          [value]="valueProgress"
          [bufferValue]="bufferValue"
          >
        </mat-progress-bar>

        <br>

        <h5>Presale Ends in:</h5>
        <!-- <div id="comingsoon-countdown"></div> -->
        <h5>Finish!</h5>

        <br>

      </mat-card-content>
    </mat-card>

    <mat-card>
    <mat-card-content>
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-around center">
        <div fxLayout="column">
          <app-donut [doughnutChartLabels]="doughnutChartLabels" [doughnutChartData]="doughnutChartData" ></app-donut>
        </div>
        <div fxLayout="column">
          <h5>
            Estimated Initial Marketcap:
            <br>
            $288.120
            <br>
          Presale Address: 0xEa18e58F540c6f355ef997ed53DBbb0360e558C8
          <br>
          Total Supply	100.000.000 HBlock
          <br>
          Presale	35.000.000 HBlock
          <br>
          Presale Rate	600 HBlock per MATIC
          <br>
          Presale Start Time	1 Feb 2022 at 15:00
          <br>
          Presale End Time	3 Feb 2022 at 14:00
        </h5>
        </div>
      </div>
</mat-card-content>
</mat-card>

        <div class="body">
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="58px" fxLayoutAlign="center">
            <div fxFlex style="padding: .5%">
              <mat-card>
              
            </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="58px" fxLayoutAlign="center">
            <div fxFlex style="padding: .5%">
            <mat-card>
              
            </mat-card>
            </div>
          </div>
        </div>

        

    
