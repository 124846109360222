<mat-tab-group mat-align-tabs="start">
  
    <mat-tab label="{{ 'carteras.my_portfolios' | translate }}">

        <div class="body">

          <p>{{ 'carteras.here_is_your' | translate }}
          </p>
        
          <!-- <mat-form-field appearance="fill">
            <mat-label>{{ 'carteras.filter' | translate }}</mat-label>
            <mat-select [formControl]="toppings" (selectionChange)="onFilterChange($event)">
              <mat-option *ngFor="let tipo of tipos" [value]="tipo.value">
                {{tipo.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        
              <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="18px" fxLayoutAlign="center">
                <div fxFlex="23" fxFlex.lg="18" fxFlex.sm="40"
                  *ngFor="let cartera of carteras | filtro:tipo">
                  <app-cartera-tarjeta [cartera]="cartera"></app-cartera-tarjeta>
                </div>
            </div>
        
          </div>


    </mat-tab>




    <mat-tab label="{{ 'carteras.my_nft' | translate }}">

        <div class="body">
          <!-- <button mat-button (click)="mintNFT()">Mint NFT</button> -->
          <!-- <button mat-button (click)="withdrow()">Withdraw</button> -->
          <h3> NFTs Minteados: {{ nftsMinteados }}</h3>
          <br>
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="25px" fxLayoutAlign="center">
            
            <div fxFlex="25" fxFlex.lg="20" fxFlex.sm="50"
              *ngFor="let nft of nfts">
              <app-nft-tarjeta [nft]="nft"></app-nft-tarjeta>
            </div>
        </div>
        </div>

    </mat-tab>


    <!-- <mat-tab label="{{ 'carteras.others' | translate }}"></mat-tab> -->
  </mat-tab-group>
  


  
