import { Component, Inject, OnInit } from '@angular/core';
import { Token } from 'src/app/components/select-tokens/select-tokens.component';
import { WEB3 } from 'src/app/core/web3';
import { ContractService } from 'src/app/services/contract/contract.service';
import Web3 from 'web3';
import Decimal from '../swap/decimal.js';

@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})
export class SwapComponent implements OnInit {

  tokenA: Token = {token: "", symbol: "", logo: ""}
  tokenB: Token = {token: "", symbol: "", logo: ""}
  amountA = 0.0;
  amountB = 0.00;
  slippage = 300;
  contrato;
  decimalsA = 0;
  decimalsB = 0;
  price = 0;
  direction = '';
  bestSwap = [];
  swapPath = '';
  feePath = "0x0";
  errorInput = false;
  balance = 0;
  amountBWithDecimal = 0;
  linkOperation = '';
  loaded = false;

  USDC = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
  matic = '0x0000000000000000000000000000000000001010';

  amountCrypto=(amount,decimals)=>Decimal.mul(amount,Decimal.pow(10, decimals));


  constructor(
    private contract: ContractService,
    @Inject(WEB3) private web3: Web3
  ) {
  }

  ngOnInit() {
    this.direction = localStorage.getItem('myAddress');

    document.getElementById('amountA').oninput = () => { 
      this.swapPrevio();
      this.amountB = 0;
    };

    this.contract.getContractSwap()
      .then((contrato: any) => {
        console.log(contrato);
        this.contrato = contrato;
      });
  }

  // checkAmount(){
  //   if(this.balance > 0 && this.decimalsA > 0){
  //     if (this.amountA*10**this.decimalsA > this.balance){
  //       console.log('entro al if', this.amountA*10**this.decimalsA, this.balance);
  //       this.errorInput = true;
  //     }
  //   }
  // }

  change(event){
    console.log(event.value);
    this.slippage = event.value;
  }

  nftASelected(evento) {
    console.log('llega al Swap este NFT A selected: ', evento);
    this.tokenA = evento;
    // Get decimals del token selected
    if(this.tokenA.token == this.matic){
      this.contract.getBalance(this.direction).then( balance => {
        console.log(balance);
        this.decimalsA = 18;
        this.balance = balance;
        if (new Decimal(balance).toFixed(0) < this.amountCrypto(this.amountA, 18).toFixed(0)){
          this.amountA = 0;
          this.errorInput = true;
          alert('No tienes suficiente saldo para realizar la operación...')
          console.log(balance, this.errorInput);
        }
      });
      return
    }
    this.contract.getContractAnyToken(this.tokenA.token).then((contratoToken: any) => {
      contratoToken.methods.decimals().call().then((decimales: any) => {
        this.decimalsA = decimales;
        contratoToken.methods.balanceOf(this.direction).call().then((balance: number) => {
          this.balance = balance;
          console.log(new Decimal(balance).toFixed(0) < this.amountCrypto(this.amountA, this.decimalsA).toFixed(0));
          
          if (new Decimal(balance).toFixed(0) < this.amountCrypto(this.amountA, this.decimalsA).toFixed(0)){
            this.amountA = 0;
            this.errorInput = true;
            alert('No tienes suficiente saldo para realizar la operación...')
            console.log(balance, this.errorInput);
          }
        });
      });
    });
  }

  setMaxBalance() {
    if(this.balance > 0 && this.tokenA.token != '') {
      this.amountA = +(this.balance/10**this.decimalsA);
      this.amountA = Math.floor(this.amountA * 100)/100;
    }
  }

  nftBSelected(evento) {
    this.tokenB = evento;
    this.contract.getContractAnyToken(this.tokenB.token).then((contratoToken: any) => {
      contratoToken.methods.decimals().call().then((decimales: any) => {
        this.decimalsB = decimales;
      });
    });
  }

  cleanNftA(evento) {
    this.tokenA = evento;
    // this.amountA = 0;
    this.amountB = 0;
  }

  cleanNftB(evento) {
    this.tokenB = evento;
    this.amountB = 0;
  }

  async getCotizacionesSwap(contrato) {

    let algo = contrato.methods.getBestSwapsByTokens(this.tokenA.token, this.tokenB.token, this.amountCrypto(this.amountA, this.decimalsA).toFixed(0));
    this.checkCall(algo, '', res => {
      console.log(res);
    });

    // console.log(BigInt(this.amountA), this.decimalsA, this.amountA*10**this.decimalsA);
    // return await contrato.methods.getBestSwapsByTokens(this.tokenA.token, this.tokenB.token, this.amountCrypto(this.amountA, this.decimalsA).toFixed(0)).call();
  }

  // Funciones de comprobacion
  checkCall( methods, options, func ) {
    methods.estimateGas(options,(error,gas)=>{
      if (!error) {
       console.log('gas:', gas);
       methods.call((errorM, result)=>{
        if (!errorM) {
         console.log(result);
         func(result);
        }else{
         console.log('error para recoger', errorM, result);
        }
       });
      }else{
       console.log('error para recoger', error);
      }
     });
  }

  checkSend(methods, options, func) {
    methods.estimateGas(options,(error,gas)=>{
      if (!error) {
       console.log('gas:', gas);
       methods.send(options, (errorM, result)=>{
        if (!errorM) {
         console.log(result);
         func(result);
        }else{
         console.log('error para recoger', errorM, result);
        }
       });
      }else{
       console.log('error para recoger', error);
      }
     });
  }
  

  swapPrevio(){
    if( this.tokenA.token == '' || this.tokenB.token == '' || this.amountA <= 0 || this.decimalsA < 0){
      return;
    }
    let router = this.contrato.methods.getBestSwapsByTokens(this.tokenA.token, this.tokenB.token, this.amountCrypto(this.amountA, this.decimalsA).toFixed(0));
    this.checkCall(router, '', value => {
      console.log(value);
      let exp = this.amountCrypto(value.price, -this.decimalsB)
      this.amountBWithDecimal = value.price;
      this.price = Decimal.div(1, exp).toFixed(5);
      this.swapPath = value.bestSwap;
      let decode = this.contrato.methods.bytesToSwap(this.swapPath);
      this.checkCall(decode, '', dec => {
        console.log(dec);
        this.bestSwap = [...dec];
        this.amountB = exp.toFixed(5);
        // document.getElementById('to-amount').click();
      });
    });
  }

  swap(){

    this.loaded = true;
    let infinity = Boolean(localStorage.getItem('operacionesDefault'));
    let amountTA = this.amountCrypto(this.amountA, this.decimalsA).toFixed(0);
    console.log(this.direction, this.tokenA.token, this.tokenB.token, amountTA, this.slippage);
    let minAmountB = Decimal.mul(new Decimal(this.amountBWithDecimal), 1-(this.slippage/10000));
    console.log(minAmountB.toFixed(0));
    let swap = this.contrato.methods.bestSwapTokensForTokensByPath(this.swapPath, amountTA, minAmountB.toFixed(0));
    console.log(this.swapPath, amountTA, minAmountB.toFixed(0));
    let url = 'https://polygonscan.com/tx/';
    if(this.tokenA.token == this.matic){
      console.log('El tokenA es MATIC nativo...');
      this.checkSend(swap, {from: this.direction, value: amountTA}, res => {
        this.loaded = false;
        console.log(res);
        this.linkOperation = url + res;
      });
    } else {
      this.contract.getContractAnyToken(this.tokenA.token).then((contratoToken: any) => {
        this.contract.allowance_approve(contratoToken, this.direction, '0x836Dc5d6DE75E306d59f7393d2f67874474fADBe', amountTA, infinity, ()=>{
          this.checkSend(swap, {from: this.direction}, res => {
            this.loaded = false;
            console.log(res);
            this.linkOperation = url + res;
          });
        });
      });
    }
  }

  formatLabel(value: number) {
    if (value >= 0) {
      return Math.round(value / 100) + '%';
    }

    return 0;
  }

}
