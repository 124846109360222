<div class="body">
    <h2> Profit de la Cartera</h2>

    <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
        <canvas baseChart
          [datasets]="chartData"
          [labels]="charLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType">
        </canvas>
      </div>

      <br>

    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="1px" fxLayoutAlign="center">
        <div fxFlex="45" style="padding: .5%">
            <table mat-table [dataSource]="data" class="mat-elevation-z8">
                <ng-container matColumnDef="fecha">
                  <th mat-header-cell *matHeaderCellDef> Fecha </th>
                  <td mat-cell *matCellDef="let element"> {{ element.fecha | date: 'dd/MM/yyyy' }} </td>
                </ng-container>
               <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef> Valor </th>
                  <td mat-cell *matCellDef="let element"> {{element.valor | number : '1.2-2'}} </td>
                </ng-container>
                <ng-container matColumnDef="profit">
                  <th mat-header-cell *matHeaderCellDef> Benef. </th>
                  <td [style.color]="element.profit > 0 ? 'green' : 'red'" mat-cell *matCellDef="let element"> {{element.profit | number : '1.2-2'}} </td>
                </ng-container>
                <ng-container matColumnDef="balance">
                  <th mat-header-cell *matHeaderCellDef> Balance </th>
                  <td mat-cell *matCellDef="let element"> {{element.balance | number : '1.2-2'}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </div>

        <div fxFlex="45" style="padding: .5%"> 

          <app-tabla-acciones [acciones] = "empresas" [total_invertido]="total_invertido"></app-tabla-acciones>

          <br>

          <div style="display: block; background-color: rgba(243, 241, 241, 0.87)">
            <canvas baseChart
              [datasets]="chartDataBalance"
              [labels]="charLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType">
            </canvas>
          
            <br>
          
            <canvas baseChart
              [data]="doughnutChartData"
              [labels]="doughnutChartLabels"
              [colors]="chartColorsCash"
              [chartType]="doughnutChartType">
            </canvas>
            <br>

            <canvas baseChart
              [data]="doughnutChartData3"
              [labels]="doughnutChartLabels3"
              [colors]="chartColors"
              [chartType]="doughnutChartType">
            </canvas>
            <br>

            <canvas baseChart
              [data]="doughnutChartData2"
              [labels]="doughnutChartLabels2"
              [colors]="chartColors"
              [chartType]="doughnutChartType">
            </canvas>

          </div>

        </div>

</div>
