import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '../ui/error/error.component';
import { EmpresasComponent } from './empresas/empresas.component';


export const routes: Routes = [
  { path: '', 
    children: [
      { path: 'empresas', 
        component: EmpresasComponent 
      },
      { path: '404', component: ErrorComponent },
      { path: '**', redirectTo: '/404' },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class InfoRoutingModule { }
